var NRD = window.NRD || {};

NRD['./views/TabcordionView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        CONTENTCONTAINER: '.js-tabcordion-container',
        CONTENTWRAPPER: '.js-tabcordion-tabContentWrapper',
        TABCONTROL: '.js-tabcordion-control',
        TABCONTROLS: '.js-tabcordion-controls',
        TABCONTENT: '.js-tabcordion-tabContent',
        TABCONTENTHEADING: '.js-tabcordion-content-drawerHeading',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        CONTENTHEADING: 'tabcordion-content-drawerHeading',
        CONTENTHEADINGACTIVE: 'tabcordion-content_active',
        TABCONTROLACTIVE: 'tabcordion-control_active',
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATTRS
     * @type {Object}
     * @final
     */
    var ATTRS = {

    };

    var $window = $(window);

    /**
     * Handles initialization of and navigation within a Tabcordion view
     *
     * @class TabcordionView
     * @extends {BaseView}
     */
    var TabcordionView = BaseView.extend({

        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * If template is empty
             */
            if (!$element.length) {
                return;
            }

            /**
             * Object template
             *
             * @type {jQuery|HTMLElement}
             */
            this.$template = $element;

            /**
             * Tracks whether the view is active
             *
             * @default false
             * @property isEnabled
             * @type {Boolean}
             * @public
             */
            this.isActive = false;

            /**
             * Tracks whether the view is enabled
             *
             * @default false
             * @property isEnabled
             * @type {Boolean}
             * @public
             */
            this.isEnabled = false;

            /**
             *
             */
            this.activeTabRef = '';

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            this.isActive = true;

            return this
                .setupHandlers()
                .createChildren()
                .enable();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onResizeHandler = this.onResize.bind(this);
            this.onTriggerPanelClickHandler = this.onTriggerPanelClick.bind(this);
            this.onTriggerTabClickHandler = this.onTriggerTabClick.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$contentContainer = this.$template.find(SELECTORS.CONTENTCONTAINER);
            this.$tabControls = this.$template.find(SELECTORS.TABCONTROLS);
            this.$tabContent = this.$contentContainer.find(SELECTORS.TABCONTENT);
            this.$tabContentHeading = this.$contentContainer.find(SELECTORS.TABCONTENTHEADING);
            this.$tabControl = this.$tabControls.find(SELECTORS.TABCONTROL);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$contentContainer = null;
            this.$tabControls = null;
            this.$tabContent = null;
            this.$tabContentHeading = null;
            this.$tabControl = null;

            return this;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            if (!this.activeTabRef) { return this; }

            var $activeContent = this.$tabContent.filter('#' + this.activeTabRef);
            var $activeContentWrapper = $activeContent.find(SELECTORS.CONTENTWRAPPER);

            var contentHeight = $activeContentWrapper.outerHeight();

            // set content height to trigger animation
            $activeContent.css({ height: contentHeight });

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.isEnabled = true;

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            if (!this.isEnabled) { return this; }

            $window.unbind('resize', this.onResizeHandler);
            this.$tabContentHeading.unbind('click', this.onTriggerPanelClickHandler);
            this.$tabControl.unbind('click', this.onTriggerTabClickHandler);

            this.isEnabled = false;

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method enable
         * @public
         * @chainable
         */
        enable: function() {
            if (this.isEnabled) { return this; }

            // set event handlers
            $window.on('resize', this.onResizeHandler);
            this.$tabContentHeading.on('click', this.onTriggerPanelClickHandler);
            this.$tabControl.on('click', this.onTriggerTabClickHandler);

            var $activeControl = this.$tabControl.first();

            // force a click event on the first tab control to make it active on load
            $activeControl.trigger('click');

            return this.onEnable();
        },

        /**
         * *************************************************************************
         * Evetn Handlers
         * ************************************************************************
         */

        /**
         * Handler for clicking a tab button
         *
         * @method onTriggerTabClick
         * @private
         */
        onTriggerPanelClick: function(e) {
            e.preventDefault();

            var $trigger = $(e.currentTarget);

            var tabRef = $trigger.attr('rel');

            if (tabRef === this.activeTabRef) { tabRef = ''; }

            this.closeTab(this.activeTabRef);

            if (tabRef !== '') { this.openTab(tabRef, $trigger); }
        },

        /**
         * Handler for clicking a tab button
         *
         * @method onTriggerTabClick
         * @private
         */
        onTriggerTabClick: function(e) {
            e.preventDefault();

            var $trigger = $(e.currentTarget);

            var tabRef = $trigger.attr('rel');

            // If the selected tab reference matches the stored reference and the trigger IS NOT a heading then abort
            if (tabRef === this.activeTabRef && !$trigger.hasClass(CLASSES.CONTENTHEADING)) {
                return;
            }

            this.closeTab(this.activeTabRef);

            this.openTab(tabRef, $trigger);
        },

        /**
         * Handler for window resize event
         *
         * @method onResize
         * @private
         */
        onResize: function(e) {
            if (!this.isEnabled || this.activeTabRef === '') { return; }

            this.layout();
        },

        /**
         * *************************************************************************
         * Helper Methods
         * ************************************************************************
         */

        closeTab: function(tabRef) {
            // set state classes
            this.$tabContentHeading.removeClass(CLASSES.CONTENTHEADINGACTIVE);
            this.$tabControl.removeClass(CLASSES.TABCONTROLACTIVE);

            // set content height to trigger animation
            this.$tabContent.css({ height: 0 });

            // clear tab reference
            this.activeTabRef = '';
        },

        openTab: function(tabRef, $trigger) {
            var $activeContent = this.$tabContent.filter('#' + tabRef);
            var $activeContentHeading = this.$tabContentHeading.filter('[rel^="' + tabRef + '"]');
            var $activeContentWrapper = $activeContent.find(SELECTORS.CONTENTWRAPPER);

            var contentHeight = $activeContentWrapper.outerHeight();

            // set state classes
            $activeContentHeading.addClass(CLASSES.CONTENTHEADINGACTIVE);
            $trigger.addClass(CLASSES.TABCONTROLACTIVE);

            // store tab reference
            this.activeTabRef = tabRef;

            this.layout();
        },
    });

    return TabcordionView;
}());
