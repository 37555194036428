var NRD = window.NRD || {};

NRD['./views/EmailCaptureView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * Caches the default REGEX used by the view
     *
     * @property REGEXS
     * @type {Object}
     * @final
     */
    var REGEXS = {
        EMAIL: /^[a-zA-Z0-9\!\#\$\%\&\'\*\+\/\=\?\^\_\`\{\|\}\~\.\-]+\@[a-z0-9\-]+\.[a-z0-9\-]+$/,
    };

    /**
     * Caches all selectors used by the view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        FIELD: '.js-email-capture-field',
        FORM: '.js-email-capture-form',
        SUCCESS_MSG: '.js-email-capture-success',
        FAIL_MSG: '.js-email-capture-fail',
        SUBMIT_BTN: '.js-email-capture-submit',
        ERROR_MSG: '.js-email-capture-errorMsg',
    };

    /**
     * Caches all events used by the view
     *
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CLICK: 'click',
        SUCCESS: 'emailCaptureSuccess',
        FAIL: 'emailCaptureFail',
    };

    /**
     * Caches all css classes used by the view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_HIDDEN: 'isHidden',
        HAS_ERROR: 'hasError',
    };

    /**
     * Updates the content of the Email Capture Modal based on the success or failure of
     * external ajax calls
     * @class EmailCaptureView
     */
    var EmailCaptureView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param  {EventBus} eventBus Global instance of the Event Bus
         * @param  {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            if ($element.length < 1) {
                return;
            }

            /**
             * The regex to be used when validating the e-mail field
             *
             * @property regex
             * @type {String}
             * @default REGEXS.EMAIL
             */
            this.regex = $element.attr('data-regex') || REGEXS.EMAIL;

            // Call the base view constructor to init the view
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @returns {this.base}
         */
        createChildren: function() {
            this.$field = this.$element.find(SELECTORS.FIELD);
            this.$form = this.$element.find(SELECTORS.FORM);
            this.$successMsg = this.$element.find(SELECTORS.SUCCESS_MSG);
            this.$failMsg = this.$element.find(SELECTORS.FAIL_MSG);
            this.$submitBtn = this.$element.find(SELECTORS.SUBMIT_BTN);
            this.$errorMsg = this.$element.find(SELECTORS.ERROR_MSG);

            return this.base();
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         */
        removeChildren: function() {
            this.$field = null;
            this.$form = null;
            this.$successMsg = null;
            this.$failMsg = null;
            this.$submitBtn = null;
            this.$errorMsg = null;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.onSubmitClickHandler = this.onSubmitClick.bind(this);
            this.onAjaxSuccessHandler = this.onAjaxSuccess.bind(this);
            this.onAjaxFailHandler = this.onAjaxFail.bind(this);

            return this.base();
        },

        /**
         * Callback for inherited views to enable view specific handlers
         *
         * @method onEnable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            this.$submitBtn.on(EVENTS.CLICK, this.onSubmitClickHandler);
            this.eventBus.on(EVENTS.SUCCESS, this.onAjaxSuccessHandler);
            this.eventBus.on(EVENTS.FAIL, this.onAjaxFailHandler);

            return this;
        },

        /**
         * Callback for inherited views to disable view specific handlers
         *
         * @method onDisable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            this.$submitBtn.off(EVENTS.CLICK, this.onSubmitClickHandler);
            this.eventBus.off(EVENTS.SUCCESS, this.onAjaxSuccessHandler);
            this.eventBus.off(EVENTS.FAIL, this.onAjaxFailHandler);

            return this;
        },

        /**
         * Gives the field and label the correct classes
         *
         * @method updateView
         * @public
         * @param {Boolean} isValid
         * @returns {this}
         */
        updateView: function(isValid) {
            this.$field.removeClass(CLASSES.HAS_ERROR);
            this.$errorMsg.removeClass(CLASSES.HAS_ERROR);

            if (!isValid) {
                this.$field.addClass(CLASSES.HAS_ERROR);
                this.$errorMsg.addClass(CLASSES.HAS_ERROR);
            }

            return this;
        },

        /**
         * Tests the value of the field against the e-mail regex
         *
         * @method validateField
         * @private
         * @returns {Boolean}
         */
        _validate: function() {
            var value = this.$field.val();
            // Instantiate a new regex based on the this.regex value, necessary since
            // the regex may be passed in as a string
            var regex = new RegExp(this.regex);

            return regex.test(value);
        },



        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Runs inline validation when the submite button is clicked, stops
         * the event from bubbling to keep the form from submitting
         *
         * @method onSubmitClick
         * @param {jQuery} event object
         * @public
         */
        onSubmitClick: function(event) {
            var isValid = this._validate();

            if (!isValid) {
                event.stopPropagation();
            }

            this.updateView(isValid);
        },

        /**
         * Handles an ajax success event, shows the success message in the modal
         *
         * @method onajaxSuccess
         * @public
         */
        onAjaxSuccess: function() {
            this.$form.addClass(CLASSES.IS_HIDDEN);
            this.$successMsg.removeClass(CLASSES.IS_HIDDEN);
        },

        /**
         * Handles an ajax failure event event
         *
         * @method onAjaxFail
         * @public
         */
        onAjaxFail: function() {
            // Add failure behaviors
        },
    });

    return EmailCaptureView;
}());
