var NRD = window.NRD || {};

NRD['./views/TogglePasswordView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        INPUT: 'input:password',
    };

    var ATTRIBUTES = {
        PLAIN_TEXT: 'text',
        PASSWORD: 'password',
    };

    var LABELS = {
        SHOW: 'Show',
        HIDE: 'Hide',
    };

    var CLASSES = {
        TOGGLE_PARENT: 'togglePassword',
        TOGGLE_INPUT: 'togglePassword-input',
        BTN_TOGGLE: 'togglePassword-control',
    };

    /**
     * Finds password input and creates and injects toggle button to show or hide password input masking
     * @class TogglePasswordView
     */
    var TogglePasswordView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * Default set to false as password is set to hidden by default
             *
             * @default false
             * @property isMasked
             * @type {bool}
             * @public
             */
            this.isMasked = true;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         */
        createChildren: function() {
            this.base();

            this.$password = this.$element.find(SELECTORS.INPUT);
            this.button = document.createElement('button');
            this.button.type = 'button';
            this.button.innerHTML = LABELS.SHOW;
            this.button.classList.add(CLASSES.BTN_TOGGLE);
            this.$toggle = $(this.button);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$password = null;
            this.button = null;
            this.$toggle = null;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            this.base();
            this.$password.addClass(CLASSES.TOGGLE_INPUT);
            this.$element.addClass(CLASSES.TOGGLE_PARENT);
            this.$toggle.insertAfter(this.$password);

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();

            this.onButtonClickHandler = this.onButtonClick.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$toggle.on('click', this.onButtonClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$toggle.off('click', this.onButtonClickHandler);

            return this;
        },

        showPassword: function() {
            this.$password.attr('type', ATTRIBUTES.PLAIN_TEXT);
            this.$toggle.text(LABELS.HIDE);
            this.isMasked = false;

            return this;
        },

        hidePassword: function() {
            this.$password.attr('type', ATTRIBUTES.PASSWORD);
            this.$toggle.text(LABELS.SHOW);
            this.isMasked = true;

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * Toggle password input type to show password and toggle show hide text
         *
         * @method onButtonClick
         * @param {jQuery} e The jQuery event object
         * @public
         */
        onButtonClick: function(e) {
            e.preventDefault();

            if (this.isMasked) {
                this.showPassword();
                return this;
            }

            this.hidePassword();

            return this;
        },

    });

    return TogglePasswordView;
}());
