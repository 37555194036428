var NRD = window.NRD || {};

NRD['./App'] = (function() {
    'use strict';

    var Base = NRD['basejs']; // eslint-disable-line
    var $ = NRD['jquery']; // eslint-disable-line
    var modernizr = NRD['modernizr']; // eslint-disable-line
    var EventBus = NRD['eventbus']; // eslint-disable-line

    // Services
    var BreakpointListener = NRD['./services/BreakpointListener'];
    // var InactivityTimer = NRD['./services/InactivityTimer'];

    // Models
    var AppStateModel = NRD['./models/AppStateModel'];

    // Views
    var HeaderSearchView = NRD['./views/Header/HeaderSearchView'];
    var HeaderUserDetailView = NRD['./views/Header/HeaderUserDetailView'];
    var HeaderBurgerClickView = NRD['./views/Header/HeaderBurgerClickView'];
    var HeaderFlyoutView = NRD['./views/Header/HeaderFlyoutView'];
    var HeaderNestedFlyoutView = NRD['./views/Header/HeaderNestedFlyoutView'];
    var HeaderToggleView = NRD['./views/Header/HeaderToggleView'];
    var HeaderSecondaryToggleView = NRD['./views/Header/HeaderSecondaryToggleView'];
    var AccordionView = NRD['./views/AccordionView'];
    var TabsView = NRD['./views/TabsView'];
    var TooltippyView = NRD['./views/TooltippyView'];
    var ModalView = NRD['./views/ModalView'];
    var BackToTopView = NRD['./views/BackToTopView'];
    var InlineFormView = NRD['./views/InlineFormView'];
    var FormResetView = NRD['./views/FormResetView'];
    var CarouselView = NRD['./views/CarouselView'];
    var SelectorCarouselView = NRD['./views/SelectorCarouselView'];
    var DynamicBundleDetailView = NRD['./views/DynamicBundleDetailView'];
    var ValidationObserverView = NRD['./views/ValidationObserverView'];
    var EmailCaptureView = NRD['./views/EmailCaptureView'];
    var TabcordionView = NRD['./views/TabcordionView'];
    var TogglePasswordView = NRD['./views/TogglePasswordView'];
    var SummaryListToggle = NRD['./views/SummaryListToggle'];
    var ProgressBarView = NRD['./views/ProgressBarView'];
    var SimpleToggleView = NRD['./views/SimpleToggleView'];
    var CheckedCTA = NRD['./views/CheckedCTA'];
    var SkipLinkView = NRD['./views/SkipLinkView'];
    var FilterPanelView = NRD['./views/FilterPanelView'];
    var FilterToggleView = NRD['./views/FilterToggleView'];
    // var VueModalView = NRD['./views/VueModalView'];

    /**
     * Initial application setup. Runs once upon every page load.
     *
     * @class App
     */
    var App = Base.extend({
        /**
         * @constructor
         */
        constructor: function() {
            this.init();
        },

        /**
         * Initializes the application and kicks off loading of prerequisites.
         *
         * @method init
         * @public
         */
        init: function() {
            // Global Services

            // Event Bus pub/sub, must be started first
            this.eventBus = new EventBus();

            this.breakpointListener = new BreakpointListener(this.eventBus);
            // this.inactivityTimer = new InactivityTimer(this.eventBus);
            this.appState = new AppStateModel();

            this.headerSearch = this.createViews(HeaderSearchView, $('.js-headerSearch'));

            // header user menu toggles and moves
            this.headerUserDetail = new HeaderUserDetailView($('.js-userAccount'), this.eventBus, this.breakpointListener);
            this.headerBurgerClick = new HeaderBurgerClickView($('.js-burgerClick'), this.eventBus, this.breakpointListener);
            this.headerFlyout = new HeaderFlyoutView($('.js-flyOut'), this.eventBus, this.breakpointListener);
            this.headerNestedFlyout = new HeaderNestedFlyoutView($('.js-nestedFlyout'), this.eventBus, this.breakpointListener);
            this.headerToggle = new HeaderToggleView($('.js-primaryDropdown'), this.eventBus, this.breakpointListener);
            this.headerSecondaryToggle = new HeaderSecondaryToggleView($('.js-secondaryDropdown'), this.eventBus, this.breakpointListener);

            this.renderAccordions();
            this.tabs = this.createViews(TabsView, $('.js-tabs'));
            this.formResetters = this.createViews(FormResetView, $('.js-form-reset'));
            this.tabcordions = this.createViews(TabcordionView, $('.js-tabcordion'));
            this.togglePassword = this.createViews(TogglePasswordView, $('.js-togglePassword'));
            this.summaryListToggle = this.createViews(SummaryListToggle, $('.js-listToggle'));
            this.createModals();
            this.selectorCarousels = this.createViews(SelectorCarouselView, $('.js-selectorCarousel'));
            this.createCarousels();
            this.backToTopView = new BackToTopView($('.js-backToTop'), this.eventBus, this.breakpointListener);
            this.renderProgress();
            this.simpleToggleView = new SimpleToggleView($('.js-simpleToggle'), this.eventBus, this.breakpointListener);
            this.checkedCTA = new CheckedCTA($('.js-checkedCTA'), this.eventBus, this.breakpointListener);
            this.renderSkipLinks();
            this.renderFilterPanel();
            this.renderFilterToggle();
            this.createTooltips();
            // this.vueModalView = VueModalView(document.querySelector('#vue-app'), this.eventBus, this.breakpointListener);

            this.inlineFormView = new InlineFormView($('.js-inlineForm'), this.eventBus, this.breakpointListener);

            // Manages the dynamic bundle detail page functionality
            this.dynamicBundleDetailView = new DynamicBundleDetailView(
                $('.js-dynBundle'),
                this.eventBus,
                this.breakpointListener
            );

            this.validationObserverView = new ValidationObserverView($('[data-val-controltovalidate]'), this.eventBus, this.breakpointListener);

            this.emailCaptureView = new EmailCaptureView($('.js-email-capture'), this.eventBus, this.breakpointListener);

            this.initThirdPartyServices();

            this.setupHandlers()
                .enable();
        },

        /**
         * Binds the scope of any handler functions
         * Should only be run on initialization of the class
         *
         * @method setupHandlers
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.onBreakpointChangeHandler = this.onBreakpointChange.bind(this);

            return this;
        },

        /**
         * Enables the app
         * Performs any event binding to handlers
         * Forces a breakpoint data update
         *
         * @method enable
         * @public
         */
        enable: function() {
            this.eventBus.on('BREAKPOINT.CHANGE', this.onBreakpointChangeHandler);
            this.onBreakpointChange();
        },

        /**
         * Updates the AppStateModel with new breakpoint information whenever the breakpoint change event fires
         *
         * @method onBreakpointChange
         * @public
         */
        onBreakpointChange: function() {
            var data = {
                currentBreakpoint: this.breakpointListener.getCurrentBreakpoint(),
            };

            this.appState.set(data);
        },

        createViews: function(ViewType, $elements) {
            var length = $elements.length;
            var i = 0;
            var views = [];

            for (i; i < length; i++) {
                views.push(new ViewType($elements.eq(i), this.eventBus, this.breakpointListener));
            }

            return views;
        },

        renderProgress: function() {
            this.progressBarView = this.createViews(ProgressBarView, $('.js-progressBar'));
        },

        // allows for external ajax calls to hit app.createTooltips(); on the window object to re-instantiate tooltips
        createTooltips: function() {
            this.tooltips = this.createViews(TooltippyView, $('.js-tooltip'));
        },

        // should be called before create tooltips to mitigate any memory leak issues stemming from re-instantiating tooltips with ajax
        destroyTooltips: function() {
            for (var i = 0; i < this.tooltips.length; i++) {
                this.tooltips[i].destroy();
            }
            this.tooltips = [];
        },

        createModals: function() {
            this.modalView = new ModalView($('.js-modal'), this.eventBus, this.breakpointListener);
        },

        destroyModals: function() {
            this.modalView.disable();
            this.modalView = null;
        },

        createCarousels: function() {
            this.carousels = this.createViews(CarouselView, $('.js-carousel'));
        },

        destroyCarousels: function() {
            for (var i = 0; i < this.carousels.length; i++) {
                this.carousels[i].destroy();
            }
            this.carousel = [];
        },

        renderAccordions: function() {
            this.accordions = this.createViews(AccordionView, $('.js-accordion'));
        },

        destroyAccordions: function() {
            for (var i = 0; i < this.accordions.length; i++) {
                this.accordions[i].destroy();
            }
            this.accordions = [];
        },

        renderFilterPanel: function() {
            this.filterPanelView = this.createViews(FilterPanelView, $('.js-filterPanel'));
        },

        destroyFilterPanel: function() {
            for (var i = 0; i < this.filterPanelView.length; i++) {
                this.filterPanelView[i].destroy();
            }
            this.filterPanelView = [];
        },

        renderFilterToggle: function() {
            this.filterToggleView = this.createViews(FilterToggleView, $('.js-filterToggle'));
        },

        destroyFilterToggle: function() {
            for (var i = 0; i < this.filterToggleView.length; i++) {
                this.filterToggleView[i].destroy();
            }
            this.filterToggleView = [];
        },

        renderSkipLinks: function() {
            this.skipLinkView = this.createViews(SkipLinkView, $('.js-skipLink'));
        },

        destroySkipLinks: function() {
            for (var i = 0; i < this.skipLinkView.length; i++) {
                this.skipLinkView[i].destroy();
            }
            this.skipLinkView = [];
        },

        initThirdPartyServices: function() {
            this.initCounters();
        },

        initCounters: function() {
            var $el = $('.js-counter');
            var elCount = $el.length;
            var elMax;
            var i = 0;

            if (elCount === 0) {
                return;
            }

            for (; i < elCount; i++) {
                elMax = $el.eq(i).attr('data-max');

                $el.eq(i).maxlength({
                    max: elMax ? elMax : 120,
                    feedbackTarget: $el.eq(i).next('.js-counterCaption'),
                    feedbackText: '{r} characters remaining',
                });
            }
        },
    });

    return App;
}());
