var NRD = window.NRD || {};

NRD['./views/VueModalView'] = (function() {
    'use strict';

    var Vue = NRD['vue']; // eslint-disable-line

    var Modal = {
        name: "Modal",
        props: {
            id: {
                type: String,
                required: true
            },
            showModal: {
                type: Boolean,
                required: true,
                default: false
            }
        },
        created() {
            // Open on page load
            this.$emit("emit-toggle", this)
        },
        template: `
            <section :id="id" class="modal js-modal-target" :class="{isActive: showModal}" role="dialog" aria-labelledby="modal-headline1" aria-live="off" tabindex="0">
                <div class="modal-content js-modal-content">
                    <slot></slot>
                    <button class="modal-content-close" @click="$emit('close')" aria-label="Close" type="button">
                        <i class="icn icn_close"></i>
                    </button>
                </div>
            </section>
        `,
    }
    
    // TODO: multiple modals on one page
    // TODO: Open on page load
    // TODO: Open on event bus / set timers/session state
    // TODO: Test Return to Focus after open on page load
    // TODO: Test open modal when session modal opens - multiple modal cases?

    var VueModalView = function($element, eventBus, breakpointListener) {
        var app = Vue.createApp({
            components: {
                Modal
            },
            data() {
                return {
                    showModal: false,
                    showSessionModal: false
                }
            },
              methods: {
                toggleModal() {
                    this.showModal = !this.showModal
                },
                toggleSessionModal() {
                    this.showSessionModal = !this.showSessionModal
                }
            }
        })

        app.mount($element);
    };

    return VueModalView;
}());
