var NRD = window.NRD || {};

NRD['./shim'] = (function() {
    'use strict';

    // Shim
    NRD['basejs'] = window.Base; // eslint-disable-line
    NRD['jquery'] = window.jQuery; // eslint-disable-line
    NRD['modernizr'] = window.Modernizr; // eslint-disable-line
    NRD['eventbus'] = window.EventBus; // eslint-disable-line
    NRD['matchmedia'] = window.matchMedia; // eslint-disable-line
    NRD['vue'] = window.Vue; //eslint-disable-line
}());
