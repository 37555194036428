var NRD = window.NRD || {};

NRD['./views/SelectorCarouselView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var CarouselView = NRD['./views/CarouselView'];

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        CELL_AVAILABLE: 'available',
        CELL_SELECTED: 'isSelected',
        CELL_DISABLED: 'isDisabled',
    };

    var SELECTORS = {
        DATERADIO: '.dateRadio',
    };

    /**
     * Handles showing and hiding items in a horizontal list
     *
     * @class SelectorCarouselView
     * @extends {BaseView}
     */
    var SelectorCarouselView = CarouselView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            return this.setInitialSelected();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onCellClickHandler = this.onCellClick.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$cells.on('click', this.onCellClickHandler);

            this.base();

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$cells.on('click', this.onCellClickHandler);

            this.base();

            return this;
        },

        /**
         * gets the taget index for showing the last visited slide when the layout changes
         *
         * @method setPriorityCellIndex
         * @public
         * @chainable
         */
        setPriorityCellIndex: function(slideIndex) {
            if (this.selectedIndex !== null) {
                this.priorityCellIndex = this.selectedIndex;

                return this;
            }

            this.priorityCellIndex = ((slideIndex * this.visibleCellCount) || 0);

            return this;
        },

        /**
         * handles selected index and priority index settings if a selected cell is set in the dom on render
         *
         * @method setInitialSelected
         * @public
         * @chainable
         */
        setInitialSelected: function() {
            var intentSelectedIndex = this.$cells.index(this.$cells.filter('.' + CLASSES.CELL_SELECTED));

            this.selectedIndex = ((intentSelectedIndex > -1) ? intentSelectedIndex : null);

            if (this.selectedIndex === null) {
                return this;
            }

            return this
                .setPriorityCellIndex()
                .update();
        },

        /**
         * handles selected index and priority index settings if a cell is selected by the user
         *
         * @method updateSelected
         * @public
         * @chainable
         */
        updateSelected: function($selectedCell) {
            this.selectedIndex = this.$cells.index($selectedCell);

            this.$cells.removeClass(CLASSES.CELL_SELECTED);
            $selectedCell.addClass(CLASSES.CELL_SELECTED);

            return this.setPriorityCellIndex();
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking the a cell
         * @method onCellClick
         * @public
         */
        onCellClick: function(e) {
            var $cell = $(e.currentTarget);

            if ($cell.hasClass(CLASSES.CELL_DISABLED) || !$(SELECTORS.DATERADIO, $cell).hasClass(CLASSES.CELL_AVAILABLE)) {
                return;
            }

            this.updateSelected($cell);
        },
    });

    return SelectorCarouselView;
}());
