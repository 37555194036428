var NRD = window.NRD || {};

NRD['./views/ProgressBarView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        BAR: '.progressBar-status',
        BAR_FILL: '.progressBar-status-fill',
        VALUE: '.js-progressBar-value',
    };

    /**
     * An object of classes used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        SUCCESS: 'progressBar-status-fill_isSuccess',
        NO_ANIMATION: 'noProgressAnimation',
    };

    /**
     * An object of constant values used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var CONST = {
        ANIMATION_SPEED: 500,
        ANIMATION_DELAY_SPEED: 500,
        DEBOUNCE_TIME: 300,
    };

    /**
     * An object of any custom data attributes used
     *
     * @property DATA
     * @type {Object}
     * @final
     */
    var DATA = {
        TOTAL_VALUE: 'progressTotalValue',
    };

    /**
     * Measures Progress Bar and pulls data attribute to dynamically adjust width and styling of progress bar fill based on amount needed to trigger free delivery in orderSummary
     * @class ProgressBarView
     */
    var ProgressBarView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element A reference to the containing DOM element
         * @param {function} eventBus
         * @param {function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to the parent container
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            /**
            * Stores measured width of progress bar through breakpoints
            *
            * @default 0
            * @property barWidth
            * @type {Number}
            * @public
            */
            this.barWidth = 0;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         * @returns {this}
         */
        createChildren: function() {
            this.base();

            this.$window = $(window);
            this.$body = $('body');
            this.$progressBar = this.$element.find(SELECTORS.BAR);
            this.$progressBarFill = this.$progressBar.find(SELECTORS.BAR_FILL);
            this.currentProgressValue = this.$progressBar.find(SELECTORS.VALUE).text();
            this.totalValue = this.$element.data(DATA.TOTAL_VALUE);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$window = null;
            this.$body = null;
            this.$progressBar = null;
            this.$progressBarFill = null;
            this.currentProgressValue = null;
            this.totalValue = null;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         * @returns {this}
         */
        layout: function() {
            this.base();

            this.setStatusClass();
            this.adjustProgress();

            return this;
        },

        /**
         * Uses the success value gathered from data attribute on parent.
         * Measures and toggles class for success based on new value.
         *
         * @method setStatusClass
         * @public
         * @chainable
         * @returns {this}
         */
        setStatusClass: function() {
            if (this.currentProgressValue >= this.totalValue) {
                this.$progressBarFill.addClass(CLASSES.SUCCESS);
            } else {
                this.$progressBarFill.removeClass(CLASSES.SUCCESS);
            }

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();

            this.onResizeHandler = this.onResize.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            this.$window.on('resize', this.onResizeHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            this.$window.off('resize', this.onResizeHandler);

            return this;
        },

        /**
         * Used with Debounce to fire measurement and animation logic
         * after a set amount of time instead of constantly through
         * window resize event
         *
         * @method adjustProgress
         * @chainable
         * @private
         */

        adjustProgress: function() {
            // measure width of bar as it changes based on
            // browser width and set percentages
            this.measureLayout();

            // if the body has a noAnimation class, add width with no animation to progress bar and return logic
            if (this.$body.hasClass(CLASSES.NO_ANIMATION)) {
                this.noAnimateFill();

                return this;
            }

            // if the body does not have noProgressAnimation class, we'll skip above logic and animate progress bar width
            this.animateFill();

            return this;
        },

        /**
         * Measures bar width and sets percentage based on
         * the current value divided by the data attribute success
         * value multiplied by the measured width of the progressBar.
         *
         * @method measureLayout
         * @private
         * @returns {this}
         */
        measureLayout: function() {
            this.barWidth = this.$progressBar.width();
            this.fillPercentage = this.currentProgressValue / this.totalValue * this.barWidth;

            return this;
        },

        /**
         * Trigger jQuery animate and dynamically set
         * width on progress-status-fill div
         *
         * @method animateFill
         * @private
         * @returns {this}
         */
        animateFill: function() {
            this.$progressBarFill.stop(true, true)
                .delay(CONST.ANIMATION_DELAY_SPEED)
                .animate({
                    width: this.fillPercentage,
                }, CONST.ANIMATION_SPEED);

            return this;
        },

        /**
         * Dynamically set width with no animation on page load
         * and measurement
         *
         * @method noAnimateFill
         * @private
         */
        noAnimateFill: function() {
            this.$progressBarFill.width(this.fillPercentage);
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * When window resizes measure the progress bar width
         * progress bar is responsive and is always 100 percent
         * of its container and then animate inline width based
         * on value.
         *
         * @method onResize
         * @public
         * @returns {this}
         */
        onResize: function() {
            var debounceResize = this.debounce(this.adjustProgress, CONST.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @returns {this}
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return ProgressBarView;
}());
