var NRD = window.NRD || {};

NRD['./views/DynamicBundleDetailView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        BUNDLE_ITEMS: '.js-dynBundle-items',
        BUNDLE_ITEM: '.js-dynBundle-item',
        BUNDLE_TRIGGERS: '.js-dynBundle-trigger',
        BUNDLE_CONTINUE: '.js-dynBundle-continue',
        BUNDLE_ADD_TO_CART: '#js-dynBundle-submit',
        BUNDLE_TITLE: '.js-dynBundle-title',
        BUNDLE_TOTAL: '#js-dynBundle-total',
        BUNDLE_ALERT: '#js-dynBundle-alert',
        BUNDLE_QTY: '#js-dynBundle-qty',
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATTRS
     * @type {Object}
     * @final
     */
    var ATTRS = {
        REMOVE_RESTRICTION_ID: 'data-dynBundle-remove-restriction-id',
        CURRENT_ID: 'data-dynBundle-id',
        HAS_FIXED_PRICE: 'data-dynBundle-fixedPrice',
    };

    /**
     * Dynamic Bundle Detail View
     *
     * @class DynamicBundleDetailView
     * @extends {BaseView}
     */
    var DynamicBundleDetailView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element A reference to the containing DOM element
         */
        constructor: function($element, eventBus, breakpointListener) {
            // If no element is found, return early
            if (!$element.length) {
                return;
            }

            /**
             * Tracks whether component has a fixed price or configurable price
             *
             * @default false
             * @property hasFixedPrice
             * @type {Boolean}
             * @public
             */
            this.hasFixedPrice = false;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base()
                .setupHandlers()
                .createChildren()
                .updateView();

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();

            this.onChangeHandler = this.onChange.bind(this);
            this.onSubmitClickHandler = this.onSubmitClick.bind(this);
            this.onQtyChangeHandler = this.onQtyChange.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$triggers = this.$element.find(SELECTORS.BUNDLE_TRIGGERS);
            this.$scopes = this.$element.find(SELECTORS.BUNDLE_ITEMS);
            this.$continueBtns = this.$element.find(SELECTORS.BUNDLE_CONTINUE);
            this.$submitBundle = $(SELECTORS.BUNDLE_ADD_TO_CART);
            this.$bundleTotal = $(SELECTORS.BUNDLE_TOTAL);
            this.$bundleAlert = $(SELECTORS.BUNDLE_ALERT);
            this.$bundleQty = $(SELECTORS.BUNDLE_QTY);
            this.fixedPrice = this.$element.attr(ATTRS.HAS_FIXED_PRICE);

            this.$continueBtns.prop('disabled', true);
            this.$submitBundle.prop('disabled', true);
            this.$bundleAlert.hide();

            if (this.fixedPrice !== '' && this.fixedPrice !== undefined) {
                this.hasFixedPrice = true;
            }

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$triggers = null;
            this.$scopes = null;
            this.$continueBtns = null;
            this.$submitBundle = null;
            this.$bundleTotal = null;
            this.$bundleAlert = null;
            this.$bundleQty = null;
            this.hasFixedPrice = null;
            this.fixedPrice = null;

            return this;
        },

        /**
         * Callback for inherited views to enable specific handlers
         *
         * @method onEnable
         * @public
         * @chainable
         */
        onEnable: function() {
            this.base();

            this.$triggers.on('change', this.onChangeHandler);
            this.$submitBundle.on('click', this.onSubmitClickHandler);
            this.$bundleQty.on('change', this.onQtyChangeHandler);

            return this;
        },

        /**
         * Callback for inherited views to disable specific handlers
         *
         * @method onDisable
         * @public
         * @chainable
         */
        onDisable: function() {
            this.base();

            this.$triggers.off('change', this.onChangeHandler);
            this.$submitBundle.off('click', this.onSubmitClickHandler);
            this.$bundleQty.off('change', this.onQtyChangeHandler);

            return this;
        },

        /**
         * Notify the App with events
         *
         * @method notifyApp
         * @public
         * @param {String} restrictedId A reference to the intended accordion panel ID
         * @param {String} currentId A reference to the current panel ID
         * @param {String} bundleTitle A reference to the selected bundle item title
         * @chainable
         */
        notifyApp: function(restrictedId, currentId, bundleTitle) {
            this.eventBus.trigger(
                'REMOVE_RESTRICTION',
                restrictedId
            );

            this.eventBus.trigger(
                'UPDATE_TRIGGER_TITLE', {
                    id: currentId,
                    meta: bundleTitle,
                }
            );

            return this;
        },

        /**
         * Update elements in the DOM.
         *
         * @method updateView
         * @public
         * @param {jQuery} $continueBtn A reference to the panel's continue button
         * @chainable
         */
        updateView: function($continueBtn) {
            var orderTotal = this.tallyOrder();

            if ($continueBtn !== undefined && $continueBtn.length) {
                $continueBtn.prop('disabled', false);
            }

            if (orderTotal !== undefined && !isNaN(orderTotal)) {
                this.$bundleTotal.text(orderTotal);
            }

            // If an item is selected in each section, enable the submit button
            if (this.verifySelections()) {
                this.$submitBundle.prop('disabled', false);
                this.$bundleAlert.fadeOut();
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // HELPERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Validate the order. If invalid, display error.
         *
         * @method validateBundle
         * @private
         * @return {Boolean} The bundle selections pass validation.
         */
        validateBundle: function() {
            if (!this.verifySelections()) {
                this.$bundleAlert.fadeIn();
                return false;
            }

            return true;
        },

        /**
         * Check if a selection exists in each section
         *
         * @method verifySelections
         * @private
         * @return {Boolean} A selection exists in every section
         */
        verifySelections: function() {
            var i;

            for (i = 0; i < this.$scopes.length; i++) {
                var completed = this.$scopes.eq(i).find(SELECTORS.BUNDLE_TRIGGERS + ':checked');

                if (!completed.length) {
                    return false;
                }
            }

            return true;
        },

        /**
         * Calculate the total of currently selected items.
         *
         * @method tallyOrder
         * @private
         * @return {Integer} orderTotal A total price for all selected items
         */
        tallyOrder: function() {
            var orderTotal = 0;
            var i;
            var $selectedItems;
            var multiplier = parseInt(this.$bundleQty.val());

            // If this is a fixed price bundle
            if (this.hasFixedPrice) {
                return parseFloat(this.$element.attr(ATTRS.HAS_FIXED_PRICE) * multiplier).toFixed(2);
            }

            $selectedItems = this.$element.find(SELECTORS.BUNDLE_TRIGGERS + ':checked');

            for (i = 0; i < $selectedItems.length; i++) {
                var itemPrice = parseFloat($selectedItems.eq(i).val());
                orderTotal = parseFloat(orderTotal + itemPrice);
            }

            // always return 2 decimal places
            orderTotal = orderTotal.toFixed(2);

            return orderTotal;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for when a selection changes in the DOM
         *
         * @method onChange
         * @private
         * @param {Object} event The event object returned by the click
         */
        onChange: function(event) {
            var $selectedItem = $(event.currentTarget);
            var $bundleScope = $selectedItem.parents(SELECTORS.BUNDLE_ITEMS);
            var restrictedId = $bundleScope.attr(ATTRS.REMOVE_RESTRICTION_ID);
            var currentId = $bundleScope.attr(ATTRS.CURRENT_ID);
            var $continueBtn = $bundleScope.find(SELECTORS.BUNDLE_CONTINUE);
            var bundleTitle = $selectedItem.parent(SELECTORS.BUNDLE_ITEM).find(SELECTORS.BUNDLE_TITLE).text();

            this.notifyApp(restrictedId, currentId, bundleTitle)
                .updateView($continueBtn);

            return this;
        },

        /**
         * Handler for when the bundle quantity selector is changed
         *
         * @method onQtyChange
         * @private
         * @param {Object} event The event object returned by the click
         */
        onQtyChange: function(event) {
            this.updateView();
        },

        /**
         * Handler for submitting the bundle form
         *
         * @method onSubmitClick
         * @private
         * @param {Object} event The event object returned by the click
         */
        onSubmitClick: function(event) {
            var orderTotal;

            if (!this.validateBundle()) {
                // The order is invalid, do not submit the form
                event.preventDefault();
            } else {
                orderTotal = this.tallyOrder();
                // submit form here
                // Pass orderTotal along if desired.
                // orderTotal is an integer representing the sum of the selected item values.
            }
        },

    });

    return DynamicBundleDetailView;
}());
