var NRD = window.NRD || {};

NRD['./views/SkipLinkView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of attributes used in this view
     *
     * @property ATTRIBUTES
     * @type {Object}
         * @final
     */
    var ATTRIBUTES = {
        TABINDEX: 'tabindex',
    };

    /**
     * An object of classes used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */

    var CLASSES = {
        VISUALLY_HIDDEN: 'isVisuallyHidden',
    };

    /**
     * An object of events used in this view
     *
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CLICK: 'click',
        BLUR: 'blur',
        FOCUS: 'focus',
    };

    /**
     * An object of properties used in this view
     *
     * @property PROPERTIES
     * @type {Object}
     * @final
     */
    var PROPERTIES = {
        HASH: 'hash',
    }

    /**
     * Grabs href from skip link, associates with id on page, and applies focus while removing tab index
     * when new focus is left to avoid other accessibility focus toggle issues between various
     * screen readers and keyboard navigation techniques.
     * Used similar logic found at https://github.com/selfthinker/dokuwiki_template_writr/blob/master/js/skip-link-focus-fix.js
     * Linked from article found here https://axesslab.com/skip-links/
     * @class skipLinkView
     */
    var skipLinkView = BaseView.extend({
        /**
         * @constructor
         * @returns {this}
         * @param  {jQuery} $element A reference to the containing DOM element
         * @param {function} eventBus
         * @param {function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * hash pulled from $element click
             *
             * @default null
             * @property destinationID
             * @type {string}
             * @public
             */
            this.destinationID = null;

            /**
             * associated div element found in DOM after getting destinationID
             * from $element click
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$destination = null;

            this.base($element, eventBus, breakpointListener);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @returns {this}
         * @public
         */
        createChildren: function() {
            this.base();
            this.$body = $('body');
            this.destinationID = this.$element.prop(PROPERTIES.HASH);
            this.$destination = this.$body.find(this.destinationID);

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();

            this.onSkipLinkHandler = this.onSkipLinkClick.bind(this);
            this.onFocusLinkHandler = this.onSkipFocus.bind(this);
            this.onFocusBlurHandler = this.onBlurLink.bind(this);
            this.onBlurElementHandler = this.onBlurElement.bind(this);

            return this;
        },

        /**
         * Callback for inherited views to enable remove view specific handlers
         *
         * @method onEnable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            this.$element.on(EVENTS.CLICK, this.onSkipLinkHandler);
            this.$element.on(EVENTS.FOCUS, this.onFocusLinkHandler);
            this.$element.on(EVENTS.BLUR, this.onFocusBlurHandler);
            this.$destination.on(EVENTS.BLUR, this.onBlurElementHandler);
            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method disable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            this.$element.off(EVENTS.CLICK, this.onSkipLinkHandler);
            this.$element.off(EVENTS.FOCUS, this.onFocusLinkHandler);
            this.$element.off(EVENTS.BLUR, this.onFocusBlurHandler);
            this.$destination.off(EVENTS.BLUR, this.onBlurElementHandler);
            return this;
        },

        /**
         * sets tabindex attribute for voiceOver and then focuses on that div
         * should have explantory text announcing what you've just moved past
         *
         * @method moveFocus
         * @public

         */
        moveFocus: function() {
            this.$destination.attr(ATTRIBUTES.TABINDEX, -1);
            this.$destination.focus();
        },

        /**
         * Remove tabindex attribute from element that initial click
         * added to destination div when blur event takes place and
         * add visually hidden class back to skip link
         *
         * @method clearAttributes
         * @public
         * @param {Event} event from click

         */
        clearAttributes: function() {
            this.$destination.removeAttr(ATTRIBUTES.TABINDEX);
            this.$element.addClass(CLASSES.VISUALLY_HIDDEN);
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a skip link - prevents default from adding destinationID to URL
         * @method onSkipLinkClick
         * @public
         * @param {Event} event from click
         * @returns {this}
         */
        onSkipLinkClick: function(event) {
            event.preventDefault();
            this.moveFocus();
            return this;
        },

        /**
         * If user focuses into link with keyboard, remove visually hidden classes
         * @method onSkipFocus
         * @public
         */
        onSkipFocus: function() {
            this.$element.removeClass(CLASSES.VISUALLY_HIDDEN);
        },

        /**
         * If user tabs off of skip link without clicking, add class back onto link
         * @method onBlurLink
         * @public
         */
        onBlurLink: function() {
            this.$element.addClass(CLASSES.VISUALLY_HIDDEN);
        },

        /**
         * Handler for bluring element focus moved to, and removing injected tabindex attribute
         * @method onBlurElement
         * @public
         * @returns {this}
         */
        onBlurElement: function() {
            this.clearAttributes();
            return this;
        },

    });

    return skipLinkView;
}());
