var NRD = window.NRD || {};

NRD.config = {
    breakpoints: {
        large: {
            media: 'screen',
            arguments: '(min-width: 960px)',
            width: 960,
        },
        medium: {
            media: 'screen',
            arguments: '(min-width: 768px)',
            width: 768,
        },
        small: {
            media: 'screen',
            arguments: '(min-width: 320px)',
            width: 320,
        },
        base: {
            media: 'screen',
            arguments: '(min-width: 1em)',
            width: 1,
        },
    },
};
