var NRD = window.NRD || {};

NRD['./views/Header/HeaderBurgerClickView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        HEADER: '.js-header',
        NAVIGATION: '.js-mainMenu',
        NAVIGATION_CONTAINER: '.headerNav-menu',
        MAIN_TRIGGER: '.js-primaryDropdown',
        DROPDOWN: '.js-mainDropdown',
        CLOSE: '.js-closeMobileMenu',
        FLYOUT_PANEL: '.headerDropdown-section-panel',
        FOCUSABLE_ELEMENTS: 'button, a[href], input, select, textarea, [tabindex], [data-focusable]',
        FOCUSABLE_ELEMENTS_EXCLUSIONS: ':hidden, [tabindex="-1"], [disabled]',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        SHOW_DROPDOWN: 'headerNav-menu_showDrop',
        HEADER_DROPCLASS_SNEEZEGUARD: 'header_dropOpen',
        MOBILE_CLOSE: 'js-closeMobileMenu',
        IS_OPEN: 'isOpen',
        NO_SCROLL: 'noScroll',
        FLYOUT_OPEN: 'flyOut_isOpen',
        BODY_FIXED: 'headerFixed',
    };

    /**
     * An object of the Modernizr values
     *
     * @property MODERNIZR
     * @type {Object}
     * @final
     */
    var MODERNIZR = {
        TOUCH: 'touch',
        NO_TOUCH: 'no-touch',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    var EVENTS = {
        CLOSE_DROPDOWN: 'CLOSE_DROPDOWN',
        HAMBURGER_OPEN: 'HAMBURGER_OPEN',
        SECONDARY_OPEN: 'SECONDARY_OPEN',
        HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
        HOVER_OPEN: 'HOVER',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    var $window = $(window);
    var $body = $('body');
    var $html = $('html');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderBurgerClickView
     * @extends {BaseView}
     */
    var HeaderBurgerClickView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            /**
             * Sets current scroll point to offset body with fixed positioning to fix shift
             *
             * @default false
             * @property scrollTop
             * @type {number}
             * @public
             */
            this.scrollTop = 0;

            /**
             * Tracks previous scroll top value to determine offset
             *
             * @default false
             * @property prevScrollTop
             * @type {number}
             * @public
             */
            this.prevScrollTop = 0;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @returns {this}
         * @public
         */
        init: function() {
            this.base();

            this.isOpen = false;
            this.setBreakpoint();
            $window.on(EVENTS.CLOSE_DROPDOWN, this.onCloseClickHandler);
            this.eventBus.on(EVENTS.SECONDARY_OPEN, this.onExternalCloseHandler);
            this.eventBus.on(EVENTS.HOVER_OPEN, this.onExternalCloseHandler);
            this.eventBus.on(EVENTS.HIDE_MAIN_MENU, this.onExternalCloseHandler.bind(this));

            return this;
        },

        onExternalClose: function() {
            this.close(false);
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @returns {this}
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onBurgerClickHandler = this.onBurgerClick.bind(this);
            this.onCloseClickHandler = this.onCloseClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);
            this.onBodyClickHandler = this.onBodyClick.bind(this);
            this.onExternalCloseHandler = this.onExternalClose.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @returns {this}
         * @public
         */
        createChildren: function() {
            this.base();

            this.$header = $body.find(SELECTORS.HEADER);
            this.$menu = $body.find(SELECTORS.NAVIGATION);
            this.$menuContainer = this.$menu.find(SELECTORS.NAVIGATION_CONTAINER);
            this.$dropdown = this.$menu.find(SELECTORS.DROPDOWN);
            this.$closeBtn = this.$menu.find(SELECTORS.CLOSE);
            this.$flyoutPanels = this.$menu.find(SELECTORS.FLYOUT_PANEL);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @returns {this}
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$header = null;
            this.$menu = null;
            this.$menuContainer = null;
            this.$dropdown = null;
            this.$closeBtn = null;
            this.$flyoutPanels = null;
            this.breakpoint = null;

            return this;
        },

        /**
         * Set initial layout
         *
         * @method layout
         * @public
         * @returns {this}
         */
        layout: function() {
            this.base();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @returns {this}
         * @public
         */
        onEnable: function() {
            this.$element.on('click', this.onBurgerClickHandler);
            this.$closeBtn.on('click', this.onCloseClickHandler);
            $window.on('resize', this.onWindowResize);
            $body.on('click tap', this.onBodyClickHandler);
            this.$dropdown.attr('aria-hidden', 'true');

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @returns {this}
         * @public
         */
        onDisable: function() {
            this.$element.off('click', this.onBurgerClickHandler);
            this.$closeBtn.off('click', this.onCloseClickHandler);
            $window.off('resize', this.onWindowResize);
            $body.off('click tap', this.onBodyClickHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @returns {this}
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Logic for clicking the trigger and toggling between open/closed states
         *
         * @method toggle
         * @returns {this}
         * @public
         */
        toggle: function(event) {
            if (this.isOpen) {
                this.close(event);

                return this;
            }
            this.open(event);

            return this;
        },

        /**
         * Adds active class to the root element.
         *
         * @method open
         * @returns {this}
         * @private
         */
        open: function(e) {
            this.isOpen = true;
            this.setBreakpoint();

            if (this.isMobile) {
                this.prevScrollTop = $window.scrollTop();
                $body.addClass(CLASSES.NO_SCROLL).css({
                    top: -this.prevScrollTop,
                });
                this.$menuContainer.addClass(CLASSES.SHOW_DROPDOWN);
            }

            if ($body.hasClass(CLASSES.BODY_FIXED)) {
                this.$menuContainer.addClass(CLASSES.SHOW_DROPDOWN);
            }

            this.$dropdown.addClass(CLASSES.IS_OPEN);
            this.$dropdown.attr('aria-hidden', 'false');
            this.$header.addClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);

            this.eventBus.trigger(EVENTS.HAMBURGER_OPEN);

            if (this.isMobile) {
                this.$focusableElements = this.$dropdown
                    .find(SELECTORS.FOCUSABLE_ELEMENTS)
                    .not(SELECTORS.FOCUSABLE_ELEMENTS_EXCLUSIONS);
                this.$focusableElementsFirst = this.$focusableElements[0];
                this.$focusableElementsLast = this.$focusableElements[this.$focusableElements.length - 1];

                // since we toggle visibility with CSS of dropdown, we need to wait for css animation to complete
                // to make the dropdown links visible before we set focus when on mobile
                setTimeout(function() {
                    this.$focusableElementsFirst.focus();
                }.bind(this), 350);
            }

            return this;
        },

        /**
         * Removes active class from the root element
         *
         * @method close
         * @returns {this}
         * @private
         */
        close: function(isLocal) {
            this.isOpen = false;
            this.setBreakpoint();

            if (typeof isLocal === 'undefined') {
                isLocal = true;
            }

            if (this.isMobile) {
                this.scrollTop = $window.scrollTop();
                $body.removeClass(CLASSES.NO_SCROLL).css({
                    top: '',
                });

                if (this.prevScrollTop > this.scrollTop) {
                    $window.scrollTop(this.prevScrollTop);
                }

                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
                this.$dropdown.scrollTop(0);
                this.$closeBtn.blur();
                this.$element.focus();
            }

            if ($body.hasClass(CLASSES.BODY_FIXED)) {
                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
            }

            this.$dropdown.removeClass(CLASSES.IS_OPEN);
            this.$dropdown.attr('aria-hidden', 'true');
            // remove this class no matter if at mobile or not when breakpoint changes happen
            this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);

            if (isLocal) {
                this.$header.removeClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);
            }

            if (this.$dropdown.hasClass(CLASSES.FLYOUT_OPEN)) {
                this.$dropdown.removeClass(CLASSES.FLYOUT_OPEN);
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @returns {this}
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            if (this.isOpen && this.isDesktop) {
                this.close();
            }

            if ($body.hasClass(CLASSES.NO_SCROLL) && this.isDesktop) {
                this.scrollTop = $window.scrollTop();
                $body.removeClass(CLASSES.NO_SCROLL).css({
                    top: '',
                });
                if (this.prevScrollTop > this.scrollTop) {
                    $window.scrollTop(this.prevScrollTop);
                }
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a trigger
         *
         * @method onBurgerClick
         * @private
         */
        onBurgerClick: function(event) {
            event.preventDefault();
            event.stopPropagation();

            this.toggle();

            return this;
        },

        /**
         * Handler for clicking a trigger
         *
         * @method onCloseClick
         * @private
         */
        onCloseClick: function(event) {
            event.preventDefault();


            this.toggle();

            return this;
        },

        /**
         * Check for body click or tap outside of open list panel, and trigger
         * hideItems event
         *
         * @method onBodyClick
         * @param {jQuery} e The jQuery event object
         * @public
         */

        onBodyClick: function(event) {
            if (this.isMobile || !this.isOpen) {
                return;
            }

            if (this.isOpen && !$(event.target).closest(SELECTORS.NAVIGATION).length) {
                this.close();
            }
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderBurgerClickView;
}());
