var NRD = window.NRD || {};

NRD['./views/Header/HeaderSecondaryToggleView'] = (function() { // jshint ignore:line
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        HEADER: '.js-header',
        SECONDARY_PARENT: '.js-secondaryDropdown',
        SECONDARY_TRIGGER: '.js-secondaryDropdown-trigger',
        SECONDARY_CHILD: '.js-secondaryChild',
        STATUS_CONTAINER: '.js-statusContainer',
        SHOPPING_CART: '.js-shoppingCart',
        MOBILE_URL: '.js-mobileUrl',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        HEADER_DROPCLASS_SNEEZEGUARD: 'header_dropOpen',
        IS_OPEN: 'isOpen',
        BODY_FIXED: 'headerFixed',
        ALLOW_CLICK: 'js-allowClick',
        ADD_CURSOR_POINTER: 'addPointer',
        MINICART: 'miniCart',
    };

    /**
     * An object of the Modernizr values
     *
     * @property MODERNIZR
     * @type {Object}
     * @final
     */
    var MODERNIZR = {
        TOUCH: 'touch',
        NO_TOUCH: 'no-touch',
    };

    var EVENTS = {
        HAMBURGER_OPEN: 'HAMBURGER_OPEN',
        SECONDARY_OPEN: 'SECONDARY_OPEN',
        HOVER_OPEN: 'HOVER',
        KEY_UP: 'keyup',
        CLOSE_SECONDARY_DROPDOWNS: 'CLOSE_SECONDARY_DROPDOWNS',
    };

    /**
     * An array of key strings used in this view
     * @default null
     * @property KEYS
     * @type {Object}
     * @final
     */
    var KEYS = {
        ESCAPE: 'Escape',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the timing constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * An object of the Data attribute constants used in this view
     *
     * @property DATA
     * @type {Object}
     * @final
     */
    var DATA = {
        MENU_TYPE: 'data-menuType',
    };

    /**
     * An object of the strings used to convey messaging for users with screen readers
     * Insert space as we chain this with the menu type from data attribute
     *
     * @property MESSAGING
     * @type {Object}
     * @final
     */
    var MESSAGING = {
        OPEN_STATUS: ' menu is open.',
        CLOSED_STATUS: ' menu has been closed.',
    };

    var $window = $(window);
    var $body = $('body');
    var $html = $('html');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderSecondaryToggleView
     * @extends {BaseView}
     */
    var HeaderSecondaryToggleView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param { eventBus } eventBus to send events between interactions
         * @param { breakpointListener } breakpointListener to measure and return value of window
         */
        constructor: function($element, eventBus, breakpointListener) {
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            /**
             * Sets current scroll point to offset body with fixed positioning to fix shift
             *
             * @default false
             * @property scrollTop
             * @type {number}
             * @public
             */
            this.scrollTop = 0;

            /**
             * Tracks previous scroll top value to determine offset
             *
             * @default false
             * @property prevScrollTop
             * @type {number}
             * @public
             */
            this.prevScrollTop = 0;

            this.$currentTarget = null;

            this.$currentDropdown = null;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         * @returns { this }
         */
        init: function() {
            this.base();

            this.isOpen = false;

            this.eventBus.on(EVENTS.HAMBURGER_OPEN, this.onExternalCloseHandler);
            this.eventBus.on(EVENTS.HOVER_OPEN, this.onExternalCloseHandler);
            this.eventBus.on(EVENTS.CLOSE_SECONDARY_DROPDOWNS, this.onExternalCloseHandler);

            return this;
        },

        onExternalClose: function() {
            this.close(false);
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         * @returns { this }
         */
        setupHandlers: function() {
            this.base();
            this.onSecondaryTriggerClickHandler = this.onSecondaryTriggerClick.bind(this);
            this.onChildLinkClickHandler = this.onChildLinkClick.bind(this);
            this.onBodyClickHandler = this.onBodyClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);
            this.onExternalCloseHandler = this.onExternalClose.bind(this);
            this.onKeyUpHandler = this.onKeyUp.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         * @returns { this }
         */
        createChildren: function() {
            this.base();
            this.$document = $(document);
            this.$header = $body.find(SELECTORS.HEADER);
            this.$trigger = this.$element.find(SELECTORS.SECONDARY_TRIGGER);
            this.$child = this.$element.find(SELECTORS.SECONDARY_CHILD);
            this.$childLinks = this.$child.find('a');
            this.$statusContainer = this.$element.find(SELECTORS.STATUS_CONTAINER);
            this.$shoppingCartLink = this.$element.find(SELECTORS.SHOPPING_CART);
            this.$mobileUrl = this.$element.find(SELECTORS.MOBILE_URL).attr('href');

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         * @returns { this }
         */
        removeChildren: function() {
            this.$document = null;
            this.$header = null;
            this.$trigger = null;
            this.$child = null;
            this.$childLinks = null;
            this.$statusContainer = null;
            this.$shoppingCartLink = null;
            this.$mobileUrl = null;

            return this;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @returns {this}
         */
        layout: function() {
            this.setBreakpoint();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns { this }
         */
        onEnable: function() {
            this.$trigger.on('click', this.onSecondaryTriggerClickHandler);
            this.$childLinks.on('click', this.onChildLinkClickHandler);
            $body.on('click tap', this.onBodyClickHandler);
            $window.on('resize', this.onWindowResize);
            this.$document.on(EVENTS.KEY_UP, this.onKeyUpHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns { this }
         */
        onDisable: function() {
            this.$trigger.off('click', this.onSecondaryTriggerClickHandler);
            this.$childLinks.off('click', this.onChildLinkClickHandler);
            $body.off('click tap', this.onBodyClickHandler);
            $window.off('resize', this.onWindowResize);
            this.$document.off(EVENTS.KEY_UP, this.onKeyUpHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDesktop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns { this }
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Logic for clicking the trigger and toggling between open/closed states
         *
         * @method toggle
         * @chainable
         * @public
         * @returns { this }
         */
        toggle: function() {
            if (this.isOpen) {
                this.close();
                return this;
            }
            this.open();

            return this;
        },

        /**
         * Adds active class to the root element.
         *
         * @method open
         * @chainable
         * @private
         * @returns { this }
         */
        open: function() {
            if (!this.$currentTarget) {
                return this;
            }

            var menuName = this.$currentTarget.attr(DATA.MENU_TYPE);
            this.isOpen = true;
            this.$statusChild = this.$currentTarget.find(SELECTORS.STATUS_CONTAINER);
            this.$currentTarget.addClass(CLASSES.IS_OPEN);
            this.$header.addClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);
            $html.addClass(CLASSES.ADD_CURSOR_POINTER);
            this.$statusChild.empty().text(menuName + MESSAGING.OPEN_STATUS);
            if (!this.isMobile) {
                this.eventBus.trigger(EVENTS.SECONDARY_OPEN);
            }

            return this;
        },

        /**
         * Removes active class from the root element
         *
         * @method close
         * @chainable
         * @private
         * @returns { this }
         * @param { isLocal } isLocal check for undefined to removeClasses from page and header if not same parent
         */
        close: function(isLocal) {
            if (!this.$currentTarget) {
                return this;
            }

            var menuName = this.$currentTarget.attr(DATA.MENU_TYPE);
            this.isOpen = false;
            this.$statusChild = this.$currentTarget.find(SELECTORS.STATUS_CONTAINER);

            if (typeof isLocal === 'undefined' || this.isMobile) {
                isLocal = true;
            }

            this.$element.removeClass(CLASSES.IS_OPEN);

            if (isLocal) {
                this.$header.removeClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);
                $html.removeClass(CLASSES.ADD_CURSOR_POINTER);
                this.$statusChild.empty().text(menuName + MESSAGING.CLOSED_STATUS);
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        /// EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a secondary trigger.
         * Controls logic if on mobile and clicking mini cart button
         * which re assigns the user to the checkout cart page, but
         * still allows dropdown toggle on desktop and user sign in
         * panel toggle inside hamburger.
         *
         * @method onSecondaryTriggerClick
         * @param {jQuery} e The jQuery event object
         * @private
         * @returns { this }
         */
        onSecondaryTriggerClick: function(e) {
            this.$currentTarget = $(e.currentTarget).parent();
            this.$currentDropdown = this.$currentTarget.find(this.$child);
            var shouldCartRedirect = this.isMobile && this.$currentTarget.hasClass(CLASSES.MINICART);

            if (shouldCartRedirect) {
                window.location.assign(this.$mobileUrl);
                return this;
            }

            this.close(false);
            this.toggle();

            return this;
        },

        /**
         * Handler for all nested children in dropdowns. Stops event bubbling for body click event.
         *
         * @method onChildLinkClick
         * @param {jQuery} e The jQuery event object
         * @private
         * @return { this }
         */
        onChildLinkClick: function(e) {
            e.stopPropagation();
            return this;
        },

        /**
         * Check for body click or tap outside of open list panel, and trigger
         * hideItems event
         *
         * @method onBodyClick
         * @param {jQuery} e The jQuery event object
         * @public
         */

        onBodyClick: function(e) {
            if (this.isMobile || !this.isOpen) {
                return;
            }

            if (this.isOpen && !$(e.target).closest(SELECTORS.SECONDARY_PARENT).length) {
                this.close();
            }
        },

        /**
         * Handles keyup events
         *
         * @method onKeyUp
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyUp: function(event) {
            var wasEscapePressed = event.key === KEYS.ESCAPE;

            if (wasEscapePressed && this.isOpen) {
                this.close();
            }
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @returns { this }
         */
        onResize: function() {
            var debounceResize = this.debounce(this.setBreakpoint, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @returns {Function}
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderSecondaryToggleView;
}());
