var NRD = window.NRD || {};

NRD['./views/FilterToggleView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the classes used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_HIDDEN: 'filterPanel-bd_mobilePanelStacked',
        IS_OPEN: 'isOpen',
        PANEL_IS_ACTIVE: 'mobilePanel_slideLeft',
    };

    /**
     * An object of the event types used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var EVENTS = {
        RESIZE: 'resize',
        CLICK: 'click',
        CLOSE_FILTERS: 'CLOSE_FILTERS',
        APPLY_FILTERS: 'APPLY_FILTERS',
    };

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        FILTER_HEAD: '.js-filterToggle-hd',
        FILTER_BODY: '.js-filterToggle-bd',
        FILTER_BACK_BUTTON: '.js-filterToggle-backButton',
        FILTERED_ITEMS: '.js-filterToggle-chosenFilters',
        FILTER_LIST: '.js-filterToggle-filters',
        PARENT_PANEL: '.js-filterPanel-body',
        MAIN_PANEL_HEAD_LABEL: '.filterPanel-hd-label',
        FILTER_LABELS: 'label',
        FILTER_INPUT: ':checkbox',
        MOBILE_PANEL: '.mobilePanel',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property BREAKPOINT_VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the data attributes accessed in this view
     *
     * @property DATA
     * @type {Object}
     * @final
     */
    var DATA = {
        OPEN_DEFAULT: 'openOnDesktop',
    };

    /**
     * An object of the constants used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var CONST = {
        DEBOUNCE_TIME: 300,
        DELAY_CLASS_REMOVAL_TIME: 500,
    };

    /**
     * Hooks click events to show and hide fixed positioning panel of filter and sort for mobile view
     * @class FilterToggleView
     */
    var FilterToggleView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param {function} eventBus
         * @param {function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {bool}
             * @public
             */
            this.isDesktop = false;

            /**
             * A global reference to the mobile and tablet breakpoint
             *
             * @default null
             * @property isMobile
             * @type {bool}
             * @public
             */
            this.isMobile = false;

            /**
             * Tracks whether the custom options panel is displayed
             *
             * @default false
             * @property isOpen
             * @type {bool}
             * @public
             */
            this.isOpen = false;

            /**
             * A reference to the form element container
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         * We are creating a secondary array of temporary filters to
         * track when filters are clicked, but the "apply" button is
         * not clicked in the mobile view. On click, we add the filter
         * title to the parent filter panel to visually show what the
         * user could potentially filter by, but if the user clicks a
         * filter in mobile, and doesn't apply it, we need to remove
         * all "temporary" filters that were chosen so as not to
         * visually represent filters that haven't been applied.
         *
         * @method createChildren
         * @public
         * @chainable
         * @returns { this }
         */
        createChildren: function() {
            this.base();

            this.$window = $(window);
            this.$html = $('html');
            this.$body = $('body');
            this.$filterHead = this.$element.find(SELECTORS.FILTER_HEAD);
            this.$filterBody = this.$element.find(SELECTORS.FILTER_BODY);
            this.$filterBackButton = this.$filterBody.find(SELECTORS.FILTER_BACK_BUTTON);
            this.$filteredItems = this.$filterHead.find(SELECTORS.FILTERED_ITEMS);
            this.$filterList = this.$element.find(SELECTORS.FILTER_LIST);
            this.$filterParent = this.$element.closest(SELECTORS.PARENT_PANEL);
            this.$filterParentPanelLabel = this.$element.parents(this.$element).find(SELECTORS.MAIN_PANEL_HEAD_LABEL);
            this.$filterLabels = this.$filterList.find(SELECTORS.FILTER_LABELS);
            this.$filterInputs = this.$filterList.find(SELECTORS.FILTER_INPUT);
            this.appliedFilterLabelsArray = [];
            this.checkboxObjectArray = [];
            this.temporaryFilteredLabelsArray = [];
            this.temporaryCheckboxObjectArray = [];
            this.$mobilePanel = this.$filterBody.find(SELECTORS.MOBILE_PANEL);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$window = null;
            this.$html = null;
            this.$body = null;
            this.$filterHead = null;
            this.$filterBody = null;
            this.$filterBackButton = null;
            this.$filteredItems = null;
            this.$filterList = null;
            this.$filterParent = null;
            this.$filterParentPanelLabel = null;
            this.$filterLabels = null;
            this.$filterInputs = null;
            this.appliedFilterLabelsArray = null;
            this.checkboxObjectArray = null;
            this.temporaryFilteredLabelsArray = null;
            this.temporaryCheckboxObjectArray = null;
            this.$mobilePanel = null;
        },

        /**
         * Set breakpoint with debounce on load so we know if we need to show or hide filters
         *
         * @method layout
         * @public
         * @chainable
         * @returns { this }
         */
        layout: function() {
            this.base();
            this.setBreakpoint();
            this.checkDefaultOpenState();
            if (this.isMobile) {
                this.findDefaultFilters();
            }

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         * @returns { this }
         */
        setupHandlers: function() {
            this.base();

            this.onWindowResize = this.onResize.bind(this);
            this.onFilterParentTriggerHandler = this.onFilterParentClick.bind(this);
            this.onFilterBackButtonClickHandler = this.onFilterBackButtonClick.bind(this);
            this.onEventCloseHandler = this.onEventClose.bind(this);
            this.onEventApplyHandler = this.onEventApply.bind(this);
            this.onFilterLabelHandler = this.onFilterClick.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns { this }
         */
        onEnable: function() {
            this.$window.on(EVENTS.RESIZE, this.onWindowResize);
            this.$filterHead.on(EVENTS.CLICK, this.onFilterParentTriggerHandler);
            this.$filterBackButton.on(EVENTS.CLICK, this.onFilterBackButtonClickHandler);
            this.eventBus.on(EVENTS.CLOSE_FILTERS, this.onEventCloseHandler);
            this.eventBus.on(EVENTS.APPLY_FILTERS, this.onEventApplyHandler);
            this.$filterInputs.on(EVENTS.CLICK, this.onFilterLabelHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns { this }
         */
        onDisable: function() {
            this.$window.off(EVENTS.RESIZE, this.onWindowResize);
            this.$filterHead.off(EVENTS.CLICK, this.onFilterParentTriggerHandler);
            this.$filterBackButton.off(EVENTS.CLICK, this.onFilterBackButtonClickHandler);
            this.eventBus.off(EVENTS.CLOSE_FILTERS, this.onEventCloseHandler);
            this.eventBus.off(EVENTS.APPLY_FILTERS, this.onEventApplyHandler);
            this.$filterInputs.off(EVENTS.CLICK, this.onFilterLabelHandler);

            return this;
        },

        /**
         * Runs on layout and loops through all input filter checkboxes
         * to find and set initial populated array in filter head
         * of any checked filters if page is populated with filters
         * from query string in URL
         *
         * @method findDefaultFilters
         * @chainable
         * @public
         */
        findDefaultFilters: function() {
            this.$filterInputs.each(function(index, $filterInput) {
                this.setCheckedFilters($filterInput);
            }.bind(this));
        },

        /**
         * Runs on layout and loops through all input filter checkboxes
         * to find and set initial populated array in filter head
         * of any checked filters if page is populated with filters
         * from query string in URL
         *
         * @method setCheckedFilters
         * @chainable
         * @public
         * @param {$filterInput} $filterInput location of specific checkbox in DOM from loop
         * @returns { this }
         */
        setCheckedFilters: function($filterInput) {
            var $filter = $($filterInput);
            var isChecked = $filter.prop('checked');
            var filterText = $filter.siblings().text();

            if (isChecked) {
                this.checkboxObjectArray.push($filter);
                this.appliedFilterLabelsArray.push(filterText);
            }
            return this;
        },

        /**
         * Check for truth/false on filter.
         * If true display filters on desktop
         *
         * @method checkDefaultOpenState
         * @chainable
         * @public
         * @returns { this }
         */
        checkDefaultOpenState: function() {
            if (!this.isDesktop) {
                return this;
            }

            if (this.$element.data(DATA.OPEN_DEFAULT)) {
                this.$filterHead.attr('aria-expanded', 'true');
                this.showFilters();
            }

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns { this }
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         * @returns { this }
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            if (this.isMobile) {
                this.hideFilters();
            }

            if (this.isOpen && this.isDesktop) {
                this.hideFilters();
            }

            if (this.$element.data(DATA.OPEN_DEFAULT) && this.isDesktop) {
                this.showFilters();
            }

            if (this.isMobile && $.isEmptyObject(this.appliedFilterLabelsArray)) {
                this.findDefaultFilters();
            }

            return this;
        },

        /**
         * Checks if this is mobile viewport and focuses on first input when filters are shown
         *
         * @method mobileCheckFocus
         * @chainable
         * @public
         * @returns { this }
         */
        mobileCheckFocus: function() {
            if (this.isMobile) {
                this.$filterInputs.first().focus();
            }

            return this;
        },

        /**
         * Adds appropriate classes to elements to display filter panel
         *
         * @method showFilters
         * @chainable
         * @public
         * @returns { this }
         */
        showFilters: function() {
            this.$element.addClass(CLASSES.IS_OPEN);
            this.$filterParent.addClass(CLASSES.IS_HIDDEN);
            this.$mobilePanel.addClass(CLASSES.PANEL_IS_ACTIVE);
            this.$filterHead.attr('aria-expanded', true);
            this.$filterParentPanelLabel.attr('aria-hidden', true);
            this.mobileCheckFocus();
            this.isOpen = true;

            return this;
        },

        /**
         * Remove appropriate classes to elements to hide filter panel
         *
         * @method hideFilters
         * @chainable
         * @public
         * @returns { this }
         */
        hideFilters: function() {
            this.$element.removeClass(CLASSES.IS_OPEN);
            this.$filterParent.removeClass(CLASSES.IS_HIDDEN);
            this.$filterList.scrollTop(0);
            this.$filterHead.attr('aria-expanded', false);
            this.$filterParentPanelLabel.attr('aria-hidden', false);
            setTimeout(function() {
                this.$mobilePanel.removeClass(CLASSES.PANEL_IS_ACTIVE);
            }.bind(this), CONST.DELAY_CLASS_REMOVAL_TIME);
            this.isOpen = false;

            return this;
        },

        /**
         * Loop through all checkboxes and remove checked property
         *
         * @method uncheckAllFilters
         * @chainable
         * @public
         * @returns { this }
         */
        uncheckAllFilters: function() {
            this.$filterInputs.each(function(index) {
                this.checked = false;
            });

            return this;
        },

        /**
         * WE HOLD THESE TRUTHS TO BE SELF EVIDENT
         * 1. checkboxes that appear in the applied array SHOULD be checked
         * 2. any temporary changes in the temporaryCheckboxArray should be discarded
         * Loop through checkboxObjectArray set in default
         * Empties all checkbox properies and only applies
         * to checkboxes that were set on load or apply.
         * check the checkbox on matching input to keep user selections in tact on closing panel
         * after emptying temporary console, we repopulate and merge with default so
         * if user reopens filters, arrays will match with previously chosen.
         * @method keepAppliedFilters
         * @chainable
         * @public
         * @returns { this }
         */
        keepAppliedFilters: function() {
            this.uncheckAllFilters();

            this.checkboxObjectArray.forEach(function(filter) {
                filter.prop('checked', true);
            });

            this.emptyTemporaryArrays();
            $.merge(this.temporaryFilteredLabelsArray, this.appliedFilterLabelsArray);

            return this;
        },

        /**
         * Empty both temporary label and checkbox arrays
         *
         * @method emptyTemporaryArrays
         * @chainable
         * @public
         * @returns { this }
         */
        emptyTemporaryArrays: function() {
            this.temporaryFilteredLabelsArray.length = 0;
            this.temporaryCheckboxObjectArray.length = 0;

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Check isOpen for true or false on currently open filter panel
         * to trigger add or remove class on filter panel
         *
         * @method onFilterParentClick
         * @public
         * @param {event} event
         * @returns { this }
         */
        onFilterParentClick: function(event) {
            event.preventDefault();

            if (!this.isOpen) {
                this.showFilters();
                return this;
            }
            this.hideFilters();
            this.$filterHead.focus();

            return this;
        },

        /**
         * Used to close the open filter panel
         *
         * @method onFilterBackButtonClick
         * @public
         * @returns { this }
         */
        onFilterBackButtonClick: function() {
            this.$mobilePanel.removeClass(CLASSES.PANEL_IS_ACTIVE);
            this.hideFilters();
            this.$filterHead.focus();

            return this;
        },

        /**
         * Triggered to hide open filter if panel close button is clicked
         * Empties out temporary array of chosen but un-applied filters
         * Resets filter labels and collapses filter panel
         *
         * @method onEventClose
         * @public
         * @returns { this }
         */
        onEventClose: function() {
            this.hideFilters();
            this.keepAppliedFilters();

            return this;
        },

        /**
         * Can be used to apply any logic upon successful filter
         * might not be needed as apply button click causes postback
         * on mobile, AJAX on desktop, but could be used in future
         * for breakpoint changes - ie going from mobile to desktop,
         * apply filters
         *
         * @method onEventApply
         * @public
         * @returns { this }
         */
        onEventApply: function() {
            if (this.isMobile) {
                this.hideFilters();
            }
            this.appliedFilterLabelsArray.length = 0;
            this.findDefaultFilters();

            return this;
        },

        /**
         * When clicking a unique filter type, get label text,
         * check for item in array, and either append or remove
         * to populate in filter head parent
         *
         * @method onFilterClick
         * @public
         * @param {event} event current filter clicked
         * @returns { this }
         */
        onFilterClick: function(event) {
            var $currentCheckbox = $(event.currentTarget);
            var labelText = $currentCheckbox.siblings().text();
            var isChecked = $currentCheckbox.prop('checked');
            var tempFilteredItem = this.temporaryFilteredLabelsArray.findIndex(function(filter) {
                return filter === labelText;
            });

            if (isChecked) {
                this.temporaryCheckboxObjectArray.push($currentCheckbox);
                this.temporaryFilteredLabelsArray.push(labelText);
            } else {
                this.temporaryCheckboxObjectArray.splice($currentCheckbox, 1);
                this.temporaryFilteredLabelsArray.splice(tempFilteredItem, 1);
            }

            return this;
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @returns { this }
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, CONST.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @returns {function}
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return FilterToggleView;
}());
