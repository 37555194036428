var NRD = window.NRD || {};

NRD['./views/CheckedCTA'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */

    var SELECTORS = {
        CTA_TRIGGER: '.js-checkedCTA-trigger',
        CTA_TARGET: '.js-checkedCTA-target',
    };

    /**
     * @class checkedCTA
     */
    var checkedCTA = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             *
             * @default false
             * @type {bool}
             * @public
             */
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         */
        createChildren: function() {
            this.base();
            this.$trigger = this.$element.find(SELECTORS.CTA_TRIGGER);
            this.$target = this.$element.find(SELECTORS.CTA_TARGET);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$trigger = null;
            this.$target = null;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();

            this.onCTAClickHandler = this.onCTAClick.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$trigger.on('click', this.onCTAClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$trigger.off('click', this.onCTAClickHandler);
            return this;
        },

        checkTarget: function() {
            if (this.$target.prop('checked')) {
                return this;
            }

            this.$target.prop('checked', true).trigger('change');

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * Toggle password input type to show password and toggle show hide text
         *
         * @method onCTAClick
         * @param {jQuery} e The jQuery event object
         * @public
         */
        onCTAClick: function(e) {
            this.checkTarget();

            return this;
        },

    });

    return checkedCTA;
}());
