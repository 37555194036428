var NRD = window.NRD || {};

NRD['./views/SummaryListToggle'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        TRIGGER: '.js-listToggle-trigger',
        PANEL: '.js-listToggle-panel',
        LINE_CONTAINER: '.lineItem',
        SUMMARY_CONTAINER: '.summaryBlock',
        STATUS_CONTAINER: '.js-itemDetails-status',
    };

    var CLASSES = {
        IS_ACTIVE: 'isActive',
        PANEL_OPEN: 'lineItem_isOpen',
        SUMMARY_OPEN: 'summaryBlock_isOpen',
        DISABLE_FLYOUT: 'disableCartFlyout',
    };

    var VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the strings used to convey messaging for users with screen readers
     *
     * @property MESSAGING
     * @type {Object}
     * @final
     */
    var MESSAGING = {
        OPEN_STATUS: 'Displaying cart item details',
        CLOSED_STATUS: 'Cart item details has been hidden',
    };

    /**
     * An object of constant values used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var CONST = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * Finds password input and creates and injects toggle button to show or hide password input masking
     * @class summaryListToggle
     */
    var SummaryListToggle = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * Tracks whether the list item is expanded
             *
             * @default false
             * @property isOpen
             * @type {bool}
             * @public
             */
            this.isOpen = false;

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {jQuery}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         */
        createChildren: function() {
            this.base();

            this.$body = $('body');
            this.$window = $(window);
            this.$trigger = this.$element.find(SELECTORS.TRIGGER);
            this.$panel = this.$element.find(SELECTORS.PANEL);
            this.$listItem = this.$element.closest(SELECTORS.LINE_CONTAINER);
            this.$sidebar = this.$element.closest(SELECTORS.SUMMARY_CONTAINER);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();
            this.$statusContainer = this.$element.parent().find(SELECTORS.STATUS_CONTAINER);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$body = null;
            this.$window = null;
            this.$trigger = null;
            this.$panel = null;
            this.$listItem = null;
            this.$sidebar = null;
            this.breakpoint = null;
            this.$statusContainer = null;
        },

        layout: function() {
            this.base();

            this.setBreakpoint();

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();

            this.onButtonClickHandler = this.onButtonClick.bind(this);
            this.onBodyClickHandler = this.onBodyClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$trigger.on('click', this.onButtonClickHandler);
            this.$body.on('click', this.onBodyClickHandler);
            this.$window.on('resize', this.onWindowResize);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$trigger.off('click', this.onButtonClickHandler);
            this.$body.off('click', this.onBodyClickHandler);
            this.$window.off('resize', this.onWindowResize);

            return this;
        },

        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === VALUES.SMALL) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }
        },

        expandItems: function() {
            this.$statusContainer.empty().text(MESSAGING.OPEN_STATUS);
            this.$body.addClass(CLASSES.DISABLE_FLYOUT);
            this.$element.addClass(CLASSES.IS_ACTIVE);
            this.$listItem.addClass(CLASSES.PANEL_OPEN);
            this.$sidebar.addClass(CLASSES.SUMMARY_OPEN);
            this.isOpen = true;
            return this;
        },

        hideItems: function() {
            this.$statusContainer.empty().text(MESSAGING.CLOSED_STATUS);
            this.$body.removeClass(CLASSES.DISABLE_FLYOUT);
            this.$element.removeClass(CLASSES.IS_ACTIVE);
            this.$listItem.removeClass(CLASSES.PANEL_OPEN);
            this.$sidebar.removeClass(CLASSES.SUMMARY_OPEN);
            this.isOpen = false;
            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * Check isOpen for true or false to trigger adding or removing classes from order
         * summary block to show and hide cart item container
         *
         * @method onButtonClick
         * @param {jQuery} e The jQuery event object
         * @public
         */
        onButtonClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            if (!this.isOpen) {
                this.expandItems();
                return this;
            }
            this.hideItems();
            return this;
        },

        /**
         * Check for body click or tap outside of open list panel, and trigger
         * hideItems event
         *
         * @method onBodyClick
         * @param {jQuery} e The jQuery event object
         * @public
         */

        onBodyClick: function(e) {
            if (this.isMobile) {
                return this;
            } else {
                if (!this.isOpen && this.$panel.has(e.target).length === 1) {
                    this.expandItems();
                    return this;
                }

                if (this.isOpen && this.$panel.has(e.target).length === 0) {
                    this.hideItems();
                    return this;
                }
            }
        },

        /**
         * When window resizes measure the progress bar width
         * progress bar is responsive and is always 100 percent
         * of its container and then animate inline width based
         * on value.
         *
         * @method onResize
         * @public
         */
        onResize: function() {
            var debounceResize = this.debounce(this.setBreakpoint, CONST.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

         /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return SummaryListToggle;
}());
