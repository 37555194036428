var NRD = window.NRD || {};

NRD['./views/CarouselView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        VIEWPORT: '.js-carousel-viewport',
        CELL_LIST: '.js-carousel-viewport-cellList',
        PIP_LIST: '.js-carousel-pipList',
        PREV_BTN: '.js-carousel-prevBtn',
        NEXT_BTN: '.js-carousel-nextBtn',
        EARLY_MSG: '.js-carousel-earlyMsg',
        AUTOPLAY_CONTROLS: '.js-carousel-autoplayControls',
        AUTOPLAY_START: '.js-carousel-autoplayControls-start',
        AUTOPLAY_PAUSE: '.js-carousel-autoplayControls-pause',
        FOOTER: '.js-carousel-footer',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_ACTIVE: 'isActive',
        IS_DISABLED: 'isDisabled',
        IS_SELECTED: 'isSelected',
        IS_MUTED: 'isMuted',
        SHOW_ALERT: 'isAlerted',
        IMPORTANT_HIDDEN: 'isHiddenImportant',
        NO_SLIDES: 'carousel_noSlides',
    };

    /**
     * An object of events used in this view
     * @default null
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CAUSE_LAYOUT: 'CAUSE_LAYOUT',
    };

    /**
     * An object of durations to be used for animations
     *
     * @property DURATIONS
     * @type {Object}
     * @final
     */
    var DURATIONS = {
        CHANGE_SLIDE: 300,
        AUTOPLAY_INTERVAL: 5000,
    };

    /**
     * An object of percentage values used in this view
     *
     * @property PERCENTAGES
     * @type {Object}
     * @final
     */
    var PERCENTAGES = {
        SWIPE_THRESHOLD: 0.3,
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATRS
     * @type {Object}
     * @final
     */
    var ATTRS = {
        HAS_STATIC_PIPS: 'data-carousel-has-static-pips',
        HAS_AUTOPLAY: 'data-carousel-has-autoplay',
    };

    /**
     * Handles showing and hiding items in a horizontal list
     *
     * @class CarouselView
     * @extends {BaseView}
     */
    var CarouselView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param  {EventBus} eventBus Global instance of the Event Bus
         * @param  {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @returns {this}
         * @public
         */
        init: function() {
            this.base();

            this.activeIndex = 0;
            this.isFinalSlide = false;
            this.isInit = true;
            this.hasStaticPips = (this.$element.attr(ATTRS.HAS_STATIC_PIPS) === 'true');
            this.hasAutoplay = (this.$element.attr(ATTRS.HAS_AUTOPLAY) === 'true');

            // Binding is normally done in onEnable, however we need this event bound even if execution never makes it to onEnable
            this.eventBus.on(EVENTS.CAUSE_LAYOUT, this.onCauseLayoutHandler);

            return this
                .removePips()
                .layout()
                .renderPips()
                .setPipActiveStates()
                .setButtonEnabledStates()
                .setButtonMuteStates()
                .setAutoplay();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @returns {this}
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onPipClickHandler = this.onPipClick.bind(this);
            this.onPrevBtnClickHandler = this.onPrevBtnClick.bind(this);
            this.onNextBtnClickHandler = this.onNextBtnClick.bind(this);
            this.onAutoplayControlsClickHandler = this.onAutoplayControlsClick.bind(this);
            this.onViewportTouchStartHandler = this.onViewportTouchStart.bind(this);
            this.onViewportTouchMoveHandler = this.onViewportTouchMove.bind(this);
            this.onViewportTouchEndHandler = this.onViewportTouchEnd.bind(this);
            this.onSlideCompleteHandler = this.onSlideComplete.bind(this);
            this.onCauseLayoutHandler = this.onCauseLayout.bind(this);
            this.onWindowFocusHandler = this.onWindowFocus.bind(this);
            this.onWindowBlurHandler = this.onWindowBlur.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @returns {this}
         * @public
         */
        createChildren: function() {
            this.base();

            this.$window = $(window);
            this.$viewport = this.$element.find(SELECTORS.VIEWPORT);
            this.$cellList = this.$element.find(SELECTORS.CELL_LIST);
            this.$cells = this.$cellList.children();
            this.$pipList = this.$element.find(SELECTORS.PIP_LIST);
            this.$prevBtn = this.$element.find(SELECTORS.PREV_BTN);
            this.$nextBtn = this.$element.find(SELECTORS.NEXT_BTN);
            this.$autoplayControls = this.$element.find(SELECTORS.AUTOPLAY_CONTROLS);
            this.$autoplayStartBtn = this.$element.find(SELECTORS.AUTOPLAY_START);
            this.$autoplayPauseBtn = this.$element.find(SELECTORS.AUTOPLAY_PAUSE);
            this.$carouselFooter = this.$element.find(SELECTORS.FOOTER);
            this.$earlyMsg = this.$element.find(SELECTORS.EARLY_MSG);

            this.hasPrevAndNextBtns = ((this.$prevBtn.length > 0) && (this.$nextBtn.length > 0));
            this.hasPipList = (this.$pipList.length > 0);

            this.$selectedDate = this.$cells.filter('.' + CLASSES.IS_SELECTED);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @returns {this}
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$window = null;
            this.$viewport = null;
            this.$cellList = null;
            this.$cells = null;
            this.$pipList = null;
            this.$pips = null;
            this.$prevBtn = null;
            this.$nextBtn = null;
            this.$autoplayControls = null;
            this.$autoplayStartBtn = null;
            this.$autoplayPauseBtn = null;
            this.$earlyMsg = null;

            return this;
        },

        /**
         * checks for whether or not the view should be disabled
         *
         * @method testDisabled
         * @public
         * @returns {this}
         */
        testDisabled: function() {
            this.layout();

            return (this.base() || (this.slideCount < 2));
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @returns {this}
         * @public
         */
        onEnable: function() {
            this.$viewport.on('touchstart', this.onViewportTouchStartHandler);
            this.$viewport.on('touchmove', this.onViewportTouchMoveHandler);
            this.$viewport.on('touchend', this.onViewportTouchEndHandler);

            if (this.hasPipList) {
                this.$pipList.on('click', '> *', this.onPipClickHandler);
            }

            if (!this.hasPrevAndNextBtns) {
                return this;
            }

            this.$prevBtn.on('click', this.onPrevBtnClickHandler);
            this.$nextBtn.on('click', this.onNextBtnClickHandler);
            this.$autoplayControls.on('click', this.onAutoplayControlsClickHandler);
            this.$window.on('focus', this.onWindowFocusHandler);
            this.$window.on('blur', this.onWindowBlurHandler);

            return this.setButtonEnabledStates();
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @returns {this}
         * @public
         */
        onDisable: function() {
            this.$viewport.off('touchstart', this.onViewportTouchStartHandler);
            this.$viewport.off('touchmove', this.onViewportTouchMoveHandler);
            this.$viewport.off('touchend', this.onViewportTouchEndHandler);

            if (this.hasPipList) {
                this.$pipList.off('click', '> *', this.onPipClickHandler);
            }

            if (!this.hasPrevAndNextBtns) {
                return this.reset();
            }

            this.$prevBtn.off('click', this.onPrevBtnClickHandler);
            this.$nextBtn.off('click', this.onNextBtnClickHandler);
            this.$autoplayControls.off('click', this.onAutoplayControlsClickHandler);
            this.$window.off('focus', this.onWindowFocusHandler);
            this.$window.off('blur', this.onWindowBlurHandler);

            this.clearAutoplayInterval();

            return this.reset();
        },

        /**
         * Causes the carousel to re-layout on a 'CAUSE_LAYOUT' event.
         * We have a check for cell list even being present and returning
         * early, due to carousels being present in modals, and the modal
         * logic trigger CAUSE_LAYOUT. This should be refactored to
         * remove any event triggering for carousels from the modal view.
         *
         * @method onCauseLayout
         * @public
         * @returns {this}
         */
        onCauseLayout: function() {
            if (!this.$cellList) {
                return this;
            }

            // Update the list of cells
            this.$cells = this.$cellList.children();

            // Reset component
            this
                .removePips()
                .layout()
                .renderPips()
                .setPipActiveStates()
                .setButtonEnabledStates()
                .setButtonMuteStates()
                .slide(this.activeIndex, 0);

            return this;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @returns {this}
         */
        layout: function() {
            this.viewportWidth = (this.$viewport[0].getBoundingClientRect().width - parseInt(this.$cellList.css('margin-left'), 10));
            this.cellCount = this.$cells.length;
            this.cellWidth = (this.$cells.length) ? this.$cells[0].getBoundingClientRect().width : 0;
            this.visibleCellCount = (this.$cells.length) ? Math.floor(this.viewportWidth / this.cellWidth) : 0;
            this.slideCount = (this.$cells.length) ? Math.ceil(this.cellCount / this.visibleCellCount) : 0;
            this.previewWidth = this.viewportWidth - (this.cellWidth * this.visibleCellCount);

            if (this.$selectedDate.length && this.isInit) {
                var intentIndex = this.$selectedDate.index();
                this.activeIndex = this.getNextIndex(intentIndex);
            }

            if (this.hasPipList || this.hasAutoplay) {
                this.renderCarouselFooter();
            }

            return this;
        },

        /**
         * Changes the active slide and updates the position of the slide list
         *
         * @method slide
         * @param {number} intentIndex
         * @param {number} duration
         * @returns {this}
         * @public
         */
        slide: function(intentIndex, duration) {
            var nextIndex = this.getNextIndex(intentIndex);
            var nextLeftDistance = this.getSlideDistance(nextIndex);

            this.isSliding = true;
            this.activeIndex = nextIndex;

            this.$cellList.animate(
                {
                    left: nextLeftDistance,
                },
                {
                    duration: duration,
                    complete: this.onSlideCompleteHandler,
                }
            );

            return this
                .setPipActiveStates()
                .setButtonMuteStates();
        },

        /**
         * Calculates the pixel value for the carousel's next animation. Takes into account
         * whether the carousel should be previewing the next or previous slides or not.
         *
         * @param {number} nextIndex
         * @returns {(this|number)}
         */
        getSlideDistance: function(nextIndex) {
            if (this.slideCount <= 1) {
                return this;
            }

            var currentEndIndex = this.getCurrentEndIndex();
            var isLastSlide = currentEndIndex === nextIndex;
            var isFirstSlide = nextIndex === 0;
            var slideDistance = -1 * (nextIndex * this.cellWidth);

            if (isLastSlide) {
                return slideDistance + this.previewWidth;
            } else if (isFirstSlide) {
                return slideDistance;
            }

            return slideDistance + (this.previewWidth / 2);
        },

        /**
         * Calculates the next index to use based on the provided index. Uses the
         * calculated endindex to make sure animations always stay in view.
         *
         * @method getNextIndex
         * @param {number} intentIndex
         * @returns {number}
         * @public
         */
        getNextIndex: function(intentIndex) {
            var endIndex = this.getCurrentEndIndex();

            if (intentIndex < 0) {
                return 0;
            } else if (intentIndex >= endIndex) {
                return endIndex;
            }

            return intentIndex;
        },

        /**
         * Calculates the current end index, which is the visible cells subtracted from the
         * total cells. This is necessary to ensure the carousel doesn't overflow as the
         * offset for the items in view is always determined from the left.
         *
         * @method getCurrentEndIndex
         * @returns {number}
         * @public
         */
        getCurrentEndIndex: function() {
            return this.cellCount - this.visibleCellCount;
        },

        /**
         * Gets the 'slide' index of the active index. Slides are the total cells
         * divided by the number of visible cells. To get the active 'slide', divide
         * the active index by the visible cell count. This is needed to ensure the correct
         * pip is marked as active.
         *
         * @method getCurrentSlideIndex
         * @returns {number}
         * @public
         */
        getCurrentSlideIndex: function() {
            return Math.ceil(this.activeIndex / this.visibleCellCount);
        },

        /**
         * updates the carousel based on position or priority slide in relation to slide count and visible cell count
         *
         * @method update
         * @public
         * @returns {this}
         */
        update: function() {
            this.isInit = false;

            return this
                .removePips()
                .renderPips()
                .slide(this.activeIndex, 0);
        },

        /**
         * removes the disabled class from buttons when there is more than one slide
         *
         * @method setButtonEnabledStates
         * @public
         * @returns {this}
         */
        setButtonEnabledStates: function() {
            if (!this.hasPrevAndNextBtns) {
                return this;
            }

            if (this.slideCount !== 0) {
                this.$prevBtn.show();
                this.$nextBtn.show();
            }

            this.$prevBtn
                .add(this.$nextBtn)
                .removeClass(CLASSES.IS_DISABLED);

            return this;
        },

        /**
         * resets the carousel progress and sets default positioning
         *
         * @method setDisabledState
         * @public
         * @returns {this}
         */
        reset: function() {
            return this
                .setButtonDisabledStates()
                .removePips()
                .slide(0, 0);
        },

        /**
         * adds the disabled class to buttons when there is only one slide
         *
         * @method setButtonDisabledStates
         * @public
         * @returns {this}
         */
        setButtonDisabledStates: function() {
            if (!this.hasPrevAndNextBtns) {
                return this;
            }

            if (this.slideCount === 0) {
                this.$prevBtn.hide();
                this.$nextBtn.hide();
            }

            this.$prevBtn
                .add(this.$nextBtn)
                .addClass(CLASSES.IS_DISABLED);

            return this;
        },

        renderCarouselFooter: function() {
            var endIndex = this.getCurrentEndIndex();

            if (this.activeIndex === endIndex || endIndex < 0) {
                this.$carouselFooter.css('display', 'none');
                return this;
            }

            this.$carouselFooter.css('display', 'flex');

            return this;
        },

        removePips: function() {
            if (!this.hasPipList || this.hasStaticPips) {
                return this;
            }

            this.$pipList
                .css('display', 'none')
                .empty();

            this.$pips = null;

            return this;
        },

        renderPips: function() {
            var i = 0;

            if (!this.hasPipList || !this.isEnabled) {
                return this;
            }

            this.$pipList.css('display', '');

            if (this.hasStaticPips) {
                this.$pips = this.$pipList.children();

                return this;
            }

            for (var i = 0; i < this.slideCount; i++) {
                this.$pipList.append(
                    '<li>' +
                        '<button type="button">slide' +
                            (i + 1) +
                        '</button>' +
                    '</li>'
                );
            }

            this.$pips = this.$pipList.children();

            return this;
        },

        /**
         * adds and removes active class from pips depending on index/progress
         *
         * @method setPipActiveStates
         * @public
         * @returns {this}
         */
        setPipActiveStates: function() {
            if (!this.$pips) {
                return this;
            }

            var activeSlideIndex = this.getCurrentSlideIndex();

            this.$pips.removeClass(CLASSES.IS_ACTIVE);
            this.$pips.eq(activeSlideIndex).addClass(CLASSES.IS_ACTIVE);

            return this;
        },

        setAutoplay: function() {
            if (!this.hasAutoplay || this.hasStaticPips) {
                return this;
            }

            this.renderAutoplayControls();
            this.startAutoplay();
        },

        renderAutoplayControls: function() {
            this.$autoplayControls.css('display', 'block');
        },

        setAutoplayInterval: function() {
            var intentIndex = this.activeIndex + this.visibleCellCount;
            var endIndex = this.getCurrentEndIndex();

            if (intentIndex >= this.cellCount) {
                intentIndex = 0;
            }

            this.slide(intentIndex, DURATIONS.CHANGE_SLIDE);
        },

        /**
         * Before starting autoplay, check that the window is in focus. This is done to prevent
         * the carousel from playing if the user immediately shifted focus from the window and
         * to accommodate re-rendering needs required by IT as described below.
         *
         * Bugs can exist if this check is not in place due to how IT runs destroy & create
         * carousel after the DOM is rendered. In that action, IT restarts carousels that were
         * purposefully paused when focus was lost. By doing this check, the carousels pause
         * on instantiation to pause immediately to avoid this bug.
         *
         * State of the focus must be checked at runtime of this method and not saved as
         * a property of the object because the destroy IT does will destroy all variables scoped
         * to individual instantiations of the CarouselView object.
         *
         * If it is in focus, start the interval and update UI elements to indicate
         * the carousel's autoplay is active.
         *
         * @returns { this }
         */
        startAutoplay: function() {
            var doesDocumentHaveFocus = document.hasFocus();

            if (!doesDocumentHaveFocus) {
                return this;
            };

            this.isAutoplayPaused = false;
            this.$autoplayStartBtn.removeClass(CLASSES.IS_ACTIVE);
            this.$autoplayPauseBtn.addClass(CLASSES.IS_ACTIVE);

            this.autoplayInterval = setInterval(
                this.setAutoplayInterval.bind(this),
                DURATIONS.AUTOPLAY_INTERVAL
            );

            return this;
        },

        pauseAutoplay: function() {
            this.isAutoplayPaused = true;
            this.$autoplayPauseBtn.removeClass(CLASSES.IS_ACTIVE);
            this.$autoplayStartBtn.addClass(CLASSES.IS_ACTIVE);
            this.clearAutoplayInterval();
        },

        resetAutoplay: function() {
            this.clearAutoplayInterval();
            this.startAutoplay();
        },

        clearAutoplayInterval: function() {
            if (!this.autoplayInterval) {
                return;
            }

            clearInterval(this.autoplayInterval);
        },

        /**
         * adds and removes disabled class from buttons depending on index/progress
         *
         * @method setButtonMuteStates
         * @public
         * @returns {this}
         */
        setButtonMuteStates: function() {
            if (!this.hasPrevAndNextBtns) {
                return this;
            }

            var endIndex = this.getCurrentEndIndex();

            // add special class to carousel to target and change inline and width of frames and hide buttons for smart calendar
            if (this.slideCount === 1 && this.activeIndex === endIndex) {
                this.$prevBtn.addClass(CLASSES.IMPORTANT_HIDDEN);
                this.$nextBtn.addClass(CLASSES.IMPORTANT_HIDDEN);
                this.$element.addClass(CLASSES.NO_SLIDES);
                this.$prevBtn.addClass(CLASSES.IS_MUTED);
                this.$nextBtn.addClass(CLASSES.IS_MUTED);
                return this;
            }

            if (this.slideCount === 1) {
                this.$prevBtn.addClass(CLASSES.IS_MUTED);
                this.$nextBtn.addClass(CLASSES.IS_MUTED);

                return this;
            }

            if (this.activeIndex === 0) {
                this.$prevBtn.addClass(CLASSES.IS_MUTED);
                this.$nextBtn.removeClass(CLASSES.IS_MUTED);

                return this;
            }

            if (this.activeIndex === endIndex) {
                this.$prevBtn.removeClass(CLASSES.IS_MUTED);
                this.$nextBtn.addClass(CLASSES.IS_MUTED);

                return this;
            }

            this.$prevBtn.removeClass(CLASSES.IS_MUTED);
            this.$nextBtn.removeClass(CLASSES.IS_MUTED);

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * Handler for clicking a pip
         * @method onPipClick
         * @public
         * @param {Event} event from click
         */
        onPipClick: function(event) {
            var intentIndex = this.$pips.index($(event.currentTarget)) * this.visibleCellCount;

            event.preventDefault();

            if (this.hasAutoplay) {
                this.resetAutoplay();
            }

            this.slide(intentIndex, DURATIONS.CHANGE_SLIDE);
        },

        /**
         * Handler for clicking the previous button
         * @method onPrevBtnClick
         * @public
         * @param {Event} event from click
         */
        onPrevBtnClick: function(event) {
            var intentIndex = this.activeIndex - this.visibleCellCount;

            event.preventDefault();

            if (this.hasAutoplay) {
                this.resetAutoplay();
            }

            this.slide(intentIndex, DURATIONS.CHANGE_SLIDE);
        },

        onWindowFocus: function() {
            if (!this.hasAutoplay) {
                return;
            }

            this.resetAutoplay();
        },

        onWindowBlur: function() {
            if (!this.hasAutoplay) {
                return;
            }

            this.pauseAutoplay();
        },

        /**
         * Handler for clicking the next button
         * @method onNextBtnClick
         * @public
         * @param {Event} event from click
         */
        onNextBtnClick: function(event) {
            var intentIndex = this.activeIndex + this.visibleCellCount;

            event.preventDefault();

            if (this.hasAutoplay) {
                this.resetAutoplay();
            }

            this.slide(intentIndex, DURATIONS.CHANGE_SLIDE);
        },

        /**
         * Handler for clicking the autoplay button
         * @method onAutoplayControlsClick
         * @public
         * @param {Event} event from click
         */
        onAutoplayControlsClick: function(event) {
            event.preventDefault();

            this.isAutoplayPaused ? this.startAutoplay() : this.pauseAutoplay();
        },

        /**
         * Handler for after a slide animation is complete
         * @method onSlideChangeComplete
         * @public
         */
        onSlideComplete: function() {
            this.isSliding = false;
        },

        /**
         * Handler for the start of a touch event on the viewport
         * @method onViewportTouchStart
         * @public
         * @param {Event} event from click
         */
        onViewportTouchStart: function(event) {
            this.touchStartX = event.originalEvent.touches[0].screenX;
            this.touchStartY = event.originalEvent.touches[0].screenY;

            this.swipeDirection = null;
            this.isSwipeReady = false;
        },

        /**
         * Handler for a touch move on the viewport
         * @method onViewportTouchMove
         * @public
         * @param {Event} event from click
         */
        onViewportTouchMove: function(event) {
            var touchCurrentX = event.originalEvent.touches[0].screenX;
            var touchCurrentY = event.originalEvent.touches[0].screenY;
            var touchXDistance =  (this.touchStartX - touchCurrentX);
            var touchYDistance =  (this.touchStartY - touchCurrentY);
            var isHorizontalSwipe = (Math.abs(touchXDistance) > Math.abs(touchYDistance));
            var swipeXThreshold = (this.viewportWidth * PERCENTAGES.SWIPE_THRESHOLD);

            if (!isHorizontalSwipe) {
                return;
            }

            event.preventDefault();

            this.isSwipeReady = (Math.abs(touchXDistance) > swipeXThreshold);
            this.swipeDirection = (touchXDistance > 0) ? 'left' : 'right';
        },

        /**
         * Handler for completing a touch event on the viewport
         * @method onViewportTouchEnd
         * @public
         */
        onViewportTouchEnd: function() {
            var intentIndex = ((this.swipeDirection === 'left') ? (this.activeIndex + this.visibleCellCount) : (this.activeIndex - this.visibleCellCount));

            if (!this.isSwipeReady) {
                return;
            }

            this.slide(intentIndex, DURATIONS.CHANGE_SLIDE);
        },

        /**
         * Handler for global breakpoint change event
         * @method onBreakpointChange
         * @public
         */
        onBreakpointChange: function() {
            this.base();

            if (!this.isEnabled) {
                return;
            }

            this.update();
        },
    });

    return CarouselView;
}());
