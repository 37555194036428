var NRD = window.NRD || {};

NRD['./views/AccordionView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        VIEW: '.js-accordion',
        TRIGGER: '.js-accordion-trigger',
        PANEL: '.js-accordion-panel',
        CANCEL_BTN: '.js-accordion-cancel',
        TRIGGER_META: '.js-accordion-meta',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_ACTIVE: 'isActive',
        IS_DISABLED: 'isDisabled',
        IS_RESTRICTED: 'isRestricted',
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATTRS
     * @type {Object}
     * @final
     */
    var ATTRS = {
        BLOCK_DISABLED_CLASS: 'data-block-disabled-class',
        IS_PROGRESS_RESTRICTED: 'data-restrict-progress',
        TRIGGER_ID: 'data-accordion-trigger-id',
        PANEL_ID: 'data-accordion-panel-id',
        DISABLED: 'disabled',
        ARIA_HIDDEN: 'aria-hidden',
        ARIA_EXPANDED: 'aria-expanded',
        STYLE: 'style',
    };

    /**
     * An object of durations to be used for animations
     *
     * @property DURATIONS
     * @type {Object}
     * @final
     */
    var DURATIONS = {
        PANEL_SLIDE: 300,
    };

    var EVENTS = {
        CLOSE: 'CLOSE_ACCORDIONS',
    };

    /**
     * Handles opening and closing stacked drawers
     *
     * @class AccordionView
     * @extends {BaseView}
     */
    var AccordionView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param {EventBus} eventBus Global instance of the Event Bus
         * @param {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * Tracks whether the accordion will automatically scroll to the activate panel.
             *
             * @default false
             * @property autoScrollEnabled
             * @type {bool}
             * @public
             */
            this.autoScrollEnabled = false;

            /**
             * Tracks the number of pixels to offset a scroll action.
             * Used this when there is position:fixed content that must be accounted for when setting the scroll position.
             *
             * @default false
             * @property autoScrollOffset
             * @type {number}
             * @public
             */
            this.autoScrollOffset = 0;

            /**
             * Tracks whether the accordion requires logic to progress through the panels.
             *
             * @default false
             * @property isProgressRestricted
             * @type {bool}
             * @public
             */
            this.isProgressRestricted = null;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @returns {this}
         * @public
         */
        init: function() {
            this
                .getDisabledBreakpoints()
                .setupHandlers()
                .createChildren()
                .layout()
                .setRestricted()
                .enableBreakpointChange();

            this.$window.on(EVENTS.CLOSE, this.onTriggerCloseHandler);
            this.isAnimating = false;

            return this.toggleEnabledDisabled();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @returns {this}
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onTriggerClickHandler = this.onTriggerClick.bind(this);
            this.onCancelBtnClickHandler = this.onCancelBtnClick.bind(this);
            this.onAnimateCompleteHandler = this.onAnimateComplete.bind(this);
            this.onRemovePanelRestrictionHandler = this.onRemovePanelRestriction.bind(this);
            this.onUpdateTriggerMetaHandler = this.onUpdateTriggerMeta.bind(this);
            this.onTriggerCloseHandler = this.onTriggerClose.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @returns {this}
         * @public
         */
        createChildren: function() {
            this.$window = $(window);
            var $triggerExlusion = this.$element.find(SELECTORS.VIEW + ' ' + SELECTORS.TRIGGER);
            var $panelExclusion = this.$element.find(SELECTORS.VIEW + ' ' + SELECTORS.PANEL);
            var $cancelBtnExclusion = this.$element.find(SELECTORS.VIEW + ' ' + SELECTORS.CANCEL_BTN);

            this.base();

            this.$triggers = (
                this.$element
                    .find(SELECTORS.TRIGGER)
                    .not($triggerExlusion)
            );

            this.$panels = (
                this.$element
                    .find(SELECTORS.PANEL)
                    .not($panelExclusion)
            );

            this.$cancelBtns = (
                this.$element
                    .find(SELECTORS.CANCEL_BTN)
                    .not($cancelBtnExclusion)
            );

            this.initialId = this.getInitialId();
            this.activeId = null;
            this.prevId = null;

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @returns {this}
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$triggers = null;
            this.$panels = null;
            this.$cancelBtns = null;
            this.initialId = null;
            this.activeId = null;
            this.prevId = null;

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @returns {this}
         * @public
         */
        onEnable: function() {
            this.$triggers.on('click', this.onTriggerClickHandler);
            this.$cancelBtns.on('click', this.onCancelBtnClickHandler);
            this.eventBus.on('REMOVE_RESTRICTION', this.onRemovePanelRestrictionHandler);
            this.eventBus.on('UPDATE_TRIGGER_TITLE', this.onUpdateTriggerMetaHandler);

            this
                .setEnabledClasses()
                .resetIds()
                .stripInlineStyles();

            if (this.initialId === null) {
                return this;
            }

            this.activeId = this.initialId;

            return this.open(0);
        },

        /**
         * Sets classes to visually reflect enabled state
         *
         * @method setEnabledClasses
         * @returns {this}
         * @public
         */
        setEnabledClasses: function() {
            this.$triggers.removeClass(CLASSES.IS_DISABLED + ' ' + CLASSES.IS_ACTIVE);
            this.$panels.removeClass(CLASSES.IS_DISABLED + ' ' + CLASSES.IS_ACTIVE);
            this.$triggers.removeAttr(ATTRS.DISABLED);

            if (this.$triggers.attr(ATTRS.IS_PROGRESS_RESTRICTED)) {
                this.setDisabledClasses();
            }

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @returns {this}
         * @public
         */
        onDisable: function() {
            this.$triggers.off('click', this.onTriggerClickHandler);
            this.$cancelBtns.off('click', this.onCancelBtnClickHandler);
            this.eventBus.off('REMOVE_RESTRICTION', this.onRemovePanelRestrictionHandler);
            this.eventBus.off('UPDATE_TRIGGER_TITLE', this.onUpdateTriggerMetaHandler);

            return this
                .setDisabledClasses()
                .resetIds()
                .stripInlineStyles();
        },

        /**
         * Sets classes to visually reflect disabled state
         *
         * @method setDisableClasses
         * @returns {this}
         * @public
         */
        setDisabledClasses: function() {
            var toBlockDisabledClass = this.$element.attr(ATTRS.BLOCK_DISABLED_CLASS);

            this.$triggers.removeClass(CLASSES.IS_ACTIVE);
            this.$panels.removeClass(CLASSES.IS_ACTIVE);

            if (toBlockDisabledClass) {
                return this;
            }

            this.$triggers.addClass(CLASSES.IS_DISABLED);
            this.$triggers.attr(ATTRS.DISABLED, ATTRS.DISABLED);
            this.$panels.addClass(CLASSES.IS_DISABLED);

            return this;
        },

        /**
         * Sets the indexes back to null
         *
         * @method resetIds
         * @returns {this}
         * @public
         */
        resetIds: function() {
            this.activeId = null;
            this.prevId = null;

            return this;
        },

        /**
         * Removes all inline styles applied by animations
         *
         * @method stripInlineStyles
         * @returns {this}
         * @public
         */
        stripInlineStyles: function() {
            this.$triggers.removeAttr(ATTRS.STYLE);
            this.$panels.removeAttr(ATTRS.STYLE);

            return this;
        },

        /**
         * Close open accordions when filter panel is closed
         *
         * @method onTriggerClose
         * @public
         * @returns {this}
         */
        onTriggerClose: function() {
            if (this.activeId === null) {
                return;
            }

            this.prevId = this.activeId;

            this.toggle();

            return this;
        },

        /**
         * Logic for handling which item should be closed or opened
         *
         * @method toggle
         * @returns {this}
         * @public
         */
        toggle: function() {
            if (this.isAnimating) {
                return this;
            }

            this.isAnimating = true;

            if (this.prevId === this.activeId) {
                return this
                    .close(DURATIONS.PANEL_SLIDE)
                    .resetIds();
            }

            if ((this.prevId !== null) && (this.$panels.length > 1)) {
                this.close(DURATIONS.PANEL_SLIDE);
            }

            return this.open(DURATIONS.PANEL_SLIDE);
        },

        /**
         * Starts the open process
         *
         * @method open
         * @param {Number} duration of animation
         * @returns {this}
         * @private
         */
        open: function(duration) {
            var $trigger = this.$triggers.filter(this.onFilterById.bind(this, this.activeId, ATTRS.TRIGGER_ID));
            var $panel = this.$panels.filter(this.onFilterById.bind(this, this.activeId, ATTRS.PANEL_ID));

            $trigger.addClass(CLASSES.IS_ACTIVE);
            $trigger.attr(ATTRS.ARIA_EXPANDED, 'true');

            if (duration === 0) {
                $panel.addClass(CLASSES.IS_ACTIVE);

                return this;
            }

            $panel
                .css('display', 'none')
                .addClass(CLASSES.IS_ACTIVE)
                .attr(ATTRS.ARIA_HIDDEN, 'false')
                .slideDown({
                    duration: duration,
                    complete: this.onAnimateCompleteHandler,
                });

            if (this.autoScrollEnabled) {
                this.scrollToTrigger($trigger, duration);
            }

            return this;
        },

        /**
         * Starts the close process for the item of the previous index
         *
         * @method close
         * @param {Number} duration of animation
         * @returns {this}
         * @private
         */
        close: function(duration) {
            var $trigger = this.$triggers.filter(this.onFilterById.bind(this, this.prevId, ATTRS.TRIGGER_ID));
            var $panel = this.$panels.filter(this.onFilterById.bind(this, this.prevId, ATTRS.PANEL_ID));

            $trigger.removeClass(CLASSES.IS_ACTIVE);
            $trigger.attr(ATTRS.ARIA_EXPANDED, 'false');

            if (duration === 0) {
                this.onAnimateComplete();

                return this;
            }

            $panel
                .attr(ATTRS.ARIA_HIDDEN, 'true')
                .slideUp({
                    duration: duration,
                    complete: this.onAnimateCompleteHandler,
                });

            return this;
        },

        /**
         * Set up restricted accordion trigger functionality
         *
         * @method setRestricted
         * @public
         * @returns {this}
         */
        setRestricted: function() {
            var i;
            var $trigger;

            for (i = 0; i < this.$triggers.length; i++) {
                $trigger = this.$triggers.eq(i);

                if ($trigger.attr(ATTRS.IS_PROGRESS_RESTRICTED)) {
                    $trigger.attr(ATTRS.DISABLED, ATTRS.DISABLED);
                    $trigger.addClass(CLASSES.IS_RESTRICTED);
                }
            }

            return this;
        },

        /**
         * Lifts the restriction from a trigger based on event
         *
         * @method removeRestriction
         * @public
         * @param {jQuery} $trigger A reference to the accordion trigger
         * @returns {this}
         */
        removeRestriction: function($trigger) {
            $trigger
                .removeAttr(ATTRS.IS_PROGRESS_RESTRICTED)
                .removeClass(CLASSES.IS_RESTRICTED);

            return this;
        },

        /**
         * Scroll the viewport to keep the active panel in view.
         * Note that this method actually scrolls to the position of the trigger for the panel immediately
         * preceeding the currently active panel. This is done as occasionally that trigger can contain
         * information useful to the user.
         *
         * @method scrollToTrigger
         * @public
         * @param {jQuery} $trigger A reference to the accordion trigger
         * @param {Number} duration The number of seconds over which to animate the transition
         * @returns {this}
         */
        scrollToTrigger: function($trigger, duration) {
            var triggerIndex = this.$triggers.index($trigger);

            $([document.documentElement, document.body]).animate({
                scrollTop: this.$triggers.eq(triggerIndex - 1).offset().top - this.autoScrollOffset,
            }, duration);

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // FILTERS/UTILITY
        /// ///////////////////////////////////////////////////////////////////////////////
        getInitialId: function() {
            var $trigger;
            var initialId = null;
            var length = this.$triggers.length;
            var i = 0;

            for (; i < length; i++) {
                $trigger = this.$triggers.eq(i);

                if ($trigger.hasClass(CLASSES.IS_ACTIVE)) {
                    initialId = $trigger.attr(ATTRS.TRIGGER_ID);
                }
            }

            return initialId;
        },

        onFilterById: function(sourceId, idAttr, index, element) {
            var $element = $(element);
            var elementId = $element.attr(idAttr);
            var $trigger = this.$triggers.filter('[' + ATTRS.TRIGGER_ID + '="' + sourceId + '"]');

            if ($trigger.length && $trigger.hasClass(CLASSES.IS_RESTRICTED)) {
                return null;
            }

            return ((sourceId === elementId) ? $element : null);
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a trigger
         *
         * @method onTriggerClick
         * @private
         * @param {jQuery} event
         * @returns {this}
         */
        onTriggerClick: function(event) {
            var $trigger;
            var intentActiveId;
            var $panels;

            $trigger = $(event.currentTarget);

            if ($trigger.hasClass(CLASSES.IS_RESTRICTED)) {
                return this;
            }

            intentActiveId = $trigger.attr(ATTRS.TRIGGER_ID);
            $panels = this.$panels.filter(this.onFilterById.bind(this, intentActiveId, ATTRS.PANEL_ID));

            if ($trigger.is('a')) {
                event.preventDefault();
            }

            // In the case where a trigger/panel should be restricted or a panel ID is missing
            if (!$panels.length || $panels === null) {
                return;
            }

            this.prevId = this.activeId;
            this.activeId = intentActiveId;

            this.toggle();

            return this;
        },

        /**
         * Handler for clicking a cancel button
         *
         * @method onCancelBtnClick
         * @private
         */
        onCancelBtnClick: function() {
            if (this.activeId === null) {
                return;
            }

            this.prevId = this.activeId;

            this.toggle();
        },

        /**
         * Handler for animation complete callback
         *
         * @method onAnimateComplete
         * @private
         * @param {jQuery} panel object
         */
        onAnimateComplete: function(panel) {
            var $panel = $(panel);
            var id = $panel.attr(ATTRS.PANEL_ID);

            this.isAnimating = false;

            if (this.activeId === id) {
                return;
            }

            $panel
                .removeClass(CLASSES.IS_ACTIVE)
                .css('display', '');
        },

        /**
         * Handler for advancing to a restricted panel
         *
         * @method onRemovePanelRestriction
         * @param {String} event The event string
         * @param {String} restrictedId The panel ID to lift restrictions
         * @private
         */
        onRemovePanelRestriction: function(event, restrictedId) {
            var $trigger = $('[' + ATTRS.TRIGGER_ID + '="' + restrictedId + '"]');

            if ($trigger) {
                this.removeRestriction($trigger);
            }
        },

        /**
         * Handler for advancing to a restricted panel
         *
         * @method onUpdateTriggerMeta
         * @param {String} event The event string
         * @param {Object} params A plainObject of parameters
         * @param {String} params.id A reference to the ID string of the intended trigger
         * @param {String} params.meta The text to be populated
         * @private
         */
        onUpdateTriggerMeta: function(event, params) {
            var $triggers = this.$triggers.filter(this.onFilterById.bind(this, params.id, ATTRS.TRIGGER_ID));

            // Populate the meta container with text
            $triggers.find(SELECTORS.TRIGGER_META).text(params.meta);
        },
    });

    return AccordionView;
}());
