var NRD = window.NRD || {};

NRD['./views/BackToTopView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * The height, in pixels, at which the scroll to top button should be shown
     *
     * @default 200
     * @property SHOW_HEIGHT
     * @type {Number}
     * @final
     */
    var SHOW_HEIGHT = 200;

    /**
     * An object of the selectors used in this view
     * @default null
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        FOCUSABLE_ELEMENTS: 'button, a[href], input, select, textarea, [tabindex]',
        FOCUSABLE_ELEMENTS_EXCLUSIONS: '[tabindex="-1"], [type="hidden"], [disabled]',
    };

    /**
     * The name of the active class for the button
     *
     * @default 'isVisible'
     * @property ACTIVE_CLASS_NAME
     * @type {String}
     * @final
     */
    var ACTIVE_CLASS_NAME = 'isVisible';

    /**
     * The duration of the scroll animation in milleseconds
     *
     * @default 300
     * @property SCROLL_DURATION
     * @type {Number}
     * @final
     */
    var SCROLL_DURATION = 300;

    /**
     * Adds hide/show and animation functionality to the
     * @class BackToTopView
     */
    var BackToTopView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param  {EventBus} eventBus Global instance of the Event Bus
         * @param  {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * Flag which reflects the state of the back to top button's visilibility
             *
             * @default false
             * @property buttonIsVisible
             * @type {boolean}
             * @public
             */
            this.buttonIsVisible = false;

            // Call the base view constructor to init the view
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         * Finds first element in dom and attaches tabindex of zero
         * so that screen reader focus can be reset and triggered back to
         * the beginning of the DOM
         *
         * @method createChildren
         * @public
         * @chainable
         * @returns {this.base}
         */
        createChildren: function() {
            this.$window = $(window);
            this.$focusableElements = $(SELECTORS.FOCUSABLE_ELEMENTS).not(SELECTORS.FOCUSABLE_ELEMENTS_EXCLUSIONS);
            this.$firstElement = this.$focusableElements.first();

            return this.base();
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$window = null;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         * @returns {this.base}
         */
        setupHandlers: function() {
            this.onWindowScrollHandler = this.onWindowScroll.bind(this);
            this.onButtonClickHandler = this.onButtonClick.bind(this);

            return this.base();
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method enable
         * @public
         * @chainable
         * @returns {this.base}
         */
        enable: function() {
            this.$window.on('scroll', this.onWindowScrollHandler);
            this.$element.on('click', this.onButtonClickHandler);

            return this.base();
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method disable
         * @public
         * @chainable
         * @returns {this}
         */
        disable: function() {
            this.$window.off('scroll', this.onWindowScrollHandler);
            this.$element.off('click', this.onButtonClickHandler);

            this.base();

            return this;
        },

        /**
         * Determines if the scroll to top button should be shown or not and
         * calls the appropriate display function
         *
         * @method toggleButton
         * @public
         */
        toggleButton: function() {
            var currentScrollPos = this.$window[0].pageYOffset;

            // Don't make a call to show/hide unless absolutely necessary
            if (currentScrollPos >= SHOW_HEIGHT && !this.buttonIsVisible) {
                this.showButton();
            } else if (currentScrollPos < SHOW_HEIGHT && this.buttonIsVisible) {
                this.hideButton();
            }
        },

        /**
         * Add the 'isVisible' class to the back to top button and flip the
         * visibility flag to true
         *
         * @method showButton
         * @public
         */
        showButton: function() {
            this.$element.addClass(ACTIVE_CLASS_NAME);
            this.buttonIsVisible = true;
        },

        /**
         * Remove the 'isVisible' class to the back to top button and flip the
         * visibility flag to false
         *
         * @method hideButton
         * @public
         */
        hideButton: function() {
            this.$element.removeClass(ACTIVE_CLASS_NAME);
            this.buttonIsVisible = false;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Trigger the button toggling logic on scroll
         *
         * @method onWindowScroll
        * @param {jQuery} e The jQuery event object
         * @public
         */
        onWindowScroll: function(e) {
            this.toggleButton();
        },

        /**
         * Animate to the top of the page when the back to top button is clicked
         *
         * Animate html to top of document and use jquery done callback with promise
         * to wait for animation to complete before running the move focus to top
         * since you won't be able to blur or focus while jquery animates
         *
         * @method onButtonClick
         * @param {jQuery} e The jQuery event object
         * @public
         */
        onButtonClick: function(e) {
            e.preventDefault();

            $('html')
                .animate({ scrollTop: 0 }, { duration: 250 })
                .promise()
                .done(this.moveFocusToTop.bind(this));
        },

        /**
         * Move focus to first focusableElement once back to top animation completes to make
         * the experience the same for keyboard & screenreader users as it is for mouse users.
         */
        moveFocusToTop: function() {
            this.$element.blur();
            this.$firstElement.focus();
        },

    });

    return BackToTopView;
}());
