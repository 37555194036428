var NRD = window.NRD || {};

NRD['./views/SimpleToggleView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */

    var SELECTORS = {
        TRIGGER: '.js-simpleToggle-trigger',
        TOGGLE: '.js-simpleToggle-toggle',
    };

    var CLASSES = {
        IS_HIDDEN: 'isHidden',
    };

    var PROPERTIES = {
        SELECTED: 'checked',
    };

    /**
     * Finds password input and creates and injects toggle button to show or hide password input masking
     * @class simpleToggleView
     */
    var simpleToggleView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * Default set false
             *
             * @default false
             * @property isHidden
             * @type {bool}
             * @public
             */
            this.isHidden = false;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         */
        createChildren: function() {
            this.base();

            this.$trigger = this.$element.find(SELECTORS.TRIGGER);
            this.$toggle = this.$element.find(SELECTORS.TOGGLE);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$trigger = null;
            this.$toggle = null;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            this.base();

            this.onLoad();

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();

            this.onTriggerChangeHandler = this.onTriggerChange.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$trigger.on('change', this.onTriggerChangeHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$trigger.off('change', this.onTriggerChangeHandler);

            return this;
        },

        onLoad: function() {
            if (this.$trigger.prop(PROPERTIES.SELECTED)) {
                this.$toggle.addClass(CLASSES.IS_HIDDEN);

                return this;
            }
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////
        /**
         * Toggle password input type to show password and toggle show hide text
         *
         * @method onTriggerChange
         * @param {jQuery} e The jQuery event object
         * @public
         */
        onTriggerChange: function() {
            this.$toggle.toggleClass(CLASSES.IS_HIDDEN);
        },

    });

    return simpleToggleView;
}());
