var NRD = window.NRD || {};

NRD['./views/BaseView'] = (function() {
    'use strict';

    var Base = NRD['basejs']; // eslint-disable-line

    var ATTRS = {
        DISABLED_BREAKPOINTS: 'data-disabled-breakpoints',
    };

    /**
     * @class BaseView
     */
    var BaseView = Base.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to the containing DOM element.
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            this.eventBus = eventBus;

            this.breakpointListener = breakpointListener;

            /**
             * Tracks whether component is enabled.
             *
             * @default false
             * @property isEnabled
             * @type {bool}
             * @public
             */
            this.isEnabled = null;

            /**
             * Tracks whether component breakpoint change listener is enabled.
             *
             * @default false
             * @property isBreakpointChangeEnabled
             * @type {bool}
             * @public
             */
            this.isBreakpointChangeEnabled = false;

            this.init();
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @public
         * @returns {this}
         */
        init: function() {
            this
                .getDisabledBreakpoints()
                .setupHandlers()
                .createChildren()
                .layout()
                .enableBreakpointChange()
                .toggleEnabledDisabled();

            return this;
        },

        getDisabledBreakpoints: function() {
            this.disabledBreakpoints = (this.$element.attr(ATTRS.DISABLED_BREAKPOINTS) || '');

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.onBreakpointChangeHandler = this.onBreakpointChange.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @returns {this}
         */
        createChildren: function() {
            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @returns {this}
         */
        removeChildren: function() {},

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @returns {this}
         */
        layout: function() {
            return this;
        },

        /**
         * Tests breakpoint listener's current breakpoint against view's disabled breakpoints
         *
         * @method testDisabled
         * @public
         * @return {Boolean}
         */
        testDisabled: function() {
            var breakpointIndex = (
                this.disabledBreakpoints.indexOf(this.breakpointListener.currentBreakpoint)
            );

            return ((breakpointIndex >= 0));
        },

        /**
         * If testDiabled returns true, call disabled method. If false, call enable method
         *
         * @method toggleEnabledDisabled
         * @public
         * @returns {this}
         */
        toggleEnabledDisabled: function() {
            var toDisable = this.testDisabled();

            if (toDisable) {
                return this.disable();
            }

            return this.enable();
        },

        /**
         * Enables the component breakpoint change listener.
         * Exits early if it is already enabled.
         *
         * @method enableBreakpointChange
         * @public
         * @returns {this}
         */
        enableBreakpointChange: function() {
            if (this.isBreakpointChangeEnabled) {
                return this;
            }

            this.isBreakpointChangeEnabled = true;

            this.eventBus.on('BREAKPOINT.CHANGE', this.onBreakpointChangeHandler);

            return this;
        },

        /**
         * Disables the component breakpoint change listener.
         * Exits early if it is already disabled.
         *
         * @method disableBreakpointChange
         * @public
         * @returns {this}
         */
        disableBreakpointChange: function() {
            if (!this.isBreakpointChangeEnabled) {
                return this;
            }

            this.isBreakpointChangeEnabled = false;

            this.eventBus.off('BREAKPOINT.CHANGE', this.onBreakpointChangeHandler);

            return this;
        },

        onBreakpointChange: function(e) {
            this.toggleEnabledDisabled();
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method enable
         * @public
         * @returns {this}
         */
        enable: function() {
            if (this.isEnabled && (this.isEnabled !== null)) {
                return this;
            }

            this.isEnabled = true;

            return this.onEnable();
        },

        /**
         * Callback for inherited views to enable remove view specific handlers
         *
         * @method onEnable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method disable
         * @public
         * @returns {this}
         */
        disable: function() {
            if (!this.isEnabled && (this.isEnabled !== null)) {
                return this;
            }

            this.isEnabled = false;

            return this.onDisable();
        },

        /**
         * Callback for inherited views to disable remove view specific handlers
         *
         * @method onDisable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            return this;
        },

        /**
         * Destroys the component.
         * Tears down any events, handlers, elements.
         * Should be called when the object should be left unused.
         *
         * @method destroy
         * @public
         * @returns {this}
         */
        destroy: function() {
            return this
                .disable()
                .disableBreakpointChange()
                .removeChildren();
        },
    });

    return BaseView;
}());
