var NRD = window.NRD || {};

NRD['./views/Header/HeaderFlyoutView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        DROPDOWN: '.js-mainDropdown',
        CLOSE: '.js-closeMobileMenu',
        DROPDOWN_PANEL_FLYOUT_TRIGGER: '.headerDropdown-section-panel-expand',
        DROPDOWN_PANEL_ITEM: '.headerDropdown-section-panel-label',
        DROPDOWN_PANEL: '.headerDropdown-section-panel',
        FLYOUT_BACK: '.js-flyOutBack',
        FLYOUT_LIST: '.flyOut-list',
        FLYOUT_TITLE: '.flyOut-title',
        FLYOUT: '.js-flyOut',
        HEADER: '.js-header',
        NAVIGATION: '.js-mainMenu',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        MOBILE_CLOSE: 'js-closeMobileMenu',
        IS_OPEN: 'isOpen',
        FLYOUT_OPEN: 'flyOut_isOpen',
    };

    /**
     * An object of events used in this view
     * @default null
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CLICK: 'click',
        HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
        RESIZE: 'resize',
    };

    /**
     * An object of the Modernizr values
     *
     * @property MODERNIZR
     * @type {Object}
     * @final
     */
    var MODERNIZR = {
        TOUCH: 'touch',
        NO_TOUCH: 'no-touch',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
        FLYOUT_ANIMATION: 250,
        HEADER_ANIMATION: 250,
    };

    /**
     * An object of the constants used on data attributes for navigation images
     *
     * @property ATTRIBUTES
     * @type {Object}
     * @final
     */
    var ATTRIBUTES = {
        LAZY_LOAD: 'data-llhi',
    };

    var $window = $(window);
    var $body = $('body');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderFlyoutView
     * @extends {BaseView}
     */
    var HeaderFlyoutView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param {Function} eventBus
         * @param {Function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property flyoutOpen
             * @type {Function}
             * @public
             */
            this.flyoutOpen = null;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         * @returns {this}
         */
        init: function() {
            this.base();

            this.isOpen = false;
            this.setBreakpoint();
            this.eventBus.on(EVENTS.HIDE_MAIN_MENU, this.onExternalCloseHandler.bind(this));

            return this;
        },

        /**
         * Close all flyouts when click detected outside of menu
         */
        onExternalClose: function() {
            this.hideFlyout();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();

            this.onCloseClickHandler = this.onCloseClick.bind(this);
            this.onDropdownPanelClickHandler = this.onDropdownPanelClick.bind(this);
            this.onExternalCloseHandler = this.onExternalClose.bind(this);
            this.onFlyoutBackClickHandler = this.onFlyoutBackClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         * @returns {this}
         */
        createChildren: function() {
            this.base();

            this.$header = $body.find(SELECTORS.HEADER);
            this.$menu = $body.find(SELECTORS.NAVIGATION);
            this.$closeBtn = this.$menu.find(SELECTORS.CLOSE);
            this.$dropdown = this.$menu.find(SELECTORS.DROPDOWN);
            this.$dropdownPanels = this.$menu.find(SELECTORS.DROPDOWN_PANEL);
            this.$dropdownPanelsItems = this.$menu.find(SELECTORS.DROPDOWN_PANEL_ITEM);
            this.$flyout = this.$menu.find(SELECTORS.FLYOUT);
            this.$flyoutBackBtn = this.$menu.find(SELECTORS.FLYOUT_BACK);
            this.$activeDropdownPanel = null;
            this.$activeDropdownPanelItem = null;
            this.$activeFlyout = null;
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         * @returns {this}
         */
        removeChildren: function() {
            this.base();

            this.$header = null;
            this.$menu = null;
            this.$closeBtn = null;
            this.$dropdown = null;
            this.$dropdownPanels = null;
            this.$activeDropdownPanel = null;
            this.$activeDropdownPanelItem = null;
            this.$activeFlyout = null;
            this.$flyout = null;
            this.$flyoutBackBtn = null;
            this.breakpoint = null;

            return this;
        },

        /**
         * Set initial layout
         *
         * @method layout
         * @public
         * @chainable
         * @returns {this}
         */
        layout: function() {
            this.base();

            this.setBreakpoint();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns {this}
         *
         */
        onEnable: function() {
            $window.on(EVENTS.RESIZE, this.onWindowResize);
            this.$closeBtn.on(EVENTS.CLICK, this.onCloseClickHandler);
            this.$flyoutBackBtn.on(EVENTS.CLICK, this.onFlyoutBackClickHandler);
            this.$dropdownPanels.on(EVENTS.CLICK, this.onDropdownPanelClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            $window.off(EVENTS.RESIZE, this.onWindowResize);
            this.$closeBtn.off(EVENTS.CLICK, this.onCloseClickHandler);
            this.$flyoutBackBtn.off(EVENTS.CLICK, this.onFlyoutBackClickHandler);
            this.$dropdownPanels.off(EVENTS.CLICK, this.onDropdownPanelClickHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns {this}
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        toggleFlyout: function() {
            if (this.flyoutOpen) {
                this.hideFlyout();

                return this;
            }

            this.showFlyout();

            return this;
        },

        /**
         * Find dropdownPanel & dropdownPanelItems related to flyout clicked
         * Apply isOpen class to flyout and panel to apply CSS & visually show menu
         * Apply flyout_isOpen from main dropdown toggle
         * Set aria-expanded attribute to true to indicate to screenreaders the menu is open
         *
         * @param {jQuery} $flyout
         */
        showFlyout: function() {
            this.flyoutOpen = true;

            this.checkForLazyLoadImages();
            this.setActiveDropdownPanel();
            this.addOpenClasses();

            /* Wait for CSS Animation to Complete. 250ms = 2.5s in _flyOut.scss */
            setTimeout(this.focusOnFlyoutTitle.bind(this), TIMING.FLYOUT_ANIMATION);
        },

        /**
         * Add isOpen class from all flyouts and panels to apply CSS & visually show menus
         * Add flyout_isOpen from main dropdown toggle
         */
        addOpenClasses: function() {
            this.$activeFlyout.addClass(CLASSES.IS_OPEN);
            this.$dropdown.addClass(CLASSES.FLYOUT_OPEN);
            this.$activeDropdownPanel.addClass(CLASSES.IS_OPEN);
            this.$activeDropdownPanelItem.attr('aria-expanded', 'true');
        },

        /**
         * Set activeDropdownPanelItem in order to return focus after hiding flyout
         *
         * @param {jQuery} $dropdownPanel
         */
        setActiveDropdownPanel: function($dropdownPanel) {
            var $dropdownPanel = this.$activeFlyout.closest(SELECTORS.DROPDOWN_PANEL);
            var $dropdownPanelItem = $dropdownPanel.find(SELECTORS.DROPDOWN_PANEL_ITEM);

            this.$activeDropdownPanel = $dropdownPanel;
            this.$activeDropdownPanelItem = $dropdownPanelItem;
        },

        focusOnFlyoutTitle: function() {
            var $flyoutTitle = this.$activeFlyout.find(SELECTORS.FLYOUT_TITLE);

            $flyoutTitle.focus();
        },

        /**
         * Set flyoutOpen to false for use by other functions then call utility
         * functions to close menus & update keyboard/screenreader attributes
         */
        hideFlyout: function() {
            this.flyoutOpen = false;

            this.removeOpenClasses();
            this.resetAriaExpandedAttributes();

            /* Wait for CSS Animation to Complete. 250ms = 2.5s in _headerDropdown.scss */
            setTimeout(this.focusOnFlyoutTrigger.bind(this), TIMING.HEADER_ANIMATION);
        },

        /**
         * Remove isOpen class from all flyouts and panels to apply CSS & visually hide menus
         * Remove flyout_isOpen from main dropdown toggle
         *
         * Apply to all flyouts and panels to close any nested menus at once to avoid issues with
         * multiple menus being opened at a time such as a submenu on top of a menu
         */
        removeOpenClasses: function() {
            this.$flyout.removeClass(CLASSES.IS_OPEN);
            this.$dropdownPanels.removeClass(CLASSES.IS_OPEN);
            this.$dropdown.removeClass(CLASSES.FLYOUT_OPEN);
            this.$dropdown.scrollTop(0);
        },

        /**
         * If there is an active panel item that triggered current flyout then reset
         * focus to that item to improve usability for keyboard/screenreader users
         */
        focusOnFlyoutTrigger: function() {
            if (!this.$activeDropdownPanelItem) {
                return;
            }

            this.$activeDropdownPanelItem.focus();
        },

        /**
         * Set aria-expanded attribute to false to indicate
         * to screenreaders the menu is closed
         */
        resetAriaExpandedAttributes: function() {
            this.$dropdownPanelsItems.each(function() {
                var $isCollapsible = $(this).is(':button');

                if ($isCollapsible) {
                    $(this).attr('aria-expanded', 'false');
                }
            });
        },

        /**
         * Look within submenu for lazy load images
         * If located, run loadImages to update attributes
         *
         * @method checkForLazyLoadImages
         * @param {jQuery} $flyout jQuery selector
         * @public
        */
        checkForLazyLoadImages: function() {
            var $images = this.$activeFlyout.find('img');
            var hasImage = $images.length;

            if (hasImage) {
                this.loadLazyLoadImages($images);

                return;
            }
        },

        /**
         * Loop through image array to find how many images listed by lazyload custom attribute
         *
         * If more than one, assign to srcset
         * If only one, assign to src
         *
         * @param {Array} images
         */
        loadLazyLoadImages: function(images) {
            images.each(function() {
                var lazyLoadAttr = $(this).attr(ATTRIBUTES.LAZY_LOAD);

                if (!lazyLoadAttr) {
                    return;
                }

                var hasMultipleImages = lazyLoadAttr.indexOf(',') !== -1;

                if (hasMultipleImages) {
                    $(this).attr('srcset', lazyLoadAttr);
                    return;
                }

                $(this).attr('src', lazyLoadAttr);
            });
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         * @returns {this}
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            if (this.isOpen && this.isDesktop) {
                this.close();
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a trigger
         *
         * @method onDropdownPanelClick
         * @private
         * @param {jQuery} event The jQuery event object
         * @returns {this}
         */
        onDropdownPanelClick: function(event) {
            this.$activeFlyout = $(event.currentTarget).find(SELECTORS.FLYOUT);

            this.hideFlyout();
            this.showFlyout();

            return this;
        },

        /**
         * Handler for clicking a trigger
         *
         * @method onFlyoutBackClick
         * @private
         * @param {jQuery} event The jQuery event object
         * @returns {this}
         */
        onFlyoutBackClick: function(event) {
            event.preventDefault();
            event.stopPropagation();

            this.hideFlyout();

            return this;
        },

        /**
         * Handler for clicking a trigger
         *
         * @method onCloseClick
         * @private
         * @param {jQuery} event The jQuery event object
         * @returns {this}
         */
        onCloseClick: function(event) {
            event.preventDefault();

            this.hideFlyout();

            return this;
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @returns {this}
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @public
         * @returns {Function}
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderFlyoutView;
}());
