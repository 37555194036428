var NRD = window.NRD || {};

NRD['./views/TabsView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        TRIGGER: '.js-tabs-trigger',
        TARGET: '.js-tabs-trigger-target',
        PANEL: '.js-tabs-panel',
        CANCEL_BTN: '.js-tabs-cancel',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_ACTIVE: 'isActive',
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATTRS
     * @type {Object}
     * @final
     */
    var ATTRS = {
        TRIGGER_ID: 'data-tabs-trigger-id',
        PANEL_ID: 'data-tabs-panel-id',
    };

    var $window = $(window);

    /**
     * Handles opening and closing stacked drawers
     *
     * @class TabsView
     * @extends {BaseView}
     */
    var TabsView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            return this.openActive();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onTriggerClickHandler = this.onTriggerClick.bind(this);
            this.onTriggerSelectHandler = this.onTriggerSelect.bind(this);
            this.onCancelBtnClickHandler = this.onCancelBtnClick.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$triggers = this.$element.find(SELECTORS.TRIGGER);
            this.$clickTriggers = this.$triggers.filter('[data-tabs-trigger-type="click"]');
            this.$selectTriggers = this.$triggers.filter('[data-tabs-trigger-type="select"]');
            this.$panels = this.$element.find(SELECTORS.PANEL);
            this.$cancelBtn = this.$element.find(SELECTORS.CANCEL_BTN);

            this.prevId = null;
            this.activeId = this.getInitialId();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$triggers = null;
            this.$panels = null;
            this.$cancelBtn = null;

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$clickTriggers.on('click', this.onTriggerClickHandler);
            this.$selectTriggers.on('change', this.onTriggerSelectHandler);
            this.$cancelBtn.on('click', this.onCancelBtnClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$triggers.off('click', this.onTriggerClickHandler);
            this.$cancelBtn.on('click', this.onCancelBtnClickHandler);

            return this.close();
        },

        /**
         * Handles opening the active tab and closing the previous one
         *
         * @method toggle
         * @chainable
         * @public
         */
        toggle: function() {
            return this
                .closePrev()
                .openActive();
        },

        /**
         * Adds active class to the active tab and trigger
         *
         * @method openActive
         * @chainable
         * @private
         */
        openActive: function() {
            var $triggers;
            var $panels;

            if (this.activeId === null) {
                return this;
            }

            $triggers = this.$triggers.filter(this.onFilterById.bind(this, this.activeId, ATTRS.TRIGGER_ID));
            $panels = this.$panels.filter(this.onFilterById.bind(this, this.activeId, ATTRS.PANEL_ID));

            $triggers.addClass(CLASSES.IS_ACTIVE);
            $panels.addClass(CLASSES.IS_ACTIVE);

            return this;
        },

        /**
         * Removes active class from previously active tab and trigger
         *
         * @method closePrev
         * @chainable
         * @private
         */
        closePrev: function() {
            var $triggers;
            var $panels;

            if (this.prevId === null) {
                return this;
            }

            $triggers = this.$triggers.filter(this.onFilterById.bind(this, this.prevId, ATTRS.TRIGGER_ID));
            $panels = this.$panels.filter(this.onFilterById.bind(this, this.prevId, ATTRS.PANEL_ID));

            $triggers.removeClass(CLASSES.IS_ACTIVE);
            $panels.removeClass(CLASSES.IS_ACTIVE);

            return this;
        },

        /**
         * If the active tab is closes, set the scroll position to the top of the tabs element
         *
         * @method resetScrollPosition
         * @chainable
         * @private
         */
        resetScrollPosition: function() {
            var currentScrollTop = $window.scrollTop();
            var elementOffsetTop = this.$element.offset().top;

            if (elementOffsetTop >= currentScrollTop) {
                return this;
            }

            $window.scrollTop(this.$element.offset().top);

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // FILTERS/UTILITY
        /// ///////////////////////////////////////////////////////////////////////////////
        getInitialId: function() {
            var $trigger;
            var initialId = null;
            var length = this.$triggers.length;
            var i = 0;

            for (; i < length; i++) {
                $trigger = this.$triggers.eq(i);

                if ($trigger.hasClass(CLASSES.IS_ACTIVE)) {
                    initialId = $trigger.attr(ATTRS.TRIGGER_ID);
                }
            }

            return initialId;
        },

        onFilterById: function(sourceId, idAttr, index, element) {
            var $element = $(element);
            var elementId = $element.attr(idAttr);

            return ((sourceId === elementId) ? $element : null);
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a trigger
         *
         * @method onTriggerClick
         * @private
         */
        onTriggerClick: function(e) {
            var $trigger = $(e.currentTarget);

            if ($trigger.closest(SELECTORS.CANCEL_BTN).length > 0) {
                e.stopPropagation();
            }

            this.prevId = this.activeId;
            this.activeId = $trigger.attr(ATTRS.TRIGGER_ID);

            this.toggle();

            if (!$trigger.is('a')) {
                return;
            }

            e.preventDefault();
        },

        /**
         * Handler for clicking a trigger
         *
         * @method onTriggerClick
         * @private
         */
        onTriggerSelect: function(e) {
            var $trigger = $(e.currentTarget);
            var $target = $trigger.find(SELECTORS.TARGET);

            if (!$target.is(':selected')) {
                return;
            }

            this.prevId = this.activeId;
            this.activeId = $trigger.attr(ATTRS.TRIGGER_ID);

            this.toggle();
        },

        /**
         * Handler for clicking a cancel button
         *
         * @method onCancelBtnClick
         * @private
         */
        onCancelBtnClick: function(e) {
            var $cancelBtn = $(e.currentTarget);

            if (this.activeId === null) {
                return;
            }

            this.prevId = this.activeId;
            this.activeId = null;

            this
                .closePrev()
                .resetScrollPosition();

            this.prevId = null;

            if (!$cancelBtn.is('a')) {
                return;
            }

            e.preventDefault();
        },
    });

    return TabsView;
}());
