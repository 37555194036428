var NRD = window.NRD || {};

NRD['./views/TooltippyView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        TRIGGER: '.js-tooltip-trigger',
        TRIGGER_DOWN: 'tooltip-trigger_tipDown',
        PANEL: '.js-tooltip-panel',
    };

    /**
     * Handles opening and closing stacked drawers
     *
     * @class TooltippyView
     * @extends {BaseView}
     */
    var TooltippyView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param  {EventBus} eventBus Global instance of the Event Bus
         * @param  {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to the modal trigger
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            // If no element is found, return early
            if (!this.$element.length) {
                return;
            }

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         * @returns {this}
         */
        createChildren: function() {
            this.base();

            this.$trigger = this.$element.find(SELECTORS.TRIGGER);
            this.$panel = this.$element.find(SELECTORS.PANEL);
            this.tooltipContent = this.$panel.html();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         * @returns {this}
         */
        removeChildren: function() {
            this.base();

            this.$trigger = null;
            this.$panel = null;
            this.tooltipContent = null;

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            var hideOnPopperBlur = {
                name: 'hideOnPopperBlur',
                defaultValue: true,
                fn: function() {
                    return {
                        onCreate: function(instance) {
                            instance.popper.addEventListener('focusout', function(event) {
                                if (
                                    instance.props.hideOnPopperBlur &&
                                    event.relatedTarget &&
                                    !instance.popper.contains(event.relatedTarget)
                                ) {
                                    instance.hide();
                                }
                            });
                        },
                    };
                },
            };
            var hideOnEsc = {
                name: 'hideOnEsc',
                defaultValue: true,
                fn: function(instance) {
                    function onKeyDown(event) {
                        if (event.keyCode === 27) {
                            instance.hide();
                        }
                    };
                    return {
                        onShow: function() {
                            document.addEventListener('keydown', onKeyDown);
                        },
                        onHide: function() {
                            document.removeEventListener('keydown', onKeyDown);
                        },
                    };
                },
            };
            var standardConfig = {
                plugins: [
                    hideOnPopperBlur,
                    hideOnEsc,
                ],
                content: function(reference) {
                    var id = reference.getAttribute('data-template');
                    var template = document.getElementById(id);
                    return template.innerHTML;
                },
                popperOptions: {
                    strategy: 'absolute',
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['top', 'bottom'],
                            },
                        },
                    ],
                },
                role: 'tooltip',
                allowHTML: true,
                interactive: true,
                aria: {
                    content: 'describedby',
                    expanded: 'auto',
                },
                trigger: 'mouseenter click',
                onTrigger: function(instance, event) {
                    event.stopPropagation();
                    event.preventDefault();
                },
                onUntrigger: function(instance, event) {
                    instance.hide();
                    event.stopPropagation();
                    event.preventDefault();
                },
            };
            var alwaysDownConfig = {
                ...standardConfig,
                placement: 'bottom',
            };

            if (!this.$trigger.hasClass(SELECTORS.TRIGGER_DOWN)) {
                tippy(this.$trigger[0], standardConfig);
            } else {
                tippy(this.$trigger[0], alwaysDownConfig);
            }

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            return this;
        },

    });

    return TooltippyView;
}());
