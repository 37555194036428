var NRD = window.NRD || {};

NRD['./views/FormResetView'] = (function() { // jshint ignore:line
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        RESET_BTN: '.js-form-reset-btn',
        RESET_ALL_BTN: '.js-form-reset-all-btn',
        FIELDSET: '.js-form-reset-fieldset',
    };

    /**
     * An object of attributes used in this view
     *
     * @property ATTRS
     * @type {Object}
     * @final
     */
    var ATTRS = {
        RESET_BTN_ID: 'data-form-reset-btn-id',
        FIELDSET_ID: 'data-form-reset-fieldset-id',
    };

    /**
     * Handles opening and closing stacked drawers
     *
     * @class FormResetView
     * @extends {BaseView}
     */
    var FormResetView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            return this.saveFieldsets();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onResetBtnClickHandler = this.onResetBtnClick.bind(this);
            this.onResetAllBtnClickHandler = this.onResetAllBtnClick.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$fieldsets = this.$element.find(SELECTORS.FIELDSET);

            return this.saveFieldsets();
        },

        saveFieldsets: function() {
            var length = this.$fieldsets.length;
            var i = 0;

            for (; i < length; i++) {
                this.saveFormElements(this.$fieldsets.eq(i));
            }

            return this;
        },

        saveFormElements: function($fieldset) {
            return this
                .saveSelects($fieldset)
                .saveCheckedItems($fieldset)
                .saveTextFields($fieldset);
        },

        saveSelects: function($fieldset) {
            var $allSelects = $fieldset.find('select');
            var length  = $allSelects.length;
            var i = 0;
            var $select;
            var $selectedOption;

            for (; i < length; i++) {
                $select = $allSelects.eq(i);
                $selectedOption = $select.children(':selected');

                $select.data('formReset-selectedOption', $selectedOption);
            }

            return this;
        },

        saveCheckedItems: function($fieldset) {
            var $allCheckedItems = $fieldset.find('input[type="radio"], input[type="checkbox"]');
            var length  = $allCheckedItems.length;
            var i = 0;
            var $checkedItem;
            var isChecked;

            for (; i < length; i++) {
                $checkedItem = $allCheckedItems.eq(i);
                isChecked = $checkedItem.is(':checked');

                $checkedItem.data('formReset-checked', isChecked);
            }

            return this;
        },

        saveTextFields: function($fieldset) {
            var $excludedItems = $fieldset.find('input[type="radio"], input[type="checkbox"], input[type="submit"]');
            var $allTextFields = $fieldset.find('input, textarea').not($excludedItems);
            var length  = $allTextFields.length;
            var i = 0;
            var $textField;
            var textFieldValue;

            for (; i < length; i++) {
                $textField = $allTextFields.eq(i);
                textFieldValue = $textField.val();

                $textField.data('formReset-textFieldValue', textFieldValue);
            }

            return this;
        },

        resetFieldsets: function($fieldsets) {
            var length = $fieldsets.length;
            var i = 0;

            for (; i < length; i++) {
                this.resetFormElements($fieldsets.eq(i));
            }

            return this;
        },

        resetFormElements: function($fieldset) {
            return this
                .resetSelects($fieldset)
                .resetCheckedItems($fieldset)
                .resetTextFields($fieldset);
        },

        resetSelects: function($fieldset) {
            var $allSelects = $fieldset.find('select');
            var length  = $allSelects.length;
            var i = 0;
            var $select;
            var $options;
            var $currentSelectedOption;
            var $oldSelectedOption;

            for (; i < length; i++) {
                $select = $allSelects.eq(i);
                $options = $select.children();
                $currentSelectedOption = $options.filter(':selected');
                $oldSelectedOption = $select.data('formReset-selectedOption');

                if ($currentSelectedOption !== $oldSelectedOption) {
                    $options.removeAttr('selected');
                    $oldSelectedOption.attr('selected', 'selected');

                    $select.change();
                }
            }

            return this;
        },

        resetCheckedItems: function($fieldset) {
            var $allCheckedItems = $fieldset.find('input[type="radio"], input[type="checkbox"]');
            var length  = $allCheckedItems.length;
            var i = 0;
            var $checkedItem;
            var wasChecked;
            var isChecked;

            for (; i < length; i++) {
                $checkedItem = $allCheckedItems.eq(i);
                wasChecked = $checkedItem.data('formReset-checked');
                isChecked = $checkedItem.is(':checked');

                if (wasChecked && !isChecked) {
                    $checkedItem
                        .prop('checked', true)
                        .change();
                } else if (isChecked && !wasChecked) {
                    $checkedItem
                        .prop('checked', false)
                        .change();
                }
            }

            return this;
        },

        resetTextFields: function($fieldset) {
            var $excludedItems = $fieldset.find('input[type="radio"], input[type="checkbox"], input[type="submit"]');
            var $allTextFields = $fieldset.find('input, textarea').not($excludedItems);
            var length  = $allTextFields.length;
            var i = 0;
            var $textField;
            var oldTextFieldValue;
            var newTextFieldValue;

            for (; i < length; i++) {
                $textField = $allTextFields.eq(i);
                oldTextFieldValue = $textField.data('formReset-textFieldValue');
                newTextFieldValue = $textField.val();

                if (newTextFieldValue !== oldTextFieldValue) {
                    $textField
                        .val(oldTextFieldValue)
                        .change()
                        .trigger('keyup');
                }
            }

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$fieldsets = null;

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$element.on('click', SELECTORS.RESET_BTN, this.onResetBtnClickHandler);
            this.$element.on('click', SELECTORS.RESET_ALL_BTN, this.onResetAllBtnClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$element.off('click', SELECTORS.RESET_BTN, this.onResetBtnClickHandler);
            this.$element.off('click', SELECTORS.RESET_ALL_BTN, this.onResetAllBtnClickHandler);

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // FILTERS/UTILITY
        /// ///////////////////////////////////////////////////////////////////////////////
        onFilterById: function(btnId, index, element) {
            var $element = $(element);
            var elementId = $element.attr(ATTRS.FIELDSET_ID);

            return ((btnId === elementId) ? $element : null);
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a reset button
         *
         * @method onResetBtnClick
         * @private
         */
        onResetBtnClick: function(e) {
            var $resetBtn = $(e.currentTarget);
            var $fieldsets = this.$fieldsets.filter(this.onFilterById.bind(this, $resetBtn.attr(ATTRS.RESET_BTN_ID)));

            this.resetFieldsets($fieldsets);

            if (!$resetBtn.is('a')) {
                return;
            }

            e.preventDefault();
        },

        /**
         * Handler for clicking a reset all button
         *
         * @method onResetAllBtnClick
         * @private
         */
        onResetAllBtnClick: function(e) {
            var $resetAllBtn = $(e.currentTarget);

            this.resetFieldsets(this.$fieldsets);

            if (!$resetAllBtn.is('a')) {
                return;
            }

            e.preventDefault();
        },
    });

    return FormResetView;
}());
