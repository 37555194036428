var NRD = window.NRD || {};

NRD['./views/Header/HeaderSearchView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        SEARCH_TEXT_INPUT: '.searchForm-input',
        SEARCH_TEXT_LABEL: '.searchForm-label',
        SEARCH_TOGGLE_TRIGGER: '.searchForm-trigger_triggerBtn',
        SEARCH_CLOSE_BUTTON: '.searchForm-trigger_closeBtn',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        SEARCH_IS_OPEN: 'isOpen',
        RFK_IS_OPEN: 'rfkSearchBarOpen',
    };

    var ATTRIBUTES = {
        ON_CLICK: 'onclick',
        ARIA_HIDDEN: 'aria-hidden',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    var $window = $(window);
    var $body = $('body');

    /**
     * Handles showing/hiding the search bar at the small breakpoint
     *
     * @class HeaderSearchView
     * @extends {BaseView}
     */
    var HeaderSearchView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * Tracks whether the custom options panel is displayed
             *
             * @default false
             * @property isOpen
             * @type {bool}
             * @public
             */
            this.isOpen = false;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onSearchTriggerClickHandler = this.onSearchTriggerClick.bind(this);
            this.onSearchCloseClickHandler = this.onSearchCloseClick.bind(this);
            this.onSearchInputChangeHandler = this.onSearchInputChange.bind(this);
            this.onWindowResizeHandler = this.onWindowResize.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$search = this.$element;
            this.$searchInput = this.$search.find(SELECTORS.SEARCH_TEXT_INPUT);
            this.$searchLabel = this.$search.find(SELECTORS.SEARCH_TEXT_LABEL);
            this.$searchTrigger = this.$search.find(SELECTORS.SEARCH_TOGGLE_TRIGGER);
            this.$searchCloseBtn = this.$search.find(SELECTORS.SEARCH_CLOSE_BUTTON);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$search = null;
            this.$searchInput = null;
            this.$searchLabel = null;
            this.$searchTrigger = null;
            this.$searchCloseBtn = null;
            this.breakpoint = null;

            return this;
        },

        /**
         * Set breakpoint with debounce on load so we know if we need to hide filters in panel or not
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            this.base();

            this.setBreakpoint();

            if (this.isMobile) {
                this.removeOnClick();
                this.$searchTrigger.prop('disabled', false);
                this.$searchInput.attr(ATTRIBUTES.ARIA_HIDDEN, true);
                this.$searchLabel.attr(ATTRIBUTES.ARIA_HIDDEN, true);
            }

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$searchTrigger.on('click', this.onSearchTriggerClickHandler);
            this.$searchCloseBtn.on('click', this.onSearchCloseClickHandler);
            this.$searchInput.on('input', this.onSearchInputChangeHandler);
            $window.on('resize', this.onWindowResizeHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$searchTrigger.off('click', this.onSearchTriggerClickHandler);
            this.$searchCloseBtn.off('click', this.onSearchCloseClickHandler);
            this.$searchInput.off('input', this.onSearchInputChangeHandler);
            $window.off('resize', this.onWindowResizeHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         */
        breakpointChanges: function() {
            this.setBreakpoint();
            if (this.isDesktop) {
                this.resetOnClick();
                this.$searchTrigger.prop('disabled', true);
                this.$searchInput.attr(ATTRIBUTES.ARIA_HIDDEN, false);
                this.$searchLabel.attr(ATTRIBUTES.ARIA_HIDDEN, false);

                if (this.isOpen) {
                    this.hideSearch();
                }

                return this;
            }

            if (this.isMobile) {
                this.removeOnClick();
                this.$searchTrigger.prop('disabled', false);
                this.$searchInput.attr(ATTRIBUTES.ARIA_HIDDEN, true);
                this.$searchLabel.attr(ATTRIBUTES.ARIA_HIDDEN, true);
                return this;
            }

            return this;
        },

        removeOnClick: function() {
            this.$searchAttrName = this.$searchTrigger.attr(ATTRIBUTES.ON_CLICK);
            this.$searchTrigger.removeAttr(ATTRIBUTES.ON_CLICK);
        },

        resetOnClick: function() {
            this.$searchTrigger.attr(ATTRIBUTES.ON_CLICK, this.$searchAttrName);
        },

        showSearch: function() {
            this.isOpen = true;
            this.resetOnClick();
            this.$search.addClass(CLASSES.SEARCH_IS_OPEN);
            $body.addClass(CLASSES.RFK_IS_OPEN);
            this.$searchInput.focus();
            this.$searchInput.attr(ATTRIBUTES.ARIA_HIDDEN, false);
            this.$searchLabel.attr(ATTRIBUTES.ARIA_HIDDEN, false);


            return this;
        },

        hideSearch: function() {
            this.isOpen = false;

            this.removeOnClick();
            this.$search.removeClass(CLASSES.SEARCH_IS_OPEN);
            this.$searchInput.attr(ATTRIBUTES.ARIA_HIDDEN, true);
            this.$searchLabel.attr(ATTRIBUTES.ARIA_HIDDEN, true);
            this.$searchTrigger.focus();
            $body.removeClass(CLASSES.RFK_IS_OPEN);

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        onSearchTriggerClick: function(e) {
            e.preventDefault();
            if (this.isOpen) {
                return this;
            }

            if (this.isMobile) {
                this.showSearch();
            }

            return this;
        },

        onSearchCloseClick: function(e) {
            e.preventDefault();
            if (this.isMobile) {
                this.hideSearch();
            }

            return this;
        },

        onSearchInputChange: function(e) {
            if (this.$searchInput.val().length === 0) {
                this.$searchTrigger.prop('disabled', true);

                return this;
            }

            this.$searchTrigger.prop('disabled', false);

            return this;
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         */
        onWindowResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return HeaderSearchView;
}());
