var NRD = window.NRD || {};

NRD['./views/Header/HeaderToggleView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        HEADER: '.js-header',
        NAVIGATION: '.js-mainMenu',
        NAVIGATION_CONTAINER: '.headerNav-menu',
        MAIN_TRIGGER: '.js-primaryDropdown',
        DROPDOWN: '.js-mainDropdown',
        FLYOUT_TRIGGER: '.headerDropdown-section-panel-label',
        FLYOUT_PANEL: '.headerDropdown-section-panel',
        FLYOUT_BACK: '.js-flyOutBack',
        STATUS_CONTAINER: '.js-mainDropdown-status',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        SHOW_DROPDOWN: 'headerNav-menu_showDrop',
        HEADER_DROPCLASS_SNEEZEGUARD: 'header_dropOpen',
        ADD_CURSOR_POINTER: 'addPointer',
        IS_OPEN: 'isOpen',
        NO_SCROLL: 'noScroll',
        FLYOUT_OPEN: 'flyOut_isOpen',
        BODY_FIXED: 'headerFixed',
    };

    /**
     * An object of events used in this view
     * @default null
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CLICK_TAP: 'click tap',
        CLICK: 'click',
        CLOSE_DROPDOWN: 'CLOSE_DROPDOWN',
        HAMBURGER_OPEN: 'HAMBURGER_OPEN',
        HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
        HOVER_OPEN: 'HOVER',
        KEY_DOWN: 'keydown',
        RESIZE: 'resize',
        SECONDARY_OPEN: 'SECONDARY_OPEN',
    };

    /**
     * An array of key strings used in this view
     * @default null
     * @property KEYS
     * @type {Object}
     * @final
     */
    var KEYS = {
        ESCAPE: 'Escape',
    };

    /**
     * An object of the Modernizr values
     *
     * @property MODERNIZR
     * @type {Object}
     * @final
     */
    var MODERNIZR = {
        TOUCH: 'touch',
        NO_TOUCH: 'no-touch',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * An object of the strings used to convey messaging for users with screen readers
     * Insert space as we chain this with the menu type from data attribute
     *
     * @property MESSAGING
     * @type {Object}
     * @final
     */
    var MESSAGING = {
        OPEN_STATUS: 'Categories main menu is open',
        CLOSED_STATUS: 'Categories main menu has been hidden',
    };

    var $window = $(window);
    var $body = $('body');
    var $html = $('html');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderToggleView
     * @extends {BaseView}
     */
    var HeaderToggleView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            /**
             * Sets current scroll point to offset body with fixed positioning to fix shift
             *
             * @default false
             * @property scrollTop
             * @type {number}
             * @public
             */
            this.scrollTop = 0;

            /**
             * Tracks previous scroll top value to determine offset
             *
             * @default false
             * @property prevScrollTop
             * @type {number}
             * @public
             */
            this.prevScrollTop = 0;

            /**
             * A reference to the document
             *
             * @default null
             * @property $document
             * @type {jQuery}
             * @public
             */
            this.$document = null;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            this.isOpen = false;
            this.setBreakpoint();
            this.eventBus.on(EVENTS.SECONDARY_OPEN, this.onExternalCloseHandler);
            this.eventBus.on(EVENTS.HOVER_OPEN, this.onExternalCloseHandler);

            return this;
        },

        onExternalClose: function() {
            this.close(false);
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onWindowResize = this.onResize.bind(this);
            this.onKeyDownHandler = this.onKeyDown.bind(this);
            this.onBodyClickHandler = this.onBodyClick.bind(this);
            this.onTriggerClickHandler = this.onTriggerClick.bind(this);
            this.onExternalCloseHandler = this.onExternalClose.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$document = $(document);
            this.$header = $body.find(SELECTORS.HEADER);
            this.$menu = $body.find(SELECTORS.NAVIGATION);
            this.$menuContainer = this.$menu.find(SELECTORS.NAVIGATION_CONTAINER);
            this.$mainTrigger = this.$menu.find(SELECTORS.MAIN_TRIGGER);
            this.$dropdown = this.$menu.find(SELECTORS.DROPDOWN);
            this.$flyoutPanels = this.$menu.find(SELECTORS.FLYOUT_PANEL);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();
            this.$statusContainer = this.$header.find(SELECTORS.STATUS_CONTAINER);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         */
        removeChildren: function() {
            this.base();

            this.$document = null;
            this.$header = null;
            this.$menu = null;
            this.$menuContainer = null;
            this.$mainTrigger = null;
            this.$primaryDropdown = null;
            this.$flyoutPanels = null;
            this.breakpoint = null;
            this.$statusContainer = null;

            return this;
        },

        /**
         * Set initial layout
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            this.base();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            this.$document.on(EVENTS.KEY_DOWN, this.onKeyDownHandler);
            this.$mainTrigger.on(EVENTS.CLICK, this.onTriggerClickHandler);
            $window.on(EVENTS.RESIZE, this.onWindowResize);
            $body.on(EVENTS.CLICK_TAP, this.onBodyClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            this.$document.off(EVENTS.KEY_DOWN, this.onKeyDownHandler);
            this.$mainTrigger.off(EVENTS.CLICK, this.onTriggerClickHandler);
            $window.off(EVENTS.RESIZE, this.onWindowResize);
            $body.off(EVENTS.CLICK_TAP, this.onBodyClickHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Logic for clicking the trigger and toggling between open/closed states
         *
         * @method toggle
         * @chainable
         * @public
         */
        toggle: function() {
            if (this.isOpen) {
                this.close();

                return this;
            }

            this.open();

            return this;
        },

        /**
         * Adds active class to the root element.
         *
         * @method open
         * @chainable
         * @private
         */
        open: function() {
            this.isOpen = true;
            this.setBreakpoint();

            this.$statusContainer.empty().text(MESSAGING.OPEN_STATUS);
            this.$mainTrigger.attr('aria-pressed', true);

            if (this.isMobile) {
                this.prevScrollTop = $window.scrollTop();
                $body.addClass(CLASSES.NO_SCROLL).css({
                    top: -this.prevScrollTop,
                });
                this.$menuContainer.addClass(CLASSES.SHOW_DROPDOWN);
            }

            if ($body.hasClass(CLASSES.BODY_FIXED)) {
                this.$menuContainer.addClass(CLASSES.SHOW_DROPDOWN);
            }

            this.$dropdown.addClass(CLASSES.IS_OPEN);
            this.$dropdown.attr('aria-hidden', false);
            $html.addClass(CLASSES.ADD_CURSOR_POINTER);
            this.$header.addClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);
            this.eventBus.trigger(EVENTS.HAMBURGER_OPEN);

            return this;
        },

        /**
         * Removes active class from the root element
         *
         * @method close
         * @chainable
         * @private
         */
        close: function(isLocal) {
            this.isOpen = false;
            this.setBreakpoint();

            this.$mainTrigger.attr('aria-pressed', false);

            if (typeof isLocal === 'undefined') {
                isLocal = true;
            }

            if (this.isMobile) {
                this.scrollTop = $window.scrollTop();
                $body.removeClass(CLASSES.NO_SCROLL).css({
                    top: '',
                });

                if (this.prevScrollTop > this.scrollTop) {
                    $window.scrollTop(this.prevScrollTop);
                }

                this.$dropdown.scrollTop(0);
                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
            }

            if ($body.hasClass(CLASSES.BODY_FIXED)) {
                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
            }

            this.$dropdown.removeClass(CLASSES.IS_OPEN);
            this.$dropdown.attr('aria-hidden', true);
            // remove this class no matter if at mobile or not when breakpoint changes happen
            this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);

            if (isLocal) {
                this.$header.removeClass(CLASSES.HEADER_DROPCLASS_SNEEZEGUARD);
                $html.removeClass(CLASSES.ADD_CURSOR_POINTER);
                this.$statusContainer.empty().text(MESSAGING.CLOSED_STATUS);
            }

            this.eventBus.trigger(EVENTS.HIDE_MAIN_MENU);

            if (this.$dropdown.hasClass(CLASSES.FLYOUT_OPEN)) {
                this.$flyoutPanels.removeClass(CLASSES.IS_OPEN);
                this.$dropdown.removeClass(CLASSES.FLYOUT_OPEN);
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            if (this.isOpen && $body.hasClass(MODERNIZR.NO_TOUCH)) {
                this.close();
            }

            if ($body.hasClass(CLASSES.NO_SCROLL) && this.isDesktop) {
                this.scrollTop = $window.scrollTop();
                $body.removeClass(CLASSES.NO_SCROLL).css({
                    top: '',
                });
                if (this.prevScrollTop > this.scrollTop) {
                    $window.scrollTop(this.prevScrollTop);
                }
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking a trigger
         *
         * @method onTriggerClick
         * @private
         */
        onTriggerClick: function(event) {
            event.preventDefault();
            event.stopPropagation();
            this.toggle();

            return this;
        },

        /**
         * Check for body click or tap outside of open list panel, and trigger
         * hideItems event
         *
         * @method onBodyClick
         * @param {jQuery} event The jQuery event object
         * @public
         */

        onBodyClick: function(event) {
            if (this.isMobile || !this.isOpen) {
                return;
            }

            if (this.isOpen && !$(event.target).closest(this.$dropdown).length) {
                this.close();
            }
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Handles keydown events
         *
         * @method onKeyDown
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyDown: function(event) {
            var wasEscapePressed = event.key === KEYS.ESCAPE;

            if (wasEscapePressed && this.isOpen) {
                this.close();
            }
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderToggleView;
}());
