var NRD = window.NRD || {};

NRD['./views/Header/HeaderFixedView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    var $window = $(window);
    var $body = $('body');

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        NAVIGATION: '.js-mainMenu',
        NAVIGATION_CONTAINER: '.headerNav-menu',
        DROPDOWN: '.js-mainDropdown',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        HEADER_FIXED: 'header_isFixed',
        BODY_FIXED: 'headerFixed',
        SHOW_DROPDOWN: 'headerNav-menu_showDrop',
        IS_OPEN: 'isOpen',
    };

    var TRIGGERS = {
        CLOSE_DROPDOWN: 'CLOSE_DROPDOWN',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderFixedView
     * @extends {BaseView}
     */
    var HeaderFixedView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base();

            this.isFixed = false;
            this.setBreakpoint();

            if (this.isMobile) {
                return;
            }

            return this.toggleFixed($window.scrollTop());
        },


        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         */
        setupHandlers: function() {
            this.base();

            this.onScrollHandler = this.onScroll.bind(this);
            this.onWindowResize = this.onResize.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         */
        createChildren: function() {
            this.base();

            this.$menu = $body.find(SELECTORS.NAVIGATION);
            this.$menuContainer = this.$menu.find(SELECTORS.NAVIGATION_CONTAINER);
            this.$dropdown = this.$menu.find(SELECTORS.DROPDOWN);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         */
        onEnable: function() {
            $window.on('scroll', this.onScrollHandler);
            $window.on('resize', this.onWindowResize);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         */
        onDisable: function() {
            $window.off('scroll', this.onScrollHandler);
            $window.off('resize', this.onWindowResize);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Logic for clicking the trigger and toggling between open/closed states
         *
         * @method toggle
         * @chainable
         * @public
         */
        toggleFixed: function(scrollTop) {
            if (scrollTop === 0) {
                return this.unFixHeader();
            }

            return this.fixHeader();
        },

        /**
         * Adds the fixed class to the header
         *
         * @method open
         * @chainable
         * @private
         */
        fixHeader: function() {
            if (this.isFixed) {
                return this;
            }

            this.isFixed = true;

            this.$element.removeClass(CLASSES.HEADER_FIXED).addClass(CLASSES.HEADER_FIXED);
            $body.addClass(CLASSES.BODY_FIXED);

            if (this.$dropdown.hasClass(CLASSES.IS_OPEN)) {
                this.$dropdown.removeClass(CLASSES.IS_OPEN);
                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
                $window.trigger(TRIGGERS.CLOSE_DROPDOWN);
            }

            return this;
        },

        /**
         * Removes fixed class from the header
         *
         * @method close
         * @chainable
         * @private
         */
        unFixHeader: function() {
            if (!this.isFixed) {
                return this;
            }

            this.isFixed = false;

            if (this.isMobile) {
                return this;
            }

            this.$element.removeClass(CLASSES.HEADER_FIXED);
            $body.removeClass(CLASSES.BODY_FIXED);

            if (this.$dropdown.hasClass(CLASSES.IS_OPEN)) {
                this.$dropdown.removeClass(CLASSES.IS_OPEN);
                this.$menuContainer.removeClass(CLASSES.SHOW_DROPDOWN);
                $window.trigger(TRIGGERS.CLOSE_DROPDOWN);
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for window scroll event.
         *
         * @method onScroll
         * @private
         */
        onScroll: function(e) {
            if (this.isMobile) {
                return;
            }
            this.toggleFixed($window.scrollTop());
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return HeaderFixedView;
}());
