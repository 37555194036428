var NRD = window.NRD || {};

NRD['./views/InlineFormView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var modernizr = NRD['modernizr']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * Base zoom view.
     *
     * @class ZoomView
     * @extends {BaseView}
     */
    var InlineFormView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element A reference to the containing DOM element
         */
        constructor: function($element, eventBus, breakpointListener) {
            // If no element is found, return early
            if (!$element.length) {
                return;
            }

            this.formStates = {};

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         */
        setupHandlers: function() {
            this.base();
            this.onTriggerClickHandler = this.onTriggerClick.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         */
        createChildren: function() {
            this.$triggers = this.$element.find('.js-inlineForm-trigger');
            this.$briefs = this.$element.find('.js-inlineForm-brief');
            this.$forms = this.$element.find('.js-inlineForm-inputs');

            return this;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         */
        layout: function() {
            this.registerForms();

            // ensure forms are closed by default
            this.$forms.css({ 'display': 'none' });

            return this;
        },

        /**
         * Callback for inherited views to enable remove view specific handlers
         *
         * @method onEnable
         * @public
         * @chainable
         */
        onEnable: function() {
            this.base();
            this.$triggers.on('click', this.onTriggerClickHandler);

            return this;
        },

        /**
         * Callback for inherited views to disable remove view specific handlers
         *
         * @method onDisable
         * @public
         * @chainable
         */
        onDisable: function() {
            this.base();

            this.$triggers.off('click', this.onTriggerClickHandler);

            return this;
        },

        registerForms: function() {
            var count = this.$element.length;
            var i = 0;
            var id;
            var $el;
            var type;

            for (; i < count; i++) {
                id = this.$element.eq(i).attr('data-id');
                type = this.$element.eq(i).attr('data-type');

                if (this.formStates[id] !== undefined) {
                    continue;
                }

                // Select all instances of this id, since thee may be multiple on the page
                $el = this.$element.filter('[data-id="' + id + '"]');

                this.formStates[id] = {
                    isOpen: false,
                    $briefs: type === 'exclusive' ?
                        $el.find('.js-inlineForm-brief').filter('[data-id="' + id + '"]') :
                        $el.find('.js-inlineForm-brief'),
                    $inputs: type === 'exclusive' ?
                        $el.find('.js-inlineForm-inputs').filter('[data-id="' + id + '"]') :
                        $el.find('.js-inlineForm-inputs'),
                };
            }
        },

        toggle: function(id) {
            var form = this.formStates[id];

            if (form.isOpen) {
                form.$briefs.attr('style', '');
                form.$inputs.css({ display: 'none' });
                form.isOpen = false;
            } else {
                form.$briefs.css({ display: 'none' });
                form.$inputs.attr('style', '');
                form.isOpen = true;
            }

            this.formStates[id] = form;
        },


        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Clicks of triggers
         * @method onTriggerClick
         * @public
         * @param {Object} event The event object returned by the click
         */
        onTriggerClick: function(event) {
            var id = $(event.currentTarget).attr('data-id');

            event.preventDefault();
            this.toggle(id);
        },

    });

    return InlineFormView;
}());
