var NRD = window.NRD || {};

NRD['./views/Header/HeaderUserDetailView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        HEADER_CONTAINER: '.js-header',
        NAVIGATION: '.js-mainMenu',
        MAIN_DROPDOWN: '.js-mainDropdown',
        MAIN_DROPDOWN_UTILITY_PLACEHOLDER: '.js-mainDropdown_utilityHolder',
        UTILITY_MENU_CONTAINER: '.js-utilityMenu',
        USER_NOTIFICATIONS_CONTAINER: '.js-userNotifications',
        USER_DROPDOWN_PANEL: '.js-userDropdown',
        USER_DROPDOWN_SECTION: '.userDropdown-section',
        USER_DROPDOWN_TRIGGER: '.js-userTitle-trigger',
        USER_DROPDOWN_ORDERS: '.js-userDropdown-orders',
        USER_DROPDOWN_FAVORITES: '.js-userDropdown-favorites',
        USER_DROPDOWN_BACKBTN: '.js-userDropdown-backBtn',
        MAIN_MENU_CLOSE_BTN: '.js-closeMobileMenu',
        FOCUSABLE_ELEMENTS: 'button, a[href], input, select, textarea, [tabindex], [data-focusable]',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        DROPDOWN_IS_OPEN: 'isOpen',
        SECONDARY_IS_OPEN: 'mobileScrollOverride',
        IS_ACTIVE: 'isActive',
        IS_HIDDEN: 'userDropdown-section_isHidden',
        IS_VISUALLY_HIDDEN: 'userAccount-triggers-option_isVisuallyHidden',
    };

    var EVENTS = {
        CLOSE_SECONDARY_DROPDOWNS: 'CLOSE_SECONDARY_DROPDOWNS',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * An object of the constants used for association of menu items and triggers
     *
     * @property ATTRIBUTES
     * @type {Object}
     * @final
     */
    var ATTRIBUTES = {
        MENU_TO_SHOW: 'data-menu',
    };

    var $window = $(window);
    var $body = $('body');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderUserDetailView
     * @extends {BaseView}
     */
    var HeaderUserDetailView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param {Function} eventBus
         * @param {Function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop and tablet breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {Function}
             * @public
             */
            this.isDesktop = null;

            /**
             * A global reference to the mobile breakpoint
             *
             * @default null
             * @property isMobile
             * @type {Function}
             * @public
             */
            this.isMobile = null;

            /**
             * Tracks whether the custom options panel is displayed
             *
             * @default false
             * @property isOpen
             * @type {bool}
             * @public
             */
            this.isOpen = false;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         * @returns {this}
         */
        init: function() {
            this.base();

            this.isOpen = false;

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();

            this.onMainMenuCloseClickHandler = this.onMainMenuCloseClick.bind(this);
            this.onTriggerClickHandler = this.onTriggerClick.bind(this);
            this.onBackBtnClickHandler = this.onBackBtnClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         * @returns {this}
         */
        createChildren: function() {
            this.base();
            this.$header = $body.find(SELECTORS.HEADER_CONTAINER);
            this.$menu = this.$header.find(SELECTORS.NAVIGATION);
            this.$mainDropdown = this.$menu.find(SELECTORS.MAIN_DROPDOWN);
            this.$utility = this.$header.find(SELECTORS.UTILITY_MENU_CONTAINER);
            this.$utilityPlaceholder = this.$menu.find(SELECTORS.MAIN_DROPDOWN_UTILITY_PLACEHOLDER);
            this.$userAccount = this.$element;
            this.$userNotifications = this.$header.find(SELECTORS.USER_NOTIFICATIONS_CONTAINER);
            this.$mainMenuCloseBtn = this.$menu.find(SELECTORS.MAIN_MENU_CLOSE_BTN);
            this.$dropdownPanel = this.$userAccount.find(SELECTORS.USER_DROPDOWN_PANEL);
            this.$dropdownTrigger = this.$userAccount.find(SELECTORS.USER_DROPDOWN_TRIGGER);
            this.$dropdownSections = this.$userAccount.find(SELECTORS.USER_DROPDOWN_SECTION);
            this.$ordersPanel = this.$userAccount.find(SELECTORS.USER_DROPDOWN_ORDERS);
            this.$favoritesPanel = this.$userAccount.find(SELECTORS.USER_DROPDOWN_FAVORITES);
            this.$dropdownBackBtn = this.$dropdownPanel.find(SELECTORS.USER_DROPDOWN_BACKBTN);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         * @returns {this}
         */
        removeChildren: function() {
            this.base();

            this.$header = null;
            this.$menu = null;
            this.$mainDropdown = null;
            this.$utility = null;
            this.$utilityPlaceholder = null;
            this.$userAccount = null;
            this.$userNotifications = null;
            this.$mainMenuCloseBtn = null;
            this.$dropdownPanel = null;
            this.$dropdownTrigger = null;
            this.$dropdownSections = null;
            this.$ordersPanel = null;
            this.$favoritesPanel = null;
            this.$dropdownBackBtn = null;
            this.breakpoint = null;

            return this;
        },

        /**
         * Set breakpoint with debounce on load so we know if we need to hide filters in panel or not
         *
         * @method layout
         * @public
         * @chainable
         * @returns {this}
         */
        layout: function() {
            this.base();

            this.setBreakpoint();
            this.setUserContainer();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            this.$mainMenuCloseBtn.on('click', this.onMainMenuCloseClickHandler);
            this.$dropdownBackBtn.on('click', this.onBackBtnClickHandler);
            $window.on('resize', this.onWindowResize);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            this.$mainMenuCloseBtn.off('click', this.onMainMenuCloseClickHandler);
            this.$dropdownBackBtn.off('click', this.onBackBtnClickHandler);
            $window.off('resize', this.onWindowResize);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns {this}
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Find associated menu with data attribute matching class based
         * on link clicked
         *
         * @method findAssociatedPanel
         * @public
         * @chainable
         * @param {jQuery} event The jQuery event object
         * @returns {this}
         */
        findAssociatedPanel: function(event) {
            var $currentTrigger = $(event.currentTarget).attr(ATTRIBUTES.MENU_TO_SHOW);
            var $currentPanel = this.$dropdownPanel.find('.' + $currentTrigger);
            $currentPanel.removeClass(CLASSES.IS_HIDDEN);
        },

        setFocusAndReturn: function(event) {
            this.$firstElement = $(this.$dropdownPanel.find(SELECTORS.FOCUSABLE_ELEMENTS)[0]);
            this.$returnFocus = $(event.currentTarget);
        },

        /**
         * Close all open dropdown menus
         *
         * @method close
         * @public
         * @chainable
         * @returns {this}
         */
        close: function() {
            this.isOpen = false;
            this.$dropdownSections.addClass(CLASSES.IS_HIDDEN);
            this.$dropdownPanel.removeClass(CLASSES.DROPDOWN_IS_OPEN);
            this.$mainDropdown.removeClass(CLASSES.SECONDARY_IS_OPEN);
            this.$dropdownPanel.scrollTop(0);
            this.$mainDropdown.scrollTop(0);
            this.eventBus.trigger(EVENTS.CLOSE_SECONDARY_DROPDOWNS);

            return this;
        },

        /**
         * Open dropdown menu based on link clicked
         *
         * @method open
         * @public
         * @chainable
         * @param {jQuery} event The jQuery event object
         * @returns {this}
         */
        open: function(event) {
            this.isOpen = true;
            this.findAssociatedPanel(event);
            this.setFocusAndReturn(event);
            this.$returnFocus.blur();
            this.$returnFocus.addClass(CLASSES.IS_VISUALLY_HIDDEN);
            this.$dropdownPanel.addClass(CLASSES.DROPDOWN_IS_OPEN);
            this.$mainDropdown.addClass(CLASSES.SECONDARY_IS_OPEN);
            this.$firstElement.focus();

            return this;
        },

        /**
         * Check against breakpoint and decide where to place user detail containers
         *
         * @method setUserContainer
         * @public
         * @chainable
         * @returns {this}
         */
        setUserContainer: function() {
            if (!this.isDesktop) {
                this.$userAccount.appendTo(this.$utilityPlaceholder).addClass(CLASSES.IS_ACTIVE);
                this.$userNotifications.appendTo(this.$utilityPlaceholder).addClass(CLASSES.IS_ACTIVE);
                this.$dropdownTrigger.on('click', this.onTriggerClickHandler);
                this.$dropdownSections.addClass(CLASSES.IS_HIDDEN);
            } else {
                this.$userNotifications.prependTo(this.$utility).removeClass(CLASSES.IS_ACTIVE);
                this.$userAccount.prependTo(this.$utility).removeClass(CLASSES.IS_ACTIVE);
                this.$dropdownTrigger.off('click', this.onTriggerClickHandler);
                this.$dropdownSections.removeClass(CLASSES.IS_HIDDEN);
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         * @returns {this}
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            if (this.isOpen && this.isDesktop) {
                this.close();
                this.setUserContainer();

                return this;
            }

            this.setUserContainer();

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Handler for clicking main menu close button
         *
         * @method onMainMenuCloseClick
         * @private
         * @param {jQuery} e The jQuery event object
         * @returns {this}
         */
        onMainMenuCloseClick: function(event) {
            event.preventDefault();

            this.close();

            if (typeof this.$returnFocus === 'undefined') {
                return this;
            }

            this.$returnFocus.removeClass(CLASSES.IS_VISUALLY_HIDDEN);
            delete this.$returnFocus;

            return this;
        },

        /**
         * Handler for clicking user dropdown trigger
         *
         * @method onTriggerClick
         * @private
         * @param {jQuery} e The jQuery event object
         * @returns {this}
         */
        onTriggerClick: function(event) {
            event.preventDefault();

            this.open(event);

            return this;
        },

        /**
         * Handler for clicking user dropdown back button
         *
         * @method onBackBtnClick
         * @private
         * @param {jQuery} e The jQuery event object
         * @returns {this}
         */
        onBackBtnClick: function(event) {
            event.preventDefault();

            this.close();
            this.$firstElement.blur();
            this.$returnFocus.removeClass(CLASSES.IS_VISUALLY_HIDDEN);
            this.$returnFocus.focus();

            return this;
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @param {jQuery} e The jQuery event object
         * @returns {this}
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @returns {Function}
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderUserDetailView;
}());
