var NRD = window.NRD || {};

NRD['./views/ModalView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of class names used in this view
     * @default null
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        SNEEZEGUARD: 'sneezeguard',
        IS_ACTIVE: 'isActive',
        IS_VISUALLY_HIDDEN: 'isVisuallyHidden',
        BODY_ACTIVE: 'hasOverlay',
        HAS_MODAL: 'hasModal',
    };

    /**
     * An object of events used in this view
     * @default null
     * @property EVENTS
     * @type {Object}
     * @final
     */
    var EVENTS = {
        CAUSE_LAYOUT: 'CAUSE_LAYOUT',
        CLICK: 'click',
        KEY_UP: 'keyup',
        KEY_DOWN: 'keydown',
        TRANSITION_END: 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
        EVENT_BUS_TOGGLE_BY_ID: 'toggleModalById',
        EVENT_BUS_SHOW_BY_ID: 'showModalById',
        EVENT_BUS_HIDE_BY_ID: 'hideModalById',
        EVENT_BUS_RESET_FOCUSABLE_ELEMENTS: 'resetFocusableElements',
    };

    /**
     * An object of the selectors used in this view
     * @default null
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        MODAL_TRIGGER_CLASS: '.js-modal',
        MODAL_TARGET_ID: 'data-modal-target-id',
        MODAL_TARGET_CLASS: '.js-modal-target',
        MODAL_CONTENT_CLASS: '.js-modal-content',
        MODAL_CLOSE_CLASS: '.js-modal-close',
        MODAL_TRIGGER_TYPE: 'data-modal-trigger-type',
        MODAL_SHOW_ON_LOAD: 'data-modal-show-on-load',
        MODAL_DELAY_SHOW: 'data-modal-delay-show',
        MODAL_RETURN_FOCUS: 'data-modal-return-focus',
        ACTIVE_MODAL: '.modal.isActive',
        PRIVACY_BANNER: '#onetrust-consent-sdk',
        PRIVACY_BANNER_ACCEPT: '#onetrust-accept-btn-handler',
        PRIVACY_BANNER_ALLOW_ALL: '#accept-recommended-btn-handler',
        PRIVACY_BANNER_CLOSE: '.onetrust-close-btn-handler',
        INACTIVITY_MODAL: '#inactivityModal',
        ASP_FORM: '#aspnetForm',
        MAIN_CONTENT: '#aspnetForm > div',
        FOCUSABLE_ELEMENTS: 'button, a[href], input, select, textarea, [tabindex], [data-focusable]',
        FOCUSABLE_ELEMENTS_EXCLUSIONS: ':hidden, [tabindex="-1"], [disabled]',
        HEADER_MESSAGING: '.headerMessaging',
    };

    /**
     * An array of key strings used in this view
     * @default null
     * @property KEYS
     * @type {Object}
     * @final
     */
    var KEYS = {
        TAB: 'Tab',
        ESCAPE: 'Escape',
    };

    /**
     * An object of integers used in this view
     * @default null
     * @property INTEGERS
     * @type {Object}
     * @final
     */
    var INTEGERS = {
        Z_INDEX_SNEEZEGUARD: '900',
        Z_INDEX_MODAL: '1000',
    };

    /**
     * An object of css properties used in this view
     * @default null
     * @property CSS_PROPERTIES
     * @type {Object}
     * @final
     */
    var CSS_PROPERTIES = {
        FIXED: 'fixed',
    };

    /// ///////////////////////////////////////////////////////////////////////////////
    // HELPERS
    /// ///////////////////////////////////////////////////////////////////////////////

    // TODO: refactor these two utility methods into one if they represent same data
    /**
     * Get the target modal
     * @method _getModalTarget
     * @private
     * @param {Object} event
     * @return {Object} $modalTarget A jQuery object of the intended modal target
     */
    function _getModalTarget(event) {
        return $('#' + $(event.currentTarget).attr(SELECTORS.MODAL_TARGET_ID));
    }

    /**
     * Abstracted method to get modalTarget & trigger to hide or show modal
     *
     * @param {String} id
     * @returns {Object} {$modalTarget, $trigger}
     */
    function _getModalTargetAndTrigger(id) {
        var $modalTarget = $('#' + id);
        var $modalTrigger = $('body');

        return {
            $modalTarget: $modalTarget,
            $modalTrigger: $modalTrigger,
        };
    }

    /**
     * Modal view.
     *
     * @class ModalView
     * @extends {BaseView}

     */
    var ModalView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element A reference to the containing DOM element
         * @param {function} eventBus
         * @param {function} breakpointListener
         * @constructor
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to the global eventBus, used to call global services
             *
             * @default null
             * @property eventBus
             * @type {EventBus}
             * @public
             */
            this.eventBus = eventBus;

            this.$mainContent = $(SELECTORS.MAIN_CONTENT);
            this.$focusableElements = null;

            /**
             * A reference to the modal trigger
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            /**
             * A reference to the html element
             *
             * @default null
             * @property $html
             * @type {jQuery}
             * @public
             */
            this.$html = null;

            /**
             * A reference to the document
             *
             * @default null
             * @property $document
             * @type {jQuery}
             * @public
             */
            this.$document = null;

            /**
             * A reference to the modal targets in the DOM
             *
             * @default null
             * @property $modals
             * @type {jQuery}
             * @public
             */
            this.$modals = null;

            /**
             * A reference to the modal close trigger
             *
             * @default null
             * @property $modalClose
             * @type {jQuery}
             * @public
             */
            this.$modalClose = null;

            /**
             * A reference to the sneezeguard
             *
             * @default null
             * @property $sneezeguard
             * @type {jQuery}
             * @public
             */
            this.$sneezeguard = null;

            /**
             * Default configuration options
             * @property options
             * @type {Object}
             */
            this.options = {
                duration: 400,
                easing: 'linear',
                autoPosition: true,
            };

            /**
             * Tracks whether modal is present
             *
             * @default false
             * @property isModalActive
             * @type {Boolean}
             * @public
             */
            this.isModalActive = false;

            /**
             * Stores the trigger type. Defaults to null if no type is provided
             *
             * @default null
             * @property triggerType
             * @type {String}
             * @public
             */
            this.triggerType = this.$element.attr(SELECTORS.MODAL_TRIGGER_TYPE) || null;

            /**
             * Stores the data for showing on load. Defaults to null.
             *
             * @default null
             * @property showOnLoad
             * @type {String}
             * @public
             */
            // this.shouldShowOnLoad = this.$element.attr(SELECTORS.MODAL_SHOW_ON_LOAD) || null;

            /**
             * Stores the scroll position of the user before the modal was opened
             *
             * @default null
             * @property scrollPos
             * @type {String}
             * @public
             */
            // this.scrollPos = null;

            /**
             * Stores a flag indicating the existance of a scrollbar on the viewport
             *
             * @default false
             * @property scrollBar
             * @type {Boolean}
             * @public
             */
            this.hasScrollBar = false;

            /**
             * Stores the pixel width of the viewport's scrollbar
             *
             * @default 0
             * @property scrollBarWidth
             * @type {Number}
             * @public
             */
            this.scrollBarWidth = 0;

            /**
             * Stores the current event.Target
             *
             * @default null
             * @property $target
             * @type {jQuery}
             * @public
             */
            this.$target = null;

            /**
             * Check for existance of headerMessaging banner
             *
             * @default false
             * @property hasMessaging
             * @type {Boolean}
             * @public
             */
            this.hasMessaging = false;

            // If no element is found, return early
            if (!this.$element.length) {
                return;
            }

            // Call the BaseView's constructor
            this.base(this.$element, eventBus, breakpointListener);
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         *
         * @method init
         * @chainable
         * @public
         */
        init: function() {
            this.base()
                .enable();
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();
            this.onTriggerClickHandler = this.onTriggerClick.bind(this);
            this.onCloseClickHandler = this.onCloseClick.bind(this);
            this.onKeyUpHandler = this.onKeyUp.bind(this);
            this.onKeyDownHandler = this.onKeyDown.bind(this);
            this.onDocumentClickHandler = this.onDocumentClick.bind(this);
            this.onTransitionEndHandler = this.onTransitionEnd.bind(this);
            this.onToggleModalById = this.onToggleModalById.bind(this);
            this.onShowModalById = this.onShowModalById.bind(this);
            this.onHideModalById = this.onHideModalById.bind(this);
            this.onResetFocusableElements = this.onResetFocusableElements.bind(this);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         * @returns {this}
         */
        createChildren: function() {
            this.base();

            this.$html = $('html');
            this.$body = $('body');
            this.$form = $('body').find(SELECTORS.ASP_FORM);
            this.$document = $(document);
            this.$headerMessaging = this.$body.find(SELECTORS.HEADER_MESSAGING);
            this.$modals = $(SELECTORS.MODAL_TARGET_CLASS);
            this.$modalClose = $(SELECTORS.MODAL_CLOSE_CLASS);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         * @returns {this}
         */
        removeChildren: function() {
            this.base();

            this.$html = null;
            this.$body = null;
            this.$document = null;
            this.$headerMessaging = null;
            this.$modals = null;
            this.$modalClose = null;
            this.$sneezeguard.remove();
            this.$sneezeguard = null;
            this.$target = null;

            return this;
        },

        /**
         * Create single sneezeguard to provide transparent black backdrop for active modal
         *
         * @method createSneezeguard
         * @public
         */
        createSneezeguard: function() {
            if (this.$sneezeguard !== null) {
                return;
            }

            this.$sneezeguard = $('<div />');

            this.$sneezeguard
                .addClass(CLASSES.SNEEZEGUARD)
                .appendTo($('body'));
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes.
         *
         * @method layout
         * @public
         * @chainable
         * @returns {this}
         */
        layout: function() {
            this.base();
            this.createSneezeguard();

            if (this.$headerMessaging.length) {
                this.hasMessaging = true;
            }

            this.$modals.addClass(CLASSES.IS_VISUALLY_HIDDEN);

            return this;
        },

        /**
         * Callback from BaseView to enable view specific handlers
         *
         * @method onEnable
         * @public
         * @chainable
         * @returns {this}
         */
        onEnable: function() {
            this.base();
            this.$element.on(EVENTS.CLICK, this.onTriggerClickHandler);
            this.$modalClose.on(EVENTS.CLICK, this.onCloseClickHandler);
            this.$document.on(EVENTS.CLICK, this.onDocumentClickHandler);
            this.$document.on(EVENTS.KEY_UP, this.onKeyUpHandler);
            this.$document.on(EVENTS.KEY_DOWN, this.onKeyDownHandler);
            this.eventBus.on(EVENTS.EVENT_BUS_TOGGLE_BY_ID, this.onToggleModalById);
            this.eventBus.on(EVENTS.EVENT_BUS_SHOW_BY_ID, this.onShowModalById);
            this.eventBus.on(EVENTS.EVENT_BUS_HIDE_BY_ID, this.onHideModalById);
            this.eventBus.on(EVENTS.EVENT_BUS_RESET_FOCUSABLE_ELEMENTS, this.onResetFocusableElements);

            function openOnLoad(i, element) {
                var $parent = $(element).parent();

                if ($($parent).attr(SELECTORS.MODAL_SHOW_ON_LOAD) === 'true') {
                    this.shouldShowOnLoad = true;
                    this.showOnLoad(i);
                }
            };

            /* Show modal 4 seconds after page load */
            function openAfterDelay(i, element) {
                var $parent = $(element).parent();

                if ($($parent).attr(SELECTORS.MODAL_DELAY_SHOW) === 'true') {
                    this.shouldShowAfterDelay = true;
                    setTimeout(() => {
                        this.showOnLoad(i);
                    }, 4000);
                }
            };

            openOnLoad = openOnLoad.bind(this);
            openAfterDelay = openAfterDelay.bind(this);

            this.$modals.each(openOnLoad);
            this.$modals.each(openAfterDelay);

            return this;
        },

        /**
         * Callback from BaseView to disable view specific handlers
         *
         * @method onDisable
         * @public
         * @chainable
         * @returns {this}
         */
        onDisable: function() {
            this.base();
            this.$element.off(EVENTS.CLICK, this.onTriggerClickHandler);
            this.$modalClose.off(EVENTS.CLICK, this.onCloseClickHandler);
            this.$document.off(EVENTS.CLICK, this.onDocumentClickHandler);
            this.$document.off(EVENTS.KEY_UP, this.onKeyUpHandler);
            this.$document.off(EVENTS.KEY_DOWN, this.onKeyDownHandler);
            this.eventBus.off(EVENTS.EVENT_BUS_TOGGLE_BY_ID, this.onToggleModalById);
            this.eventBus.off(EVENTS.EVENT_BUS_SHOW_BY_ID, this.onShowModalById);
            this.eventBus.off(EVENTS.EVENT_BUS_HIDE_BY_ID, this.onHideModalById);
            this.eventBus.off(EVENTS.EVENT_BUS_RESET_FOCUSABLE_ELEMENTS, this.onResetFocusableElements);

            return this;
        },

        /**
         * Allow modal to be shown on page load for backend developers to use when needed
         *
         * @param {Number} triggerIndex
         */
        showOnLoad: function(triggerIndex) {
            var $modalTarget = $(this.$modals[triggerIndex]);
            var $trigger = this.$body;

            if (!$modalTarget.length) {
                return;
            }

            if (this.isModalActive) {
                this.hideAll();
            }

            this.toggleModal($modalTarget, $trigger);
        },

        /**
         * Toggle the modal
         *
         * @method toggleModal
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         * @param { jQuery } $trigger element which triggered toggleModal
         * @public
         */
        toggleModal: function($modalTarget, $trigger) {
            if (this.isModalActive) {
                this.hideModal($modalTarget);
            } else {
                this.showModal($modalTarget, $trigger);
            }
        },

        /**
         * Check to see if window has a scrollbar & capture width
         */
        checkWidthWithScrollbar: function() {
            this.hasScrollBar = window.innerWidth > document.documentElement.clientWidth;
            this.scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        },

        /**
         * Deduct scroll bar width from HTML element to avoid horizontal scrolling
         */
        setWidthWithScrollbar: function() {
            if (!this.hasScrollBar) {
                return;
            }

            this.$html.css('width', 'calc(100% - ' + this.scrollBarWidth + 'px');
        },

        /**
         * Toggle sneezeguard to improve user's focus on modal & dim background
         */
        toggleSneezeguard: function() {
            if (this.isModalActive) {
                this.$sneezeguard
                    .css('z-index', INTEGERS.Z_INDEX_SNEEZEGUARD)
                    .addClass(CLASSES.IS_ACTIVE);
            } else {
                this.$sneezeguard
                    .removeClass(CLASSES.IS_ACTIVE);
            }
        },

        /**
         * Set isModalActive flag to true to provide current state to other functions
         */
        setModalActive: function() {
            this.isModalActive = true;
        },

        /**
         * Set isModalActive flag to false to provide current state to other functions
         */
        setModalInactive: function() {
            this.isModalActive = false;
        },

        /**
         * Show the modal
         *
         * @method showModal
         * @public
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         * @param {jQuery} $trigger element which triggered showModal
         * @returns {this}
         */
        showModal: function($modalTarget, $trigger) {
            if (this.isModalActive) {
                return;
            }

            this.checkWidthWithScrollbar();
            this.$html.addClass(CLASSES.HAS_MODAL);

            this.setModalActive();
            this.toggleSneezeguard();

            if (this.$form.length > 0) {
                $modalTarget.appendTo(this.$form);
            } else {
                $modalTarget.appendTo(this.$body);
            }

            // Add position: fixed with JS to prevent DOM write issues in mobile devices when
            // modal is hidden by default - such as in an accordion
            $modalTarget
                .css('z-index', INTEGERS.Z_INDEX_MODAL)
                .css('position', CSS_PROPERTIES.FIXED)
                .removeClass(CLASSES.IS_VISUALLY_HIDDEN)
                .addClass(CLASSES.IS_ACTIVE);

            this.setWidthWithScrollbar();
            this.toggleMainContentHidden();

            this.setFocusableElements($modalTarget);
            this.setReturnFocusElement($modalTarget, $trigger);
            this.moveFocusInsideModal();

            if (this.shouldShowOnLoad) {
                return this;
            }

            if (this.shouldShowAfterDelay) {
                return this;
            }

            this.eventBus.trigger(EVENTS.CAUSE_LAYOUT);
        },

        /**
         * Hide the modal
         *
         * @method hideModal
         * @public
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         */
        hideModal: function($modalTarget) {
            this.destroyFocusableElements();

            $modalTarget
                .removeClass(CLASSES.IS_ACTIVE)
                .on(EVENTS.TRANSITION_END, this.onTransitionEndHandler);

            this.setModalInactive();
            this.toggleSneezeguard();
            this.toggleMainContentHidden();
            this.$target.focus();

            this.setHeaderMessagingDisplay();

            var methodToCall = $modalTarget.attr('data-close-fcn');

            if (methodToCall != null && window[methodToCall]) {
                window[methodToCall]();
            }
        },

        /**
         * If modal is active, then hide main content to prevent
         * keyboard & screenreader users from getting stuck
         * behind the modal. Creates same experience for all users.
         */
        toggleMainContentHidden: function() {
            if (this.isModalActive) {
                this.$mainContent.attr('aria-hidden', true);
            }

            this.$mainContent.removeAttr('aria-hidden');
        },

        /**
         * Find & set focusable elements visible within modal in order to
         * support focus trapping for keyboard & screenreader users.
         *
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         */
        setFocusableElements: function($modalTarget) {
            this.$focusableElements = $modalTarget
                .find(SELECTORS.FOCUSABLE_ELEMENTS)
                .not(SELECTORS.FOCUSABLE_ELEMENTS_EXCLUSIONS);

            this.$focusableElementsFirst = this.$focusableElements[0];
            this.$focusableElementsLast = this.$focusableElements[this.$focusableElements.length - 1];
        },

        /**
         * Clean up focusable elements when closing modals in order to
         * avoid focusing on modals or elements inside those modals
         * which are not visible.
         */
        destroyFocusableElements: function() {
            this.$focusableElements = null;
            this.$focusableElementsFirst = null;
            this.$focusableElementsLast = null;
        },

        /**
         * Set default return focus based on what was clicked to trigger modal then
         * check for alternate focus in order to set return focus element correctly
         * and return user to the intended spot where they left off before modal opened.
         *
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         * @param {jQuery} $trigger element which triggered showModal
         */
        setReturnFocusElement: function($modalTarget, $trigger) {
            // Set default return focus element
            this.$target = $trigger;

            this.checkForAlternateReturnFocus($modalTarget);
        },

        /**
         * Check if modal needs to return focus to an element
         * somewhere on the page instead of body after modal opens
         * on page reload
         *
         * @method checkForAlternateReturnFocus
         * @public
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         */
        checkForAlternateReturnFocus: function($modalTarget) {
            if (!$modalTarget.attr(SELECTORS.MODAL_RETURN_FOCUS)) {
                return;
            }

            this.setAlternativeReturnFocus($modalTarget);
        },

        /**
         * TODO: use unique data attribute instead of reusing data-modal-target-id to avoid
         *       Node.insertAfter issues stemming from trying to append modal inside of itself
         *       due to there not being matching data-modal-target-id outside of the modal.
         *
         * Set alternative focus as confirmed & captured by
         * checkForAlternateReturnFocus method
         * Close button shares same modal target ID as we set
         * with this logic, so we set elementToReceiveFocus to grab first
         * link as proper instead of also adding close button within modal
         * to this.$target
         *
         * @param {jQuery} $modalTarget A jQuery object of the target modal
         * @returns {this}
         */
        setAlternativeReturnFocus: function($modalTarget) {
            var $elementToReceiveFocusID = $modalTarget.attr(SELECTORS.MODAL_RETURN_FOCUS);
            var $elementToReceiveFocus = this.$body.find('[data-modal-target-id="' + $elementToReceiveFocusID + '"]')[0];

            this.$target = $elementToReceiveFocus;

            return this;
        },

        /**
         * Move focus inside the modal to the first focusable element when
         * modal opens to promote usability & satisfy accessibility requirements
         */
        moveFocusInsideModal: function() {
            this.$focusableElementsFirst.focus();
        },

        /**
         * Determine whether to add hidden aria if header messaging is being used on site
         * @method setHeaderMessagingDisplay
         * @public
         * @returns {this}
         */
        setHeaderMessagingDisplay: function() {
            if (!this.hasMessaging) {
                return;
            }

            if (this.isModalActive) {
                this.$headerMessaging.attr('aria-hidden', 'true');
            } else {
                this.$headerMessaging.removeAttr('aria-hidden');
            }

            return this;
        },

        /**
         * Runs tasks on completion of animation
         * @method onComplete
         * @public
         * @param {jQuery} $modalTarget A jQuery object of the modal
         * @chainable
         * @returns {this}
         */
        onComplete: function($modalTarget) {
            $modalTarget.off(EVENTS.TRANSITION_END, this.onTransitionEndHandler);

            if (this.isModalActive && this.shouldShowAfterDelay) {
                this.moveFocusInsideModal();
            } else if (this.isModalActive) {
                // If more than one modal close hook if found, focus the first one.
                $modalTarget
                    .find($(SELECTORS.MODAL_CLOSE_CLASS))
                    .first()
                    .focus();

                $modalTarget.attr('aria-live', 'polite');
            } else {
                // Removing the style attribute removes the inline z-index which
                // resets the z-index to the default defined in CSS, -1.
                this.$sneezeguard
                    .removeAttr('style');

                this.$html.removeClass(CLASSES.HAS_MODAL);

                if (this.hasScrollBar) {
                    this.$html.removeAttr('style');
                }

                $modalTarget
                    .attr('aria-live', 'off')
                    .removeAttr('style')
                    .addClass(CLASSES.IS_VISUALLY_HIDDEN)
                    .insertAfter(this.$target);

                this.$target = null;
            }

            return this;
        },

        /**
         * Hide any modals that may be showing in the DOM
         * @method hideAll
         * @public
         * @chainable
         * @returns { this }
         */
        hideAll: function() {
            var $modalTarget = $(SELECTORS.MODAL_TARGET_CLASS);
            var modalCount = $modalTarget.length;

            this.setModalInactive();

            for (var i = 0; i < modalCount; i++) {
                var $target = $modalTarget.eq(i);

                if ($target.hasClass(CLASSES.IS_ACTIVE)) {
                    this.hideModal($target);
                }
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Click handler shows modal
         *
         * @method onTriggerClick
         * @public
         * @param {Object} event The event object returned by the click
         */
        onTriggerClick: function(event) {
            var $modalTarget = _getModalTarget(event);
            var $trigger = $(event.currentTarget);

            if (!$modalTarget.length) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            // Remove focus in order to prevent accidental multiple key press events
            // i.e. hitting the 'return' key twice in quick succession
            $trigger.blur();
            this.toggleModal($modalTarget, $trigger);
        },

        /**
         * Toggle modal triggered by an external event via eventBus
         *
         * @method onToggleModalById
         * @public
         * @param {String} eventType triggered by eventBus
         * @param {String} id The id of the modal target to show
         */
        onToggleModalById: function(eventType, id) {
            var $modalTargetAndTrigger = _getModalTargetAndTrigger(id);
            var $modalTarget = $modalTargetAndTrigger.$modalTarget;
            var $modalTrigger = $modalTargetAndTrigger.$modalTrigger;

            if (!$modalTarget.length) {
                return;
            }

            this.toggleModal($modalTarget, $modalTrigger);
        },

        /**
         * Show modal triggered by an external event via eventBus
         *
         * @method onShowModalById
         * @public
         * @param {String} eventType triggered by eventBus
         * @param {String} id The id of the modal target to show
         */
        onShowModalById: function(eventType, id) {
            var $modalTargetAndTrigger = _getModalTargetAndTrigger(id);
            var $modalTarget = $modalTargetAndTrigger.$modalTarget;
            var $modalTrigger = $modalTargetAndTrigger.$modalTrigger;

            if (!$modalTarget.length) {
                return;
            }

            this.showModal($modalTarget, $modalTrigger);
        },

        /**
         * Hide modal triggered by an external event via eventBus
         *
         * @method onHideModalById
         * @public
         * @param {String} eventType triggered by eventBus
         * @param {String} id The id of the modal target to show
         */
        onHideModalById: function(eventType, id) {
            var $modalTargetAndTrigger = _getModalTargetAndTrigger(id);
            var $modalTarget = $modalTargetAndTrigger.$modalTarget;
            var $modalTrigger = $modalTargetAndTrigger.$modalTrigger;

            if (!$modalTarget.length) {
                return;
            }

            this.hideModal($modalTarget, $modalTrigger);
        },

        /**
         * Click handler closes modal
         *
         * @method onCloseClick
         * @public
         * @param {Object} event The event object returned by the click
         */
        onCloseClick: function(event) {
            event.originalEvent.stopPropagation();

            var $modalTarget = _getModalTarget(event);
            var $trigger = $(event.currentTarget);

            if (!$modalTarget.length) {
                return;
            }

            event.preventDefault();

            // Remove focus in order to prevent accidental multiple key press events
            // i.e. hitting the 'return' key twice in quick succession
            $trigger.blur();
            this.toggleModal($modalTarget);
        },

        /**
         * Handles keyup events
         *
         * @method onKeyUp
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyUp: function(event) {
            var wasEscapePressed = event.key === KEYS.ESCAPE;

            if (wasEscapePressed && this.isModalActive) {
                this.hideAll();
            }
        },

        /**
         * Handles keydown events
         *
         * @method onKeyDown
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyDown: function(event) {
            var wasTabPressed = event.key === KEYS.TAB;

            if (wasTabPressed) {
                this.checkActiveElement(event);
            }
        },

        /**
         * Closes modal when the document is clicked outside the modal
         *
         * @method onDocumentClick
         * @public
         * @param {Object} event The event object returned by the click
         */
        onDocumentClick: function(event) {
            var isModalContentClick = $(event.target).closest($(SELECTORS.MODAL_CONTENT_CLASS)).length;
            var isPrivacyBannerClick = $(event.target).closest($(SELECTORS.PRIVACY_BANNER)).length;
            var isInactivityModalClick = $(event.target).closest($(SELECTORS.INACTIVITY_MODAL)).length;

            if (!this.isModalActive || isModalContentClick) {
                return;
            }

            if (isPrivacyBannerClick) {
                this.onPrivacyBannerClick(event);

                return;
            }

            if (isInactivityModalClick) {
                this.eventBus.trigger('resetInactivityTimer');
            }

            this.hideAll();
        },

        onPrivacyBannerClick: function(event) {
            var isPrivacyBannerAcceptClick = $(event.target).closest(SELECTORS.PRIVACY_BANNER_ACCEPT).length;
            var isPrivacyBannerCloseClick = $(event.target).closest(SELECTORS.PRIVACY_BANNER_CLOSE).length;
            var isPrivacyBannerAllowAllClick = $(event.target).closest(SELECTORS.PRIVACY_BANNER_ALLOW_ALL).length;
            var activeModal = $(SELECTORS.ACTIVE_MODAL);

            this.isPrivacyBannerActive = true;

            if (!isPrivacyBannerAcceptClick && !isPrivacyBannerCloseClick && !isPrivacyBannerAllowAllClick) {
                return;
            }

            this.isPrivacyBannerActive = false;
            this.moveFocusInsideModal();
        },

        /**
         * Runs onComplete functions on transition end
         *
         * @method onTransitionEnd
         * @public
         * @param {Object} event The event object returned by the click
         */
        onTransitionEnd: function(event) {
            this.onComplete($(event.currentTarget));
        },

        /**
         * Method to be triggered when IT called 'resetFocusableElements' on the eventBus.
         *
         * Used in a scenario where the modal content changes without a closing or opening event.
         * Most often that scenario involves an AJAX call which will then call the resetFocusableElements event
         *
         * @param {String} eventType
         * @param {String} id
         */
        onResetFocusableElements: function(eventType, id) {
            var $modalTargetAndTrigger = _getModalTargetAndTrigger(id);
            var $modalTarget = $modalTargetAndTrigger.$modalTarget;

            this.destroyFocusableElements();
            this.setFocusableElements($modalTarget);
            this.moveFocusInsideModal();
        },

        /**
         * Runs when onKeyDown function detects the Tab key was used
         *
         * @method checkActiveElement
         * @public
         * @param {event} event The event object returned by the keyDown method
        */
        checkActiveElement: function(event) {
            var isActiveElementFirst = document.activeElement === this.$focusableElementsFirst;
            var isActiveElementLast = document.activeElement === this.$focusableElementsLast;

            if (!isActiveElementFirst && !isActiveElementLast) {
                return;
            }

            this.trapFocus(event, isActiveElementFirst, isActiveElementLast);
        },

        /**
         * Runs if tab or shift+tab was used when active element is first or last element within modal.
         *
         * Keeps focus within modal by moving from last element to first or first element to last
         * depending on currently focused element.
         *
         * @method trapFocus
         * @public
         * @param {event} event The event object returned by the keyDown method
         * @param {Boolean} isActiveElementFirst
         * @param {Boolean} isActiveElementLast
        */
        trapFocus: function(event, isActiveElementFirst, isActiveElementLast) {
            var wasShiftPressed = event.shiftKey;

            /* Navigate from first element to last onKeyDown of shift + tab */
            if (wasShiftPressed && isActiveElementFirst) {
                event.preventDefault();
                this.$focusableElementsLast.focus();

                return;
            }

            /* Navigate from last element to first onKeyDown of tab */
            if (!wasShiftPressed && isActiveElementLast) {
                event.preventDefault();
                this.$focusableElementsFirst.focus();

                return;
            }
        },

    });

    return ModalView;
}());
