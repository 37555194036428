var NRD = window.NRD || {};

NRD['./services/InactivityTimer'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView']; // eslint-disable-line

    /**
     * An object of time values in milliseconds
     */
    var TIMES = {
        INACTIVITY_LENGTH: 1200, // 20 minutes
        INACTIVITY_MODAL_PROMPT: 300, // 5 minutes
    };

    var DATA_ATTRS = {
        INACTIVITY: 'inactivity-timer',
    };

    var IDS = {
        INACTIVITY_MODAL: 'inactivityModal',
    };

    var SELECTORS = {
        INACTIVITY_MODAL: '#inactivityModal',
        INACTIVITY_MODAL_HD: '#modal-headline-inactivityModal',
        INACTIVITY_MODAL_BD: '#modal-body-inactivityModal .modalBox-bd-label',
        INACTIVITY_MODAL_BTN: '#modal-button-inactivityModal',
        MODAL_TIMER: '#modal-timer-inactivityModal',
        MODAL_TIMER_ANNOUNCEMENT: '#modal-announcement-inactivityModal',
    };

    var MSGS = {
        MODAL_TIMER_ANNOUNCEMENT_LABEL: 'Your online session will expire in ',
        MODAL_TIMER_ANNOUNCEMENT_LABEL_SUFFIX: 'Please press Keep Shopping to continue shopping.',
        MODAL_TIMER_ANNOUNCEMENT_EXPIRED: 'Due to inactivity, your online session has expired.',
        INITIAL_HD: 'Would you like to keep shopping?',
        INITIAL_BD: 'Your online session will expire in <span id="modal-timer-inactivityModal">00:00</span>',
        INITIAL_BTN: 'Keep Shopping',
        LOGGED_OUT_HD: 'Session Expired',
        LOGGED_OUT_BD: 'Due to inactivity, your online session has expired. <br />To start shopping again, click Ok.',
        LOGGED_OUT_BTN: 'Ok',
    };

    /// ///////////////////////////////////////////////////////////////////////////////
    // CONSTRUCTOR
    /// ///////////////////////////////////////////////////////////////////////////////

    /**
     * Inactivity Timer Utility
     *
     * @class InactivityTimer
     * @constructor
     */
    var InactivityTimer = BaseView.extend({

        constructor: function(eventBus) {
            this.eventBus = eventBus;

            this.init();
        },

        /**
         * Initializes the Inactivity Timer
         * Runs a single setupHandlers call, followed by createChildren, and enable
         *
         * Only enable timer if HTML has data atribute of inactivity-timer
         * to avoid running timer when not intended
         *
         * @method init
         * @public
         * @returns {this}
         */
        init: function() {
            if (!$('html').data(DATA_ATTRS.INACTIVITY)) {
                return this;
            }

            this.setupHandlers()
                .createChildren()
                .enable();

            return this;
        },

        /**
         * Binds the scope of any handler functions
         * Should only be run on initialization of the view
         *
         * @method setupHandlers
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.onResetInactivityTimerHandler = this.onResetInactivityTimer.bind(this);
            this.onWindowFocusHandler = this.onWindowFocus.bind(this);

            return this;
        },

        /**
         * Remove handlers to prevent duplicate or leaking events
         *
         * @method removeHandlers
         * @public
         * @returns {this}
         */
        removeHandlers: function() {
            this.eventBus.off('resetInactivityTimer', this.resetTimer);
            this.$window.off('focus', this.checkInactivity);

            return this;
        },

        /**
         * Create any child objects or references to DOM elements
         * Should only be run on initialization of the view
         *
         * @method createChildren
         * @public
         * @returns {this}
         */
        createChildren: function() {
            this.$window = $(window);
            this.$document = $(document);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements
         *
         * @method removeChildren
         * @public
         * @returns {this}
         */
        removeChildren: function() {
            this.$window = null;
            this.$document = null;

            return this;
        },

        /**
         * Start timer, setup handlers, and attach listeners
         *
         * Called on init and also when inactivityTimer is reset
         *
         * @returns {this}
         */
        enable: function() {
            this.startTimer()
                .setupHandlers();

            this.eventBus.on('resetInactivityTimer', this.onResetInactivityTimerHandler);
            this.$window.on('focus', this.onWindowFocusHandler);

            return this;
        },

        /**
         * Initialize timer using Date object & setInterval to compare against current time
         * to create an accurate and predictable timer.
         *
         * @returns {this}
         *
         */
        startTimer: function() {
            this.timeRemaining = TIMES.INACTIVITY_LENGTH;
            this.startTimestamp = Date.now();

            this.checkInactivityInterval = setInterval(
                this.checkInactivity.bind(this),
                1000
            );

            return this;
        },

        /**
         * Clear interval that started timer to avoid multiple timers & context issues on rebinding
         */
        stopTimer: function() {
            clearInterval(this.checkInactivityInterval);
        },

        /**
         * Grouped function to reset timer for IT to reset client-side timer after AJAX call
         */
        resetTimer: function() {
            this.disable()
                .enable();
        },

        /**
         * onResetInactivityTimer resets timer on eventBus event
         */
        onResetInactivityTimer: function() {
            this.resetTimer();
        },

        /**
         * Check on timeRemaining and either update modal or show modal expired & stop timer
         *
         * Updating modal helps keep time accurate for user & convey time remaining
         * Show modal expired updates markup to convey that there is no time remaining
         */
        checkInactivity: function() {
            if (this.timeRemaining === 0) {
                this.setModalExpired();
                this.stopTimer();

                return;
            }

            this.checkForShowModal();
            this.checkForModalUpdate();
            this.updateTimeRemaining();
        },

        /**
         * Set time remaining by comparing current Date obj against initial Date obj
         * to maintain an accurate timer for the user
         */
        updateTimeRemaining: function() {
            this.currentTimestamp = Date.now() - this.startTimestamp;
            this.timeRemaining = TIMES.INACTIVITY_LENGTH - Math.floor((this.currentTimestamp / 1000));
        },

        /**
         * Check if user has eclipsed time at which modal prompt is shown to
         * display modal at 5m allowing user to continue session
         */
        checkForShowModal: function() {
            if (this.timeRemaining !== TIMES.INACTIVITY_MODAL_PROMPT) {
                return;
            }

            this.showModal();
        },

        /**
         * Pass event to eventBus to centralize opening modals and set
         * modalVisible state to use elsewhere in component
         */
        showModal: function() {
            this.eventBus.trigger('showModalById', IDS.INACTIVITY_MODAL);
        },

        /**
         * Check to see if modal is visible before formatting time and updating UI to avoid
         * unnecessary computation, UI updates, and screenreader announcements
         */
        checkForModalUpdate: function() {
            this.formatTimeRemaining();
            this.updateModalTimerAnnouncement();
            this.updateModal();
        },

        /**
         * Update modal w/ time remaining to convey accurate information to mouse/keyboard user each second
         */
        updateModal: function() {
            $(SELECTORS.MODAL_TIMER).text(this.formattedTimeRemaining);
        },

        /**
         * Update modal timer announcement to convey accurate information to screenreader users each second
         */
        updateModalTimerAnnouncement: function() {
            if (this.timeRemaining === 0) {
                $(SELECTORS.MODAL_TIMER_ANNOUNCEMENT).text(MSGS.MODAL_TIMER_ANNOUNCEMENT_EXPIRED);

                return;
            }

            if (this.timeRemaining % 30 !== 0) {
                return;
            }

            $(SELECTORS.MODAL_TIMER_ANNOUNCEMENT).text(
                MSGS.MODAL_TIMER_ANNOUNCEMENT_LABEL + this.accessibleTimeRemaining + '. ' + MSGS.MODAL_TIMER_ANNOUNCEMENT_LABEL_SUFFIX
            );
        },

        /**
         * Update modal copy to convey accurate information to users once session time expires
         */
        setModalExpired: function() {
            $(SELECTORS.INACTIVITY_MODAL_HD).text(MSGS.LOGGED_OUT_HD);
            $(SELECTORS.INACTIVITY_MODAL_BD).html(MSGS.LOGGED_OUT_BD);
            $(SELECTORS.INACTIVITY_MODAL_BTN).text(MSGS.LOGGED_OUT_BTN);

            this.updateModalTimerAnnouncement();
            this.showModal();
        },

        /**
         * Reset modal markup to initial markup in cases where timer is reinstantiated or reset
         *
         * @returns {this}
         */
        resetModal: function() {
            $(SELECTORS.INACTIVITY_MODAL_HD).text(MSGS.INITIAL_HD);
            $(SELECTORS.INACTIVITY_MODAL_BD).html(MSGS.INITIAL_BD);
            $(SELECTORS.INACTIVITY_MODAL_BTN).text(MSGS.INITIAL_BTN);

            return this;
        },

        /**
         * Format time remaining to human readable 00:00 standard
         */
        formatTimeRemaining: function() {
            var minutes = Math.floor(this.timeRemaining / 60);
            var seconds = this.timeRemaining - minutes * 60;

            this.formattedMinutes = this.addLeadingZero(minutes);
            this.formattedSeconds = this.addLeadingZero(seconds);
            this.formattedTimeRemaining = this.formattedMinutes + ':' + this.formattedSeconds;
            this.accessibleTimeRemaining = minutes + ' minutes ' + seconds + ' seconds';
        },

        /**
         * Adds leading zero if minutes or seconds does not have one to create
         * expected human readable format of time
         *
         * @param {Number} time
         * @returns {Number} trimmedTime
         */
        addLeadingZero: function(time) {
            var timeWithLeadingZero = '0' + Math.floor(time);
            var trimmedTime = timeWithLeadingZero.slice(-2);

            return trimmedTime;
        },

        /**
         * onWindowFocus listens for the window or tab to be refocused by user after opening
         * a different window or tab & checks for inactivity to keep an accurate count
         * in case browsers stop or pause the setInterval used.
         */
        onWindowFocus: function() {
            this.checkInactivity();
        },

        /**
         * Disables the utility
         * Tears down any event binding to handlers
         *
         * @method disable
         * @public
         * @returns {this}
         */
        disable: function() {
            this.stopTimer();
            this.resetModal()
                .removeHandlers();

            this.eventBus.off('resetInactivityTimer', this.onResetInactivityTimerHandler);
            this.$window.off('focus', this.onWindowFocusHandler);

            return this;
        },

        /**
         * Destroys the utility
         * Tears down any events, handlers, elements
         * Should be called when the utility should be left unused
         *
         * @method destroy
         * @public
         * @returns {this}
         */
        destroy: function() {
            this.disable()
                .removeChildren();

            return this;
        },
    });

    return InactivityTimer;
}());
