var NRD = window.NRD || {};

NRD['./views/FilterPanelView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the classes used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_HIDDEN: 'isHidden',
        IS_VISUALLY_HIDDEN: 'isVisuallyHidden',
        IS_OPEN: 'isOpen',
        NO_SCROLL: 'noScroll',
        IS_SMALL: 'smViewport',
        IS_MEDIUM: 'mdViewport',
        IS_LARGE: 'lgViewport',
    };

    /**
     * An object of the event types used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var EVENTS = {
        RESIZE: 'resize',
        CLICK: 'click',
        KEY_UP: 'keyup',
        KEY_DOWN: 'keydown',
        CLOSE_FILTERS: 'CLOSE_FILTERS',
        APPLY_FILTERS: 'APPLY_FILTERS',
    };

    /**
     * An array of key strings used in this view
     * @default null
     * @property KEYS
     * @type {Object}
     * @final
     */
    var KEYS = {
        TAB: 'Tab',
        ESCAPE: 'Escape',
    };

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        CLOSE_PANEL_BUTTON: '.js-filterPanel-close',
        APPLY_PANEL_BUTTON: '.js-filterPanel-apply',
        TRIGGER_PANEL_BUTTON: '.js-filterPanel-trigger',
        FILTER_COUNT_CONTAINER: '.js-filterPanelTrigger-count',
        FILTER_COUNT_LABEL: '.js-filterPanelTrigger-label',
        PANEL_BODY: '.js-filterPanel-body',
        PANEL_PARENT: '.js-filterPanelParent',
        AJAX_CONTAINER: '#AjaxFilterRefreshArea2',
        FOCUSABLE_ELEMENTS: 'button, a[href], input, select, textarea, [tabindex], [data-focusable]',
        FOCUSABLE_ELEMENTS_EXCLUSIONS: ':hidden, [tabindex="-1"], [disabled]',
        MAIN_CONTENT: '#aspnetForm > div',
        HEADER_MESSAGING: '.headerMessaging',
        FILTER_CHECKBOXES_CHECKED: 'input[data-shs-filter]:checked',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property BREAKPOINT_VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the data attributes accessed in this view
     *
     * @property DATA
     * @type {Object}
     * @final
     */
    var DATA = {
        PANEL: 'filterPanel',
        PANEL_TRIGGER: 'filterTrigger',
        PANEL_APPLY_BUTTON: 'filterApply',
    };

    /**
     * An object of the constants used in this view
     *
     * @property CONST
     * @type {Object}
     * @final
     */
    var CONST = {
        DEBOUNCE_TIME: 300,
    };

    /**
     * An object of the static content used in this view
     *
     * @property CONTENT
     * @type {Object}
     * @final
     */
    var CONTENT = {
        NO_FILTERS_APPLIED: 'zero',
    };

    /**
     * Hooks click events to show and hide fixed positioning panel of filter and sort for mobile view
     * @class FilterPanelView
     */
    var FilterPanelView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param {function} eventBus
         * @param {function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;

            /**
             * A global reference to the desktop breakpoint
             *
             * @default null
             * @property isDesktop
             * @type {bool}
             * @public
             */
            this.isDesktop = false;

            /**
             * A global reference to the mobile and tablet breakpoint
             *
             * @default null
             * @property isMobile
             * @type {bool}
             * @public
             */
            this.isMobile = false;

            /**
             * Tracks whether the custom options panel is displayed
             *
             * @default false
             * @property isOpen
             * @type {bool}
             * @public
             */
            this.isOpen = false;

            /**
             * Sets current scroll point to offset body with fixed positioning to fix shift
             *
             * @default false
             * @property scrollTop
             * @type {number}
             * @public
             */
            this.scrollTop = 0;

            /**
             * Tracks previous scroll top value to determine offset
             *
             * @default false
             * @property prevScrollTop
             * @type {number}
             * @public
             */
            this.prevScrollTop = 0;

            /**
             * jQuery object of all focusable elements in component
             *
             * @default null
             * @property $focusableElements
             * @type {jQuery}
             * @public
             */
            this.$focusableElements = null;

            /**
             * jQuery object that tracks main page content
             *
             * @default null
             * @property $mainContent
             * @type {jQuery}
             * @public
             */
            this.$mainContent = $(SELECTORS.MAIN_CONTENT);

            /**
             * Check for existance of headerMessaging banner
             *
             * @default false
             * @property hasMessaging
             * @type {Boolean}
             * @public
             */
            this.hasMessaging = false;

            /**
             * A reference to the form element container
             *
             * @default null
             * @property $element
             * @type {jQuery}
             * @public
             */
            this.$element = $element;

            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @public
         * @chainable
         * @returns { this }
         */
        createChildren: function() {
            this.base();

            this.$window = $(window);
            this.$html = $('html');
            this.$body = $('body');
            this.$document = $(document);
            this.panelID = this.$element.data(DATA.PANEL);
            this.$panelParent = this.$document.find(SELECTORS.PANEL_PARENT);
            this.$panelContent = this.$document.find(SELECTORS.AJAX_CONTAINER);
            this.$panelBody = this.$element.find(SELECTORS.PANEL_BODY);
            this.$triggerPanelButton = this.$body.find(SELECTORS.TRIGGER_PANEL_BUTTON);
            this.$filterCountContainer = this.$triggerPanelButton.find(SELECTORS.FILTER_COUNT_CONTAINER);
            this.$filterCountLabel = this.$triggerPanelButton.find(SELECTORS.FILTER_COUNT_LABEL);
            this.$closePanelButton = this.$element.find(SELECTORS.CLOSE_PANEL_BUTTON);
            this.$applyPanelButton = this.$element.find(SELECTORS.APPLY_PANEL_BUTTON);
            this.$headerMessaging = this.$body.find(SELECTORS.HEADER_MESSAGING);

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.$window = null;
            this.$html = null;
            this.$body = null;
            this.$document = null;
            this.panelID = null;
            this.$panelParent = null;
            this.$panelContent = null;
            this.$panelBody = null;
            this.$triggerPanelButtonCount = null;
            this.$filterCountContainer = null;
            this.$filterCountLabel = null;
            this.$closePanelButton = null;
            this.$applyPanelButton = null;
            this.$headerMessaging = null;
        },

        /**
         * Set breakpoint with debounce on load so we know if we need to hide filters in panel or not
         *
         * @method layout
         * @public
         * @chainable
         * @returns { this }
         */
        layout: function() {
            this.base();
            this.setBreakpoint();
            this.setViewportClass();
            this.setPanelLocationAndAttributes();
            if (this.$headerMessaging.length) {
                this.hasMessaging = true;
            }

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @chainable
         * @returns { this }
         */
        setupHandlers: function() {
            this.base();

            this.onTriggerButtonHandler = this.onTriggerButtonClick.bind(this);
            this.onCloseButtonHandler = this.onCloseButtonClick.bind(this);
            this.onApplyButtonHandler = this.onApplyButtonClick.bind(this);
            this.onWindowResize = this.onResize.bind(this);
            this.onKeyUpHandler = this.onKeyUp.bind(this);
            this.onKeyDownHandler = this.onKeyDown.bind(this);

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns { this }
         */
        onEnable: function() {
            this.$triggerPanelButton.on(EVENTS.CLICK, this.onTriggerButtonHandler);
            this.$closePanelButton.on(EVENTS.CLICK, this.onCloseButtonHandler);
            this.$applyPanelButton.on(EVENTS.CLICK, this.onApplyButtonHandler);
            this.$window.on(EVENTS.RESIZE, this.onWindowResize);
            this.$document.on(EVENTS.KEY_UP, this.onKeyUpHandler);
            this.$document.on(EVENTS.KEY_DOWN, this.onKeyDownHandler);

            return this;
        },

        /**
         * Disables the component.
         * Performs any event binding to handlers.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns { this }
         */
        onDisable: function() {
            this.$triggerPanelButton.off(EVENTS.CLICK, this.onTriggerButtonHandler);
            this.$closePanelButton.off(EVENTS.CLICK, this.onCloseButtonHandler);
            this.$applyPanelButton.off(EVENTS.CLICK, this.onApplyButtonClickHandler);
            this.$window.off(EVENTS.RESIZE, this.onWindowResize);
            this.$document.off(EVENTS.KEY_UP, this.onKeyUpHandler);
            this.$document.off(EVENTS.KEY_DOWN, this.onKeyDownHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns { this }
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         * @returns { this }
         */
        breakpointChanges: function() {
            this.setBreakpoint();
            this.setViewportClass();
            this.setPanelLocationAndAttributes();

            if (this.isOpen && this.isDesktop) {
                this.hidePanel();
            }

            return this;
        },

        /**
         * Checks for specific class that gets added to the body and removes if viewport class
         * is already added. There are other body classes that we can't just wipe out before we
         * add the new viewport class to the body.
         *
         * @method setViewportClass
         * @public
         * @chainable
         * @returns { this }
         */
        setViewportClass: function() {
            if (this.$html.hasClass(CLASSES.IS_SMALL)) {
                this.$html.removeClass(CLASSES.IS_SMALL);
            }
            if (this.$html.hasClass(CLASSES.IS_MEDIUM)) {
                this.$html.removeClass(CLASSES.IS_MEDIUM);
            }
            if (this.$html.hasClass(CLASSES.IS_LARGE)) {
                this.$html.removeClass(CLASSES.IS_LARGE);
            }

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL) {
                this.$html.addClass(CLASSES.IS_SMALL);
            } else if (this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.$html.addClass(CLASSES.IS_MEDIUM);
            } else if (this.breakpoint === BREAKPOINT_VALUES.LARGE) {
                this.$html.addClass(CLASSES.IS_LARGE);
            }

            return this;
        },

        /**
         * Checks if mobile or desktop to move panel into proper location to
         * show or hide panel and allow all other page content to be hidden
         * when open. On breakpoint change we check if going from mobile to
         * desktop and move the filters back into the parent grid container.
         * Add or remove attributes needed for screen readers to announce
         * panel properly between NVDA and Voiceover.
         *
         * @method setpanelLocationAndAttributes
         * @chainable
         * @public
         * @returns { this }
         */
        setPanelLocationAndAttributes: function() {
            if (this.isMobile) {
                this.$panelContent.detach().appendTo(this.$body);
                this.$element.attr('aria-hidden', true);
                this.$element.attr('aria-modal', true);
                this.$element.attr('role','dialog');
                this.setFilterCount();
            } else {
                this.$panelContent.detach().appendTo(this.$panelParent);
                this.$element.removeAttr('aria-hidden');
                this.$element.removeAttr('aria-modal');
                this.$element.removeAttr('role');
            }

            return this;
        },

        /**
         * When setting panel location on load check if any filters are already
         * chosen and adjust the filter button text
         *
         * @method setFilterCount
         * @chainable
         * @public
         * @returns { this }
         */
        setFilterCount: function() {
            var count = $(SELECTORS.FILTER_CHECKBOXES_CHECKED).length;

            if (count > 0) {
                this.$filterCountContainer.removeClass(CLASSES.IS_VISUALLY_HIDDEN).text('(' + count + ')');
                this.$filterCountLabel.removeClass(CLASSES.IS_HIDDEN).addClass(CLASSES.IS_VISUALLY_HIDDEN);
            } else {
                this.$filterCountContainer.addClass(CLASSES.IS_HIDDEN).text('(' + CONTENT.NO_FILTERS_APPLIED + ')');
                this.$filterCountLabel.addClass(CLASSES.IS_HIDDEN).removeClass(CLASSES.IS_VISUALLY_HIDDEN);
            }

            return this;
        },

        /**
         * Adds appropriate classes to elements to display filter panel
         *
         * @method showPanel
         * @chainable
         * @public
         * @returns { this }
         */
        showPanel: function() {
            this.setFocusableElements(this.$element);
            this.prevScrollTop = this.$window.scrollTop();
            this.$body.addClass(CLASSES.NO_SCROLL).css({
                top: -this.prevScrollTop,
            });
            this.$element.addClass(CLASSES.IS_OPEN);
            this.$element.attr('aria-hidden', false);
            this.isOpen = true;
            this.toggleMainContentHidden();
            this.setHeaderMessagingDisplay();
            this.moveFocusInsidePanel();

            return this;
        },

        /**
         * Remove appropriate classes to elements to hide filter panel
         *
         * @method hidePanel
         * @chainable
         * @public
         * @returns { this }
         */
        hidePanel: function() {
            this.destroyFocusableElements();
            this.scrollTop = this.$window.scrollTop();
            this.$element.removeClass(CLASSES.IS_OPEN);
            this.$body.removeClass(CLASSES.NO_SCROLL).css({
                top: '',
            });
            if (this.prevScrollTop > this.scrollTop) {
                this.$window.scrollTop(this.prevScrollTop);
            }
            this.$panelBody.scrollTop(0);
            this.isOpen = false;
            this.setHeaderMessagingDisplay();
            this.toggleMainContentHidden();
            if (this.isMobile) {
                this.$triggerPanelButton.focus();
            }
            this.$element.attr('aria-hidden', true);

            return this;
        },

        /**
         * If filterPanel is active, then hide main content to prevent
         * keyboard & screenreader users from getting stuck
         * behind the panel. Creates same experience for all users.
         * @returns { this }
         */
        toggleMainContentHidden: function() {
            if (this.isOpen) {
                this.$mainContent.attr('aria-hidden', true);
                return this;
            }
            this.$mainContent.removeAttr('aria-hidden');

            return this;
        },

        /**
         * Find & set focusable elements visible within filterPanel in order to
         * support focus trapping for keyboard & screenreader users.
         *
         * @param {jQuery} $currentPanel A jQuery object of the target modal
         * @returns { this }
         */
        setFocusableElements: function($currentPanel) {
            this.$focusableElements = $currentPanel
                .find(SELECTORS.FOCUSABLE_ELEMENTS)
                .not(SELECTORS.FOCUSABLE_ELEMENTS_EXCLUSIONS);

            this.$focusableElementsFirst = this.$focusableElements[0];
            this.$focusableElementsLast = this.$focusableElements[this.$focusableElements.length - 1];

            return this;
        },

        /**
         * Clean up focusable elements when closing filterPanel in order to
         * avoid focusing on filterPanel or elements inside the filterPanel
         * which are not visible.
         * @returns { this }
         */
        destroyFocusableElements: function() {
            this.$focusableElements = null;
            this.$focusableElementsFirst = null;
            this.$focusableElementsLast = null;

            return this;
        },

        /**
         * Move focus inside the filterPanel to the first focusable element when
         * panel opens to promote usability & satisfy accessibility requirements
         * @returns { this }
         */
        moveFocusInsidePanel: function() {
            this.$focusableElementsFirst.focus();

            return this;
        },

        /**
         * Determine whether to add hidden aria if header messaging is being used on site
         * @method setHeaderMessagingDisplay
         * @public
         * @returns {this}
         */
        setHeaderMessagingDisplay: function() {
            if (!this.hasMessaging) {
                return;
            }

            if (this.isOpen) {
                this.$headerMessaging.attr('aria-hidden', true);
            } else {
                this.$headerMessaging.removeAttr('aria-hidden');
            }

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////

        /**
         * Check isOpen for true or false on currently open filter panel
         * to trigger add or remove class on filter panel
         *
         * @method onTriggerButtonClick
         * @public
         * @param {event} event
         * @returns { this }
         */
        onTriggerButtonClick: function(event) {
            event.preventDefault();

            if (!this.isOpen) {
                this.showPanel();
                return this;
            }
            this.hidePanel();

            return this;
        },

        /**
         * Used to close the open filter panel and reset scroll position from offset
         *
         * @method onCloseButtonClick
         * @public
         * @returns { this }
         */
        onCloseButtonClick: function() {
            this.hidePanel();
            this.eventBus.trigger(EVENTS.CLOSE_FILTERS);

            return this;
        },

        /**
         * Used to close the open filter panel and reset scroll position from offset
         *
         * @method onApplyButtonClick
         * @public
         * @returns { this }
         */
        onApplyButtonClick: function() {
            this.hidePanel();
            this.eventBus.trigger(EVENTS.APPLY_FILTERS);

            return this;
        },

        /**
         * Handles keyup events
         *
         * @method onKeyUp
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyUp: function(event) {
            var wasEscapePressed = event.key === KEYS.ESCAPE;

            if (wasEscapePressed && this.isOpen) {
                this.hidePanel();
                this.eventBus.trigger(EVENTS.CLOSE_FILTERS);
            }
        },

        /**
         * Handles keydown events
         *
         * @method onKeyDown
         * @public
         * @param {Object} event The event object returned by the keyup
         */
        onKeyDown: function(event) {
            var wasTabPressed = event.key === KEYS.TAB;

            if (wasTabPressed) {
                this.checkActiveElement(event);
            }
        },

        /**
         * Runs when onKeyDown function detects the Tab key was used
         *
         * @method checkActiveElement
         * @public
         * @param {event} event The event object returned by the keyDown method
        */
        checkActiveElement: function(event) {
            var isActiveElementFirst = document.activeElement === this.$focusableElementsFirst;
            var isActiveElementLast = document.activeElement === this.$focusableElementsLast;

            if (!isActiveElementFirst && !isActiveElementLast) {
                return;
            }

            this.trapFocus(event, isActiveElementFirst, isActiveElementLast);
        },

        /**
         * Runs if tab or shift+tab was used when active element is first or last element within modal.
         *
         * Keeps focus within modal by moving from last element to first or first element to last
         * depending on currently focused element.
         *
         * @method trapFocus
         * @public
         * @param {event} event The event object returned by the keyDown method
         * @param {Boolean} isActiveElementFirst
         * @param {Boolean} isActiveElementLast
        */
        trapFocus: function(event, isActiveElementFirst, isActiveElementLast) {
            var wasShiftPressed = event.shiftKey;

            /* Navigate from first element to last onKeyDown of shift + tab */
            if (wasShiftPressed && isActiveElementFirst) {
                event.preventDefault();
                this.$focusableElementsLast.focus();

                return;
            }

            /* Navigate from last element to first onKeyDown of tab */
            if (!wasShiftPressed && isActiveElementLast) {
                event.preventDefault();
                this.$focusableElementsFirst.focus();

                return;
            }
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @returns { this }
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, CONST.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @returns {function}
         * @public
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },

    });

    return FilterPanelView;
}());
