var NRD = window.NRD || {};

NRD['./views/Header/HeaderNestedFlyoutView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * An object of the selectors used in this view
     *
     * @property SELECTORS
     * @type {Object}
     * @final
     */
    var SELECTORS = {
        HEADER: '.js-header',
        PARENT_DROPDOWN: '.headerDropdown-section-panel',
        NESTED_FLYOUT_TRIGGER: '.js-nestedFlyout-trigger',
        NESTED_FLYOUT_PANEL: '.js-nestedFlyout-panel',
        NESTED_FLYOUT_BACK: '.js-flyOutNestedBack',
    };

    /**
     * An object of class names used in this view
     *
     * @property CLASSES
     * @type {Object}
     * @final
     */
    var CLASSES = {
        IS_OPEN: 'isOpen',
        NESTED_FLYOUT_OPEN: 'nestedOpen',
    };

    var EVENTS = {
        HIDE_MAIN_MENU: 'HIDE_MAIN_MENU',
    };

    /**
     * An object of the breakpoint values used in this view
     *
     * @property VALUES
     * @type {Object}
     * @final
     */
    var BREAKPOINT_VALUES = {
        SMALL: 'small',
        MEDIUM: 'medium',
        LARGE: 'large',
    };

    /**
     * An object of the constants used in this view
     *
     * @property TIMING
     * @type {Object}
     * @final
     */
    var TIMING = {
        DEBOUNCE_TIME: 300,
    };

    var $window = $(window);
    var $body = $('body');
    var $html = $('html');

    /**
     * Handles opening and closing stacked drawers
     *
     * @class HeaderNestedFlyoutView
     * @extends {BaseView}
     */
    var HeaderNestedFlyoutView = BaseView.extend({
        /**
         * @constructor
         * @param {jQuery} $element Root DOM node for the view instance
         * @param {Function} eventBus
         * @param {Function} breakpointListener
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Call the BaseView's constructor
            this.base($element, eventBus, breakpointListener);

            /**
             * A reference to breakpoint
             *
             * @default null
             * @property breakpoint
             * @type {string}
             * @public
             */
            this.breakpoint = null;
        },

        /**
         * Initializes the UI Component View.
         * Runs a single setupHandlers call, followed by createChildren and layout.
         * Exits early if it is already initialized.
         *
         * @method init
         * @chainable
         * @public
         * @returns {this}
         */
        init: function() {
            this.base();

            this.isOpen = false;
            this.setBreakpoint();

            if (this.isMobile) {
                this.eventBus.on(EVENTS.HIDE_MAIN_MENU, this.onExternalCloseHandler.bind(this));
            }

            return this;
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @chainable
         * @public
         * @returns {this}
         */
        setupHandlers: function() {
            this.base();


            this.onWindowResize = this.onResize.bind(this);
            this.onNestedFlyoutClickHandler = this.onNestedFlyoutClick.bind(this);
            this.onNestedFlyoutBackClickHandler = this.onNestedFlyoutBackClick.bind(this);
            this.onExternalCloseHandler = this.onExternalClose.bind(this);

            return this;
        },

        onExternalClose: function() {
            this.hideFlyout();
        },

        /**
         * Create any child objects or references to DOM elements.
         * Should only be run on initialization of the view.
         *
         * @method createChildren
         * @chainable
         * @public
         * @returns {this}
         */
        createChildren: function() {
            this.base();

            this.$header = $body.find(SELECTORS.HEADER);
            this.$dropdown = this.$header.find(SELECTORS.PARENT_DROPDOWN);
            this.$nestedFlyoutTrigger = this.$header.find(SELECTORS.NESTED_FLYOUT_TRIGGER);
            this.$nestedFlyoutPanels = this.$header.find(SELECTORS.NESTED_FLYOUT_PANEL);
            this.$nestedFlyoutBackBtn = this.$header.find(SELECTORS.NESTED_FLYOUT_BACK);
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            return this;
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @chainable
         * @public
         * @returns {this}
         */
        removeChildren: function() {
            this.base();

            this.$header = null;
            this.$dropdown = null;
            this.$nestedFlyoutTrigger = null;
            this.$nestedFlyoutPanels = null;
            this.$nestedFlyoutBackBtn = null;
            this.breakpoint = null;

            return this;
        },

        /**
         * Set initial layout
         *
         * @method layout
         * @public
         * @chainable
         * @returns {this}
         */
        layout: function() {
            this.base();

            return this;
        },

        /**
         * Enables the component.
         * Performs any event binding to handlers.
         * Exits early if it is already enabled.
         *
         * @method onEnable
         * @chainable
         * @public
         * @returns {this}
         */
        onEnable: function() {
            $window.on('resize', this.onWindowResize);
            this.$nestedFlyoutTrigger.on('click', this.onNestedFlyoutClickHandler);
            this.$nestedFlyoutBackBtn.on('click', this.onNestedFlyoutBackClickHandler);

            return this;
        },

        /**
         * Disables the component.
         * Tears down any event binding to handlers.
         * Exits early if it is already disabled.
         *
         * @method onDisable
         * @chainable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            $window.off('resize', this.onWindowResize);
            this.$closeBtn.off('click', this.onCloseClickHandler);
            this.$nestedFlyoutTrigger.off('click', this.onNestedFlyoutClickHandler);
            this.$nestedFlyoutBackBtn.off('click', this.onNestedFlyoutBackClickHandler);

            return this;
        },

        /**
         * If breakpoint matches small or medium values toggle the boolean
         * for isMobile and isDestop
         *
         * @method setBreakpoint
         * @public
         * @chainable
         * @returns {this}
         */
        setBreakpoint: function() {
            this.breakpoint = this.breakpointListener.getCurrentBreakpoint();

            if (this.breakpoint === BREAKPOINT_VALUES.SMALL || this.breakpoint === BREAKPOINT_VALUES.MEDIUM) {
                this.isMobile = true;
                this.isDesktop = false;
            } else {
                this.isMobile = false;
                this.isDesktop = true;
            }

            return this;
        },

        toggleFlyout: function(parent) {
            if (this.flyoutOpen) {
                this.hideFlyout();

                return this;
            }
            this.showFlyout(parent);

            return this;
        },

        showFlyout: function(parent) {
            this.flyoutOpen = true;
            parent.addClass(CLASSES.NESTED_FLYOUT_OPEN);
        },

        hideFlyout: function() {
            this.flyoutOpen = false;
            this.$element.removeClass(CLASSES.NESTED_FLYOUT_OPEN);
        },

        /**
         * Listens for breakpoint change and remove classes on panel if large
         *
         * @method breakpointChanges
         * @public
         * @chainable
         * @returns {this}
         */
        breakpointChanges: function() {
            this.setBreakpoint();

            return this;
        },

        /// ///////////////////////////////////////////////////////////////////////////////
        // EVENT HANDLERS
        /// ///////////////////////////////////////////////////////////////////////////////


        /**
         * Handler for clicking a trigger
         *
         * @method onNestedFlyoutClick
         * @private
         * @param {Event} e click event
         * @returns {this}
         */
        onNestedFlyoutClick: function(e) {
            if (this.isDesktop) {
                return this;
            } else {
                e.preventDefault();
                e.stopPropagation();
                this.$nestedFlyoutParent = $(e.currentTarget).parent();

                this.hideFlyout();
                this.toggleFlyout(this.$nestedFlyoutParent);
            }

            return this;
        },

        /**
         * Handler for clicking a trigger
         *
         * @method onNestedFlyoutBackClick
         * @private
         * @param {Event} e click event
         * @returns {this}
         */
        onNestedFlyoutBackClick: function(e) {
            e.preventDefault();

            this.hideFlyout();

            return this;
        },

        /**
         * When window resizes use debounce to check breakpoint value
         * and trigger breakpoint changes to remove classes if needed
         *
         *
         * @method onResize
         * @public
         * @returns {this}
         */
        onResize: function() {
            var debounceResize = this.debounce(this.breakpointChanges, TIMING.DEBOUNCE_TIME);

            debounceResize();

            return this;
        },

        /**
         * Debounce handler. Waits to run the passed in function.
         *
         * @method debounce
         * @param {Function} func The function to run on debounce
         * @param {Integer} wait The number of milliseconds to wait between firing events
         * @param {Boolean} immediate If true, run the function before the wait instead of after
         * @return function
         * @public
         * @returns {Function}
         */
        debounce: function(func, wait, immediate) {
            var self = this;

            return function() {
                var args = arguments;

                if (self.timeout !== undefined) {
                    clearTimeout(self.timeout);
                }

                self.timeout = setTimeout(function() {
                    self.timeout = null;

                    if (!immediate) {
                        func.apply(self, args);
                    }
                }, wait);

                if (immediate && !self.timeout) {
                    func.apply(self, args);
                }
            };
        },
    });

    return HeaderNestedFlyoutView;
}());
