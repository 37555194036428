var NRD = window.NRD || {};

NRD['./views/ValidationObserverView'] = (function() {
    'use strict';

    var $ = NRD['jquery']; // eslint-disable-line
    var BaseView = NRD['./views/BaseView'];

    /**
     * The configuration object for mutation observers
     *
     * @property CONFIG
     * @type {Object}
     * @final
     */
    var CONFIG = {
        attributes: true,
    };

    /**
     * The class to be added or removed from nodes which have a validation error
     *
     * @property ERROR_CLASS
     * @type {String}
     * @final
     */
    var ERROR_CLASS = 'unobtrusiveHasError';

    /**
     * The name of the data attribute which should be read to discern the
     * target input's ID.
     *
     * @property TARGET_ID_ATTR
     * @type {String}
     * @final
     */
    var TARGET_ID_ATTR = 'data-val-controltovalidate';

    /**
     * Watches for validation events on span error message tags and
     * updates the corresponding input to contain show the appropriate validation style
     *
     * @class ValidationObserverView
     */
    var ValidationObserverView = BaseView.extend({
        /**
         * @constructor
         * @param  {jQuery} $element Root element of the view
         * @param  {EventBus} eventBus Global instance of the Event Bus
         * @param  {BreakpointListener} breakpointListener Global instance of the breakpoint listener
         */
        constructor: function($element, eventBus, breakpointListener) {
            // Don't init if there are no validation elements on the page
            if ($element.length < 1) {
                return;
            }

            /**
             * The cached observers array. Contains all references to
             * mutation observers for the view.
             *
             * @property observers
             * @type {array}
             * @public
             * @default null
             */
            this.observers = null;

            this.invalidInputs = [];

            // Call the base view constructor to init the view
            this.base($element, eventBus, breakpointListener);
        },

        /**
         * Binds the scope of any handler functions.
         * Should only be run on initialization of the view.
         *
         * @method setupHandlers
         * @public
         * @returns {this.base}
         */
        setupHandlers: function() {
            this.onObservationHandler = this.onObservation.bind(this);

            return this.base();
        },

        /**
         * Remove any child objects or references to DOM elements.
         *
         * @method removeChildren
         * @public
         * @chainable
         */
        removeChildren: function() {
            this.observers = null;
            this.invalidInputs = null;
        },

        /**
         * Performs measurements and applys any positioning style logic.
         * Should be run anytime the parent layout changes. In this case,
         * toggles error state on fields which may be loaded with error
         * states visible
         *
         * @method layout
         * @public
         * @returns {this.base}
         */
        layout: function() {
            var $el = this.$element;
            var count = $el.length;
            var i = 0;

            for (; i < count; i++) {
                this.toggleErrorState($el.eq(i));
            }

            return this.base();
        },

        /**
         * Callback for inherited views to enable remove view specific handlers
         *
         * @method onEnable
         * @public
         * @returns {this.base}
         */
        onEnable: function() {
            this.enableObservers();

            return this.base();
        },

        /**
         * Callback for inherited views to disable remove view specific handlers
         *
         * @method onDisable
         * @public
         * @returns {this}
         */
        onDisable: function() {
            this.disableObservers();

            this.base();

            return this;
        },

        /**
         * Loops through the target nodes and creates a MutationObserver
         * instance for each of them (if one does not exist), as well as
         * assigning them to the observer array. It also enables them.
         *
         * @method createObservers
         * @public
         */
        enableObservers: function() {
            var $el = this.$element;
            var count = $el.length;
            // cache obervers locally for performance
            var observers = this.observers != null ? this.observers : [];
            var i = 0;
            var target;

            for (; i < count; i++) {
                // Create an observer if it doesn't exist already
                if (!observers[i]) {
                    // MutationObserver needs an HTML Node, not a jQuery object
                    target = $el[i];
                    observers[i] = new MutationObserver(this.onObservationHandler);
                }

                // Enable the individual observer
                observers[i].observe(target, CONFIG);
            }

            this.observers = observers;
        },

        /**
         * Halts observation of all the nodes.
         *
         * @method disableObservers
         * @public
         */
        disableObservers: function() {
            // cache obervers locally for performance
            var observers = this.observers;
            var count = observers.length;
            var i = 0;

            for (; i < count; i++) {
                observers[i].disconnect();
            }

            this.observers = observers;
        },

        /**
         * Adds or removes the error state to the target input based on the
         * state of the error message node ($target) which is passed in.
         *
         * @method toggleErrorState
         * @param {jQuery} $target The error message node which has undergone a mutation
         * @public
         */
        toggleErrorState: function($target) {
            var inputId = $target.attr(TARGET_ID_ATTR);
            var $input = $('#' + inputId);
            var $messages = $('[' + TARGET_ID_ATTR + '=' + inputId + ']');
            var isMessageVisible = this._checkMessageVisibility($messages);
            var visibleMessages = this._getVisibleMessages($messages);
            var firstVisibleMessageId = $(visibleMessages).attr('id');

            if (isMessageVisible) {
                this.invalidInputs.push($input);

                $input.addClass(ERROR_CLASS);
                $input.attr('aria-invalid', 'true');
                $input.attr('aria-describedby', firstVisibleMessageId);

                // For multiple errors within one view. Focus on first error.
                this.invalidInputs[0].focus();

                return;
            }

            // Remove input when invalidInputs when error has been removed from view
            this.invalidInputs.splice(this.invalidInputs.indexOf($input), 1);

            $input.removeClass(ERROR_CLASS);
            $input.attr('aria-invalid', 'false');
            $input.removeAttr('aria-describedby');
        },

        /**
         * Handles a mutation event
         *
         * @method disableObservers
         * @public
         * @param {Object} mutations
         */
        onObservation: function(mutations) {
            var $target = $(mutations[0].target);

            this.toggleErrorState($target);
        },

        /**
         * Checks all error messages checks their visibility to decide whether or not
         * to attach aria properties and show/hide error class on corresponding input.
         *
         * Most often, there will be only be one error per input field but this method
         * allows multiple fields to be described by a single error, if needed.
         *
         * @method _checkMessageVisibility
         * @private
         * @param {string} $messages All messages corresponding to invalid input
         * @returns {Boolean}
         */
        _checkMessageVisibility: function($messages) {
            var messages = $messages.toArray();

            return messages.some(function(message) {
                return $(message).is(':visible');
            });
        },

        _getVisibleMessages: function($messages) {
            var messages = $messages.toArray();

            return messages.find(function(message) {
                return $(message).is(':visible');
            });
        },

    });

    return ValidationObserverView;
}());
